import React, { useEffect, useContext, useState } from "react";
import styles from "./ServicesTable.module.css";
import PropTypes from "prop-types";
/* Third party */
/* Contexts */
import Context from "../../../store/context";
import { NotificationContext } from "../../../store/NotificationProvider";
import { DeleteContext } from "../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

import PlaceholderImage from "../../../assets/images/placeholderImage.png";

/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";

/* Icons */
import {
	Delete,
	EditButton,
	InfoIcon,
	CheckedSquare,
} from "../../../assets/Icons/index";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import NewPagination from "../../PaginationComp/NewPagination";
import {
	useChangeStatusServiceMutation,
	useSearchInServicesMutation,
	useServiceDeleteAllMutation,
	useServiceDeleteItemMutation,
} from "../../../RTK/apiSlices/servicesApi";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../DeleteModal/DeleteModal";
import { toast } from "react-toastify";

// switch styles
const switchStyle = {
	width: "26px",
	padding: "12px 0",

	"& .MuiSwitch-thumb": {
		width: "10px",
		height: "10px",
	},
	"& .MuiSwitch-switchBase": {
		padding: "6px",
		top: "9px",
		left: "-4px",
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		left: "-12px",
	},
	"& .Mui-checked .MuiSwitch-thumb": {
		backgroundColor: "#EEF4FC",
	},
	"& .MuiSwitch-track": {
		height: "16px",
		borderRadius: "20px",
	},
	"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
		backgroundColor: "#3AE374",
		opacity: 1,
	},
};

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "publish",
		numeric: false,
		disablePadding: false,
		label: "نشر",
	},
	{
		id: "status",
		numeric: false,
		disablePadding: false,
		label: "الحالة",
	},
	{
		id: "pendingServices",
		numeric: false,
		disablePadding: false,
		label: "يتم تنفيذها",
	},
	{
		id: "price",
		numeric: true,
		disablePadding: false,
		label: "سعر الخدمة",
	},
	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "اسم الخدمة",
	},

	{
		id: "number",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead sx={{ backgroundColor: "#feb17e" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0]'
						key={index}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#FFF",
							whiteSpace: "nowrap",
						}}>
						{headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,

	onSelectAllClick: PropTypes.func.isRequired,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		rowCount,
		onSelectAllClick,
		search,
		setSearch,
		itemsSelected,
	} = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;

	return (
		<Toolbar
			className='flex flex-row flex-wrap items-center justify-end gap-2 p-0 px-2 pt-2 mb-4 md:mb-0 lg:pt-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			<div className={styles.search}>
				<input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='يمكنك البحث عن طريق اسم الخدمة'
				/>
				<AiOutlineSearch color='#898989' size={"18px"} />
			</div>
			<div className='flex flex-col-reverse flex-wrap items-end justify-end flex-1 gap-4 p-0 mb-2 sm:flex-row md:flex-nowrap'>
				<div className='flex items-center gap-2'>
					{permissions?.includes("admin.service.deleteall") &&
						numSelected > 0 && (
							<div
								className='rounded-full fcc'
								style={{
									width: "130px",
									backgroundColor: "rgba(255, 56, 56, 0.1)",
									cursor: "pointer",
								}}
								onClick={() => {
									setNotificationTitle(
										"سيتم حذف جميع الخدمات التي قمت بتحديدها"
									);
									setItems(itemsSelected);
									setActionType("deleteAll");
								}}>
								<h2
									className={
										"font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0] whitespace-nowrap"
									}
									style={{ color: "#FF3838" }}>
									حذف الكل
								</h2>
								<IconButton>
									<Delete
										style={{
											cursor: "pointer",
											color: "red",
											fontSize: "0.8rem",
										}}
									/>
								</IconButton>
							</div>
						)}
				</div>
				<div className='flex items-center'>
					<h2 className='font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0] whitespace-nowrap'>
						تحديد الكل
					</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#1948a0",
							"& .MuiSvgIcon-root": {
								color: "#1948a0",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/>
				</div>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const navigate = useNavigate();
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;

	const [selected, setSelected] = React.useState([]);

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	let services = fetchedData?.services || [];

	if (search !== "") {
		services = searchResults;
	} else {
		services = fetchedData?.Services;
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = services?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	// change status for single item
	const [changeStatusService] = useChangeStatusServiceMutation();
	const changePageStatus = async (id) => {
		try {
			await changeStatusService({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [serviceDeleteAll] = useServiceDeleteAllMutation();
	const serviceDeleteAllHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await serviceDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					} else {
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [serviceDeleteItem] = useServiceDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await serviceDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const [searchInServices, { isLoading: searchIsLoading }] =
		useSearchInServicesMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInServices({
							query: search,
						});

						setSearchResults(response?.data?.data?.services);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(services);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={services?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
				/>
				<TableContainer>
					<Table size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							onSelectAllClick={handleSelectAllClick}
						/>
						<TableBody>
							{loading || searchIsLoading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{services?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										services?.map((row, index) => {
											const isItemSelected = isSelected(row.id);
											const labelId = `enhanced-table-checkbox-${index}`;

											return (
												<TableRow
													hover
													role='checkbox'
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={row.id}
													selected={isItemSelected}>
													<TableCell component='th' id={labelId} scope='row'>
														<div className='flex items-center gap-2'>
															{permissions?.includes(
																"admin.service.deleteall"
															) && (
																<Delete
																	title='حذف'
																	onClick={() => {
																		setActionDelete(
																			"سيتم حذف الخدمة وهذة الخطوة غير قابلة للرجوع"
																		);
																		setItemId(row?.id);
																	}}
																	style={{
																		cursor: "pointer",
																		color: "red",
																		fontSize: "1rem",
																	}}
																/>
															)}
															{permissions?.includes(
																"admin.service.update"
															) && (
																<EditButton
																	title='تعديل'
																	className={styles.info_icon}
																	onClick={() => {
																		navigate(
																			`/services/edit-service/${row?.id}`
																		);
																	}}
																/>
															)}
															{permissions?.includes(
																"admin.service.showdetail"
															) && (
																<InfoIcon
																	title='تفاصيل'
																	className={styles.info_icon}
																	onClick={() => {
																		navigate(
																			`/services/show-service/${row?.id}`
																		);
																	}}
																/>
															)}
														</div>
													</TableCell>
													<TableCell align='center'>
														{permissions?.includes(
															"admin.service.changestatus"
														) && (
															<Switch
																onChange={() => changePageStatus(row?.id)}
																sx={switchStyle}
																checked={row?.status === "نشط" ? true : false}
															/>
														)}
													</TableCell>
													<TableCell align='center'>
														<div
															className='min-w-[5rem] h-full py-1 rounded-2xl text-lg font-normal'
															style={{
																backgroundColor:
																	row?.status === "نشط"
																		? "#3AE37466"
																		: "#D3D3D3",
																marginLeft: "auto",
															}}>
															<h2 className='md:text-[18px] text-[16px]  text-[#1948a0]whitespace-nowrap'>
																{row?.status}
															</h2>
														</div>
													</TableCell>
													<TableCell align='center'>
														<div className=''>
															<h2
																dir='rtl'
																className='font-normal md:text-[18px] text-[16px]  text-[#1948a0]whitespace-nowrap'>
																{row?.pendingServices}
															</h2>
														</div>
													</TableCell>
													<TableCell align='center'>
														<div className=''>
															<h2
																dir='rtl'
																className='font-normal md:text-[18px] text-[16px]  text-[#1948a0]whitespace-nowrap'>
																{row?.price} ر.س
															</h2>
														</div>
													</TableCell>

													<TableCell align='right'>
														<div className='flex flex-row items-center justify-end gap-3'>
															<h2 className='inline font-normal md:text-[18px] text-[16px]  text-[#1948a0]whitespace-nowrap'>
																{row?.name}
															</h2>

															<div
																className='p-0 img_icons'
																style={{
																	minWidth: "35px",
																	minHeight: "35px",
																	maxWidth: "35px",
																	maxHeight: "35px",
																}}>
																<img
																	src={row?.file ? row?.file : PlaceholderImage}
																	alt={row?.name}
																	loading='lazy'
																	style={{
																		width: "100%",
																		height: "100%",
																		objectFit: "cover",
																		borderRadius: "50%",
																	}}
																/>
															</div>
														</div>
													</TableCell>
													<TableCell
														className='font-normal md:text-[18px] text-[16px]  text-[#1948a0]whitespace-nowrap'
														align='right'>
														{(index + 1).toLocaleString("en-US", {
															minimumIntegerDigits: 2,
															useGrouping: false,
														})}
													</TableCell>
													<TableCell padding='none' align={"right"}>
														<Checkbox
															checkedIcon={<CheckedSquare />}
															sx={{
																color: "#1948a0",
																"& .MuiSvgIcon-root": {
																	color: "#1948a0",
																},
															}}
															checked={isItemSelected}
															onClick={(event) => handleClick(event, row.id)}
															inputProps={{
																"aria-labelledby": labelId,
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{search ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}

			{notificationTitle && (
				<DeleteModalComp handleDeleteAllItems={serviceDeleteAllHandel} />
			)}
		</Box>
	);
}
