import React, { useState, useContext, Fragment, useEffect } from "react";
import styles from "./SEOPage.module.css";
import { Helmet } from "react-helmet";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../store/LoadingProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import TextareaCode from "../../../components/TextareaCode/TextareaCode";
import Button from "../../../UI/Button/Button";
import {
	LinkIcon,
	KeyWordsIcon,
	SEOSnapIcon,
	SEOTwitterIcon,
	SEOTiktokIcon,
	SEOInstagramIcon,
} from "../../../assets/Icons";
import {
	useGetSeoDataQuery,
	useUpdateSeoMutation,
} from "../../../RTK/apiSlices/seoApi";

const SEOPage = () => {
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { data: fetchedData, isLoading: loading } = useGetSeoDataQuery();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [link, setLink] = useState("");
	const [snapchat, setSnapchat] = useState("");
	const [twitter, setTwitter] = useState("");
	const [tiktok, setTiktok] = useState("");
	const [instagram, setInstagram] = useState("");
	const [keyWords, setKeyWords] = useState([]);
	const [DataError, setDataError] = useState({
		link: "",
		snappixel: "",
		twitterpixel: "",
		tiktokpixel: "",
		instapixel: "",
		key_words: "",
	});
	const resetData = () => {
		setDataError({
			link: "",
			snappixel: "",
			twitterpixel: "",
			tiktokpixel: "",
			instapixel: "",
			key_words: "",
		});
	};
	const beforeAddValidate = (keyword) => {
		return keyword.trim() !== "";
	};

	useEffect(() => {
		setLink(fetchedData?.Seo?.[0]?.google_analytics || "");
		setSnapchat(fetchedData?.Seo?.[0]?.snappixel || "");
		setTwitter(fetchedData?.Seo?.[0]?.twitterpixel || "");
		setTiktok(fetchedData?.Seo?.[0]?.tiktokpixel || "");
		setInstagram(fetchedData?.Seo?.[0]?.instapixel || "");
		setKeyWords(fetchedData?.Seo?.[0]?.key_words?.map((key) => key) || []);
	}, [fetchedData?.Seo]);
	const [updateSeo] = useUpdateSeoMutation();
	const updateSeoHandel = async (formData) => {
		try {
			const res = await updateSeo({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
			} else {
				setLoadingTitle(null);
				setDataError({
					link: res?.data?.message?.en?.google_analytics?.[0],
					snappixel: res?.data?.message?.en?.snappixel?.[0],
					twitterpixel: res?.data?.message?.en?.twitterpixel?.[0],
					tiktokpixel: res?.data?.message?.en?.tiktokpixel?.[0],
					instapixel: res?.data?.message?.en?.instapixel?.[0],
					key_words: res?.data?.message?.en?.key_words?.[0],
				});
				toast.error(res?.data?.message?.en?.google_analytics?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.snappixel?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.twitterpixel?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.tiktokpixel?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.instapixel?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.key_words?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleUpdateSEO = (data) => {
		setLoadingTitle("جاري تحديث إعدادات الSEO");
		resetData();
		let formData = new FormData();
		formData.append("google_analytics", link);
		formData.append("snappixel", snapchat);
		formData.append("twitterpixel", twitter);
		formData.append("tiktokpixel", tiktok);
		formData.append("instapixel", instagram);
		formData.append(
			"key_words",
			keyWords?.length === 0 ? "" : keyWords?.join(",")
		);
		updateSeoHandel(formData);
	};

	return (
		<Fragment>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | إعدادات SEO</title>
			</Helmet>
			<div
				className={`relative xl:pb-10 2xl:pr-8 2xl:pl-52 p-4`}
				style={{ backgroundColor: "#F7F7F7" }}>
				<h3
					style={{ color: "#011723" }}
					className='md:text-[24px] text-[20px] font-bold'>
					إعدادات SEO
				</h3>
				<div className='mt-8'>
					<div className='flex flex-col gap-[24px] flex-wrap mt-4'>
						{loading ? (
							<CircularLoading />
						) : (
							<>
								<div className='flex flex-col gap-[10px]'>
									<div className='flex flex-row gap-[10px] items-center'>
										<LinkIcon />
										<h6 className='text-[18px] font-medium text-[#200407]'>
											ربط جوجل أناليتكس Google Analytics
										</h6>
									</div>
									<input
										className='h-[56px] w-full bg-[#EEEEEF] border-none outline-none px-2 rounded-md'
										style={{ boxShadow: "0px 3px 6px #E622370F" }}
										type='text'
										placeholder='https://analytics.google.com/analytics/web/#/report'
										value={link}
										onChange={(e) => setLink(e.target.value)}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.link}
									</span>
								</div>
								<div className={`${styles.keywords} flex flex-col gap-[10px]`}>
									<div className='flex flex-row gap-[10px] items-center'>
										<KeyWordsIcon />
										<h6 className='text-[18px] font-medium text-[#200407]'>
											الكلمات المفتاحية
										</h6>
									</div>
									<TagsInput
										value={keyWords}
										onChange={setKeyWords}
										beforeAddValidate={beforeAddValidate}
										name='key_words'
										placeHolder='ضع الكلمة ثم اضغط enter'
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.key_words}
									</span>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex lg:flex-row flex-col lg:gap-[60px] gap-2'>
										<div className='min-w-[180px] flex flex-row gap-[10px] items-center'>
											<SEOSnapIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												سناب بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={snapchat}
												setValue={setSnapchat}
												placeholder='Snapchat pixel code'
											/>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.snappixel}
									</span>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex lg:flex-row flex-col lg:gap-[60px] gap-2'>
										<div className='min-w-[180px] flex flex-row gap-[10px] items-center'>
											<SEOTiktokIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												تيك توك بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={tiktok}
												setValue={setTiktok}
												placeholder='Tiktok pixel code'
											/>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.tiktokpixel}
									</span>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex lg:flex-row flex-col lg:gap-[60px] gap-2'>
										<div className='min-w-[180px] flex flex-row gap-[10px] items-center'>
											<SEOTwitterIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												تويتر بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={twitter}
												setValue={setTwitter}
												placeholder='Twitter pixel code'
											/>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.twitterpixel}
									</span>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex lg:flex-row flex-col lg:gap-[60px] gap-2'>
										<div className='min-w-[180px] flex flex-row gap-[10px] items-center'>
											<SEOInstagramIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												انستجرام بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={instagram}
												setValue={setInstagram}
												placeholder='Instagram pixel code'
											/>
										</div>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.instapixel}
									</span>
								</div>
								<div className='flex justify-center gap-4 p-4 md:p-8'>
									{permissions?.includes("admin.seo.update") && (
										<Button
											onClick={handleUpdateSEO}
											btnType={"button"}
											className='md:w-[186px] save_button w-full md:h-[56px] h-[45px] md:text-2xl text-[18px] font-medium'
											textStyle={{ color: "#EFF9FF" }}
											type={"normal"}>
											حفظ
										</Button>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default SEOPage;
