import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./TableComp.module.css";
/* Third party */
/* Contexts */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import NewPagination from "../../PaginationComp/NewPagination";
import DynamicMenu from "../../DynamicMenu/DynamicMenu";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { CgSandClock } from "react-icons/cg";
import { Delete, SortIcon, DocumentIcon } from "../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import { useTechnicalSupportDeleteItemMutation } from "../../../RTK/apiSlices/technicalSupportApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";

const stateChanges = [
	{ value: "منتهية", color: "#3AE374", icon: <FaCheck fill='#fff' /> },
	{
		value: "غير منتهية",
		color: "#D3D3D3",
		icon: <BsClockHistory fill='#fff' />,
	},
	{
		value: "قيد المعالجة",
		color: "#FF9F1A",
		icon: <CgSandClock fill='#fff' />,
	},
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		sortLabel: "supportstatus",
	},
	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "نوع الاتصال",
		sort: true,
		sortLabel: "type",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "الأنشطة",
	},
	{
		id: "activity",
		numeric: true,
		disablePadding: false,
		label: "اسم المتجر",
	},
	{
		id: "title",
		numeric: true,
		disablePadding: false,
		sort: true,
		label: "عنوان الشكوى",
		sortLabel: "title",
	},
	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#feb17e", borderRadius: "4px" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						className='text-lg font-medium '
						key={headCell.id}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#FFF",
							whiteSpace: "nowrap",
						}}>
						{headCell.sort && (
							<TableSortLabel
								sx={{ "& path": { fill: "#FFF" } }}
								IconComponent={() => {
									return <SortIcon fill='#FFF' />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
function EnhancedTableToolbar(props) {
	const { numSelected } = props;
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				gap: "2rem",
				justifyContent: "flex-end",
			}}></Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	technicalsupports,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	search,
}) {
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(9);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [userMenuOpenedId, setUserMenuOpenedId] = React.useState(null);
	const [rowAnchorEl, setRowAnchorEl] = React.useState(null);
	const navigate = useNavigate();
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const { setItemId, setActionDelete, actionDelete } = DeleteStore;

	const handleOptionsClick = (event) => {
		setAnchorEl(event.currentTarget);
		setUserMenuOpenedId(event.currentTarget.id);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setRowAnchorEl(null);
		setUserMenuOpenedId(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = technicalsupports?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - technicalsupports?.length)
			: 0;

	const [technicalSupportDeleteItem] = useTechnicalSupportDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await technicalSupportDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					numSelected={selected.length || 0}
					rowCount={technicalsupports?.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={technicalsupports?.length || 0}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={7}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{technicalsupports?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={7}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(
											technicalsupports,
											getComparator(order, orderBy)
										)?.map((row, index) => {
											const isItemSelected = isSelected(row.id);
											const labelId = `enhanced-table-checkbox-${index}`;
											const findStateChanges = stateChanges.find(
												(i) => row.supportstatus === i.value
											);
											return (
												<TableRow
													hover
													role='checkbox'
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={row.id}
													selected={isItemSelected}>
													<TableCell component='th' id={labelId} scope='row'>
														<Button
															id={index}
															aria-controls={
																userMenuOpenedId ? "basic-menu" : undefined
															}
															aria-haspopup='true'
															aria-expanded={
																userMenuOpenedId ? "true" : undefined
															}
															onClick={handleOptionsClick}>
															<BsThreeDotsVertical
																onClick={() => {}}
																style={{
																	cursor: "pointer",
																	color: "#000000",
																	fontSize: "1.2rem",
																}}></BsThreeDotsVertical>
														</Button>
														<Menu
															id='basic-menu'
															anchorEl={anchorEl}
															open={userMenuOpenedId == index}
															onClose={handleClose}
															MenuListProps={{
																"aria-labelledby": "basic-button",
															}}>
															{permissions?.includes(
																"admin.technicalsupport.show"
															) && (
																<MenuItem
																	className='md:text-lg text-[16px] whitespace-nowrap font-normal'
																	onClick={() => {
																		navigate(
																			`/technical_support/show-details/${row?.id}`
																		);
																	}}>
																	<DocumentIcon
																		className={`w-5 h-5 ml-2 ${styles.deleteIcon} `}
																		style={{
																			cursor: "pointer",
																			color: "#4875c2",
																		}}></DocumentIcon>
																	التفاصيل
																</MenuItem>
															)}
															{permissions?.includes(
																"admin.technicalsupport.deleteall"
															) && (
																<MenuItem
																	onClick={() => {
																		setActionDelete(
																			"سيتم حذف الشكوى وهذة الخطوة غير قابلة للرجوع"
																		);
																		setItemId(row?.id);
																	}}
																	className='md:text-lg text-[16px] whitespace-nowrap'>
																	<Delete
																		className={`w-5 h-5 ml-2 ${styles.deleteIcon}`}
																		style={{
																			cursor: "pointer",
																			color: "#4875c2",
																		}}></Delete>
																	حذف
																</MenuItem>
															)}
														</Menu>
													</TableCell>
													<TableCell
														className='pr-0'
														align='right'
														sx={{ "& path": { fill: "#fff" } }}>
														<div
															className={
																"flex ml-auto gap-2 items-center justify-center rounded-full p-2"
															}
															style={{
																backgroundColor: findStateChanges?.color,
																width: "131px",
															}}>
															<h2 className='text-base font-normal text-slate-50'>
																{row?.supportstatus}
															</h2>
															<div
																className={`w-4 h-4 flex items-center justify-center ${styles.icons}`}>
																{findStateChanges?.icon}{" "}
															</div>
														</div>
													</TableCell>
													<TableCell align='right'>
														<h2 className='font-normal md:text-lg text-[16px] text-[#4875c2] whitespace-nowrap'>
															{row?.type}
														</h2>
													</TableCell>
													<TableCell className='min-w-[14rem]' align='right'>
														<div className='flex flex-row items-center justify-end gap-3'>
															{row?.activity?.length > 1 && (
																<DynamicMenu items={row?.activity} />
															)}
															<h2
																style={{
																	color: "#4D4F5C",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	whiteSpace: "nowrap",
																	direction: "rtl",
																	width: "120px",
																}}
																className='md:text-[16px] text-[14px] inline whitespace-nowrap font-normal'>
																{row?.store?.activity?.[0]?.name}
															</h2>
															{row?.store?.activity?.[0]?.icon && (
																<div className='img_icons'>
																	<img
																		src={row?.store?.activity?.[0]?.icon}
																		alt={row?.store?.activity?.[0]?.name}
																		loading='lazy'
																	/>
																</div>
															)}
														</div>
													</TableCell>
													<TableCell align='right'>
														<h2 className='font-normal md:text-lg text-[16px] text-[#4875c2] whitespace-nowrap'>
															{row?.store?.store_name}
														</h2>
													</TableCell>
													<TableCell align='right' className='max-w-[240px]'>
														<h2
															className='w-full overflow-hidden font-normal md:text-lg text-[16px] text-[#4875c2] whitespace-nowrap'
															style={{
																textOverflow: "ellipsis",
																direction: "rtl",
															}}>
															{row?.title}
														</h2>
													</TableCell>
													<TableCell
														align='right'
														className='font-normal md:text-lg text-[16px] text-[#4875c2] whitespace-nowrap'>
														{(index + 1).toLocaleString("en-US", {
															minimumIntegerDigits: 2,
															useGrouping: false,
														})}
													</TableCell>
												</TableRow>
											);
										})
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{search ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
		</Box>
	);
}
