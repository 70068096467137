import React, { useState, useEffect } from "react";
import styles from "./ShowCurrentMarket.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { PhoneIcon, User } from "../../../../assets/Icons";
import { useGetMarketDataQuery } from "../../../../RTK/apiSlices/marketsApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const ShowCurrentMarket = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data: fetchedData, isLoading: loading } = useGetMarketDataQuery({
		marketId: id,
	});

	// Define storeInfo object

	const [storeInfo, setStoreInfo] = useState({
		store_name: "",
		domain: "",
		domain_type: "",
		store_email: "",
		phonenumber: "",
		country_name: "",
		city_name: "",
		activity_name: [],
		sub_activity_name: [],
		name: "",
		image: "",
		user_name: "",
		user_email: "",
		password: "",
		userphonenumber: "",
		user_country_name: "",
		user_city_name: "",
		status: "",
		verification_status: "",
	});

	const handleOnchangeValue = (e) => {
		const { name, value } = e.target;

		setStoreInfo((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	/** --------------------------------------------- */

	// UseEffect To Display all data about current Store
	useEffect(() => {
		if (fetchedData) {
			setStoreInfo({
				store_name: fetchedData?.stores?.store_name,
				domain: fetchedData?.stores?.domain,
				domain_type: fetchedData?.stores?.domain_type,
				store_email: fetchedData?.stores?.store_email,
				phonenumber: fetchedData?.stores?.phonenumber?.startsWith("+966")
					? fetchedData?.stores?.phonenumber?.slice(4)
					: fetchedData?.stores?.phonenumber?.startsWith("00966")
					? fetchedData?.stores?.phonenumber?.slice(5)
					: fetchedData?.stores?.phonenumber,
				activity_name: fetchedData?.stores?.activity,
				sub_activity_name: fetchedData?.stores?.subcategory,
				country_name: fetchedData?.stores?.country?.name,
				city_name: fetchedData?.stores?.city?.name,
				name: fetchedData?.stores?.user?.name,
				image: fetchedData?.stores?.user?.image,
				user_name: fetchedData?.stores?.user?.user_name,
				user_email: fetchedData?.stores?.user?.email,
				password: fetchedData?.stores?.package,
				userphonenumber: fetchedData?.stores?.user?.phonenumber?.startsWith(
					"+966"
				)
					? fetchedData?.stores?.user?.phonenumber?.slice(4)
					: fetchedData?.stores?.user?.phonenumber?.startsWith("00966")
					? fetchedData?.stores?.user?.phonenumber?.slice(5)
					: fetchedData?.stores?.user?.phonenumber,
				user_country_name: fetchedData?.stores?.user?.country?.name,
				user_city_name: fetchedData?.stores?.user?.city?.name,
				status: fetchedData?.stores?.status,
				verification_status: fetchedData?.stores?.verification_status,
			});
		}
	}, [fetchedData]);

	const handleOpenStore = (domain) => {
		window.open(`https://${domain}`, "_blank");
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | تفاصيل المتجر</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/markets");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<div className='flex flex-col justify-between h-full'>
					<div
						className='md:pt-12 pt-24 md:pr-16 md:p-8 px-5 py-[20px]'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<h2 className='font-bold md:text-[22px] text-[20px] text-right mb-3'>
							تفاصيل المتجر
						</h2>
						<div className='flex flex-row items-center'>
							<div
								onClick={() => {
									navigate("/markets");
								}}
								className={"flex items-center gap-2 cursor-pointer"}>
								<div
									className='flex flex-col items-center justify-center'
									style={{
										width: "30px",
										height: "30px",
										borderRadius: "50%",
										backgroundColor: "#1948a01A",
									}}>
									<GoArrowRight
										style={{ color: "#1948a0", fontSize: "1.2rem" }}
									/>
								</div>
								<h2 className='ml-4 md:text-[18px] text-[16px]'>
									{" "}
									عرض المتاجر{" "}
								</h2>
							</div>
							<h3
								className='md:text-[18px] text-[16px]'
								style={{ color: "#4875c2" }}>
								/ تفاصيل المتجر
							</h3>
						</div>
					</div>

					{loading ? (
						<div className='flex items-center justify-center w-full h-full '>
							<CircularLoading />
						</div>
					) : (
						<section className='flex-1 overflow-y-scroll'>
							<div
								style={{ backgroundColor: "#F6F6F6" }}
								className={`flex-1 overflow-y-scroll md:pl-12 text-right md:py-5 md:pr-16 p-4 ${styles.content}`}>
								<div className='flex flex-col gap-4'>
									<h3
										style={{ color: "#011723" }}
										className='md:text-[22px] text-[20px] font-bold'>
										بيانات المتجر
									</h3>

									{/* Store Name */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											اسم المتجر
										</label>
										<div className='flex flex-col'>
											{storeInfo?.store_name ? (
												<input
													disabled
													type='text'
													name='store_name'
													value={storeInfo?.store_name}
													onChange={handleOnchangeValue}
													placeholder='حروف عربية أو انجليزية'
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md outline-none'
													style={{
														backgroundColor: "#EEF4FC",
														border: "1px solid #F0F0F0",
													}}
												/>
											) : (
												<div className=' text-[16px] text-red-6'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* Domain */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											الدومين{" "}
											<span className='text-sm text-[#1948a0]'>
												{storeInfo?.domain_type === "later_time"
													? "(في وقت لاحق)"
													: storeInfo?.domain_type === "has_domain"
													? "(لدي دومين)"
													: storeInfo?.domain_type === "pay_domain"
													? "(أريد شراء دومين)"
													: null}
											</span>
										</label>

										<div className='flex flex-col'>
											{storeInfo?.domain ? (
												<div
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
													style={{
														backgroundColor: "#EEF4FC",
														border: "1px solid #F0F0F0",
														cursor:
															storeInfo?.verification_status === "تم التوثيق"
																? "pointer"
																: "",
													}}>
													{storeInfo?.verification_status === "تم التوثيق" ? (
														<div
															onClick={() => {
																handleOpenStore(storeInfo?.domain);
															}}
															className='w-full text-left outline-none'
															style={{
																backgroundColor: "#EEF4FC",
																border: "1px solid #F0F0F0",
																direction: "ltr",
															}}>
															{storeInfo?.domain}
														</div>
													) : (
														<span
															className='w-full text-left outline-none'
															style={{
																backgroundColor: "#EEF4FC",
																border: "1px solid #F0F0F0",
																direction: "ltr",
															}}>
															{storeInfo?.domain}
														</span>
													)}
												</div>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* Email */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											البريد الإلكتروني
										</label>
										<div className='flex flex-col'>
											{storeInfo?.store_email ? (
												<input
													disabled
													type='email'
													name='store_email'
													value={storeInfo?.store_email}
													onChange={handleOnchangeValue}
													placeholder='name@name.com'
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md outline-none'
													style={{
														backgroundColor: "#EEF4FC",
														border: "1px solid #F0F0F0",
														direction: "ltr",
													}}
												/>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* Country */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											الدولة
										</label>
										<div className='flex flex-col'>
											{storeInfo?.country_name ? (
												<input
													disabled
													type='text'
													name='country_name'
													value={storeInfo?.country_name}
													onChange={handleOnchangeValue}
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md outline-none'
													style={{
														backgroundColor: "#EEF4FC",
														border: "1px solid #F0F0F0",
													}}
												/>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* City */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											المدينة
										</label>
										<div className='flex flex-col'>
											{storeInfo?.city_name ? (
												<input
													disabled
													type='text'
													name='city_name'
													value={storeInfo?.city_name}
													onChange={handleOnchangeValue}
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md outline-none'
													style={{
														backgroundColor: "#EEF4FC",
														border: "1px solid #F0F0F0",
													}}
												/>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* Phone number */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											رقم الجوال
										</label>
										<div className='flex flex-col'>
											{storeInfo?.phonenumber ? (
												<div
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
													style={{
														backgroundColor: "#fff",
														border: "1px solid #f75e22",
													}}>
													<div className='flex flex-row items-center w-full'>
														<PhoneIcon className={styles.icon} />
														<div
															className='w-full flex flex-row items-center gap-[1px]'
															style={{
																backgroundColor: "transparent",
																direction: "ltr",
															}}>
															<span>+966</span>
															<input
																type='tel'
																name='phonenumber'
																value={storeInfo?.phonenumber}
																onChange={handleOnchangeValue}
																placeholder={500000000}
																className='w-full outline-none'
																style={{
																	backgroundColor: "transparent",
																	direction: "ltr",
																}}
															/>
														</div>
													</div>
												</div>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* activity_name */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											النشاط الرئيسي
										</label>

										<div className='flex flex-col'>
											{storeInfo?.activity_name?.length !== 0 ? (
												<div
													className='w-[555px] min-h-[56px] max-h-48  max-w-full py-4 px-5 rounded-md overflow-auto flex flex-wrap justify-start items-center gap-1'
													style={{
														backgroundColor: "#fff",
														border: "1px solid #f75e22",
													}}>
													{storeInfo?.activity_name?.map((category) => (
														<div
															key={category?.id}
															className='px-2 bg-white rounded '>
															{category?.name}
														</div>
													))}
												</div>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* sub_activity_name */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											الأنشطة الفرعية
										</label>
										<div className='flex flex-col'>
											{storeInfo?.sub_activity_name?.length !== 0 ? (
												<div
													className='w-[555px] min-h-[56px] max-h-48 max-w-full py-4 px-5 rounded-md overflow-auto flex flex-wrap justify-start items-center gap-1'
													style={{
														backgroundColor: "#fff",
														border: "1px solid #f75e22",
													}}>
													{storeInfo?.sub_activity_name?.map((subCategory) => (
														<div
															key={subCategory?.id}
															className='px-2 bg-white rounded '>
															{subCategory?.name}
														</div>
													))}
												</div>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											اسم المستخدم
										</label>
										<div className='flex flex-col'>
											{storeInfo?.user_name ? (
												<div
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
													style={{
														backgroundColor: "#EEF4FC",
														border: "1px solid #F0F0F0",
													}}>
													<User className={styles.icon} />
													<input
														disabled
														type='text'
														name='user_name'
														value={storeInfo?.user_name}
														onChange={handleOnchangeValue}
														placeholder='K22'
														className='w-full outline-none'
														style={{ backgroundColor: "transparent" }}
													/>
												</div>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<h2 className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											الصورة الشخصية
										</h2>
										<div className='flex flex-col'>
											{storeInfo?.image ? (
												<div className='w-36 border-[1px] border-solid border-slate-300 rounded p-1 overflow-hidden'>
													<img
														src={storeInfo?.image}
														alt=''
														className='max-w-full'
														loading='lazy'
													/>
												</div>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>

									{/* status */}
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											الحالة
										</label>

										<div className='flex flex-col'>
											{storeInfo?.status ? (
												<input
													disabled
													type='text'
													name='status'
													value={storeInfo?.status}
													onChange={handleOnchangeValue}
													className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md outline-none'
													style={{
														backgroundColor: "#EEF4FC",
														border: "1px solid #F0F0F0",
													}}
												/>
											) : (
												<div className='text-[16px] text-red-600'>
													لاتوجد بيانات
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div
								className='flex justify-center gap-4 p-8'
								style={{
									backgroundColor: "rgba(235, 235, 235, 1)",
								}}>
								<Button
									onClick={() => {
										navigate("/markets");
									}}
									className={"md:h-14 cancel_button h-[45px] w-full md:w-1/2"}
									textStyle={{ fontSize: "22px" }}
									type={"normal"}>
									إغلاق
								</Button>
							</div>
						</section>
					)}
				</div>
			</div>
		</>
	);
};

export default ShowCurrentMarket;
