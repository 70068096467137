import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const paymentTypeApi = createApi({
	reducerPath: "paymentTypeApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),
	tagTypes: ["PaymentType"],

	endpoints: (builder) => ({
		getPaymentType: builder.query({
			query: () => ({ url: `paymenttype` }),

			transformResponse: (response) => response.data,
			providesTags: ["ShippingType"],
		}),

		changePaymentTypeStatus: builder.mutation({
			query: ({ id }) => ({
				url: `changePaymenttypeStatus/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["ShippingType"],
		}),
	}),
});

// Export endpoints and hooks
export const { useGetPaymentTypeQuery, useChangePaymentTypeStatusMutation } =
	paymentTypeApi;
