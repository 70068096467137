import React from "react";
import styles from "./LoadingPage.module.css";
import logo from "../../assets/images/logo.png";

function LoadingPage() {
	return (
		<div className={styles.Loading}>
			<div className={styles.Logo}>
				<img src={logo} alt='' />
			</div>
		</div>
	);
}

export default LoadingPage;
