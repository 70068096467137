import React, { useState } from "react";
/* Third party */
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";

/* MUI */
import CircularProgress from "@mui/material/CircularProgress";

/* Icons */
import { MdFileUpload } from "react-icons/md";

const ChangeFavicon = ({
	favicon,
	setFavicon,
	curFavicon,
	loading,
	hasPermission,
}) => {
	// to update logo
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;

	// Function to handle changes in the logo
	const onChangeFavicon = (imageList, addUpdateIndex) => {
		// Check if the image size is valid
		const isSizeValid = imageList?.every(
			(image) => image?.file?.size <= maxFileSize
		);

		// If the image size is not valid
		if (!isSizeValid) {
			// Display a warning message and reset the logo state
			toast.warning("حجم الايقونة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});

			setFavicon([]);
			return;
		}

		// Check if the image dimensions are valid
		imageList?.every((image) => {
			const img = new Image();

			img.src = image?.data_url;
			return true; // Returning true because the actual check is done in the onload event
		});

		// If all checks are valid, update the state
		setFavicon(imageList);
	};

	return (
		<>
			<div className='flex flex-col items-center max-w-full px-4 py-7 md:px-0'>
				<ImageUploading
					value={favicon}
					onChange={onChangeFavicon}
					maxNumber={2}
					dataURLKey='data_url'
					acceptType={["jpg", "png", "jpeg", "svg", "webp"]}
					disabled={true}>
					{({ onImageUpload }) => (
						// write your building UI
						<div
							className='relative max-w-full upload__image-wrapper '
							style={{
								width: "572px",
							}}>
							<div className='w-full image-item '>
								<div
									style={{ border: " 1px dashed #1948a0", height: "137px" }}
									className='flex flex-col items-center justify-center w-full gap-6 p-4 rounded-lg'>
									{loading ? (
										<CircularProgress />
									) : (
										<>
											{!favicon[0] && (
												<img
													className='object-contain w-full h-full'
													src={curFavicon}
													alt={curFavicon}
													loading='lazy'
												/>
											)}
											{favicon[0] && (
												<img
													src={favicon[0]?.data_url}
													alt=''
													className='object-contain w-full h-full'
													loading='lazy'
												/>
											)}
										</>
									)}
								</div>
								<span className='text-gray-400 text-[14px] my-2'>
									الحد الأقصى للايقونة 1MB
								</span>

								<div
									disabled={!hasPermission}
									onClick={() => {
										onImageUpload();
									}}
									className='flex items-center justify-between p-4 mt-2 rounded cursor-pointer h-11'
									style={{
										backgroundColor: "#EEF4FC",
										border: "1px dashed #1948a0",
									}}>
									<h2 className='text-base font-medium text-[#1948a0]'>
										ايقونة الموقع للمتصفح
										<span className='text-sm text-[#f75e22]'>
											(المقاس الانسب 32 بكسل عرض 32 بكسل الارتفاع)
										</span>
									</h2>
									<MdFileUpload color='#1948a0' size={"1.25rem"}></MdFileUpload>
								</div>
							</div>
						</div>
					)}
				</ImageUploading>
			</div>
		</>
	);
};

export default ChangeFavicon;
