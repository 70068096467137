import React from "react";
/* Components */
import PieChartSec from "./PieChartSec/PieChartSec";
import MapChartSec from "./MapChartSec/MapChartSec";
/* MUI */
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	color: theme.palette.text.secondary,
}));

const SystemAreaSec = ({ Subscriptions_city, loading }) => {
	return (
		<Box className='mt-5 md:mt-8' sx={{ flexGrow: 1 }}>
			<Grid
				className='flex flex-col items-center md:flex-row'
				container
				spacing={2}>
				<Grid
					className='h-64'
					sx={{ width: "100%", maxWidth: "100%" }}
					item
					lg={4}>
					<Item
						sx={{ height: "1", p: "1rem" }}
						className='shadow-[0px_3px_6px_#272D3B0F] rounded-lg  pb-12'>
						<h2 className='font-normal md:text-lg text-[16px] text-[#1948a0]'>
							إجمالي استخدام النظام
						</h2>
						<PieChartSec />
					</Item>
				</Grid>
				<Grid
					className='h-full lg:h-64'
					sx={{ width: "100%", maxWidth: "100%" }}
					item
					lg={8}>
					<Item
						sx={{ height: "1", p: "1rem" }}
						className='shadow-[0px_3px_6px_#272D3B0F] rounded-lg md:pb-12'>
						<h2
							className='font-normal md:text-lg text-[16px] text-[#1948a0]'
							style={{ color: "#1948a0" }}>
							المناطق الأكثر اشتراكاً
						</h2>
						<MapChartSec
							loading={loading}
							Subscriptions_city={Subscriptions_city}
						/>
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SystemAreaSec;
