import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const etlobhaApi = createApi({
	reducerPath: "etlobhaApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),

	tagTypes: ["EtlobhaProducts"],

	endpoints: (builder) => ({
		// Get Etlobha Products
		etlobhaProductsData: builder.query({
			query: (args) => ({
				url: `etlobha?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["EtlobhaProducts"],
		}),

		// Get Product From Etlobha
		getEtlobhaProductsData: builder.query({
			query: ({ souqProductId }) => ({
				url: `etlobha/${souqProductId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "EtlobhaProducts", id }],
		}),

		// Get Store Category
		storeCategoryData: builder.query({
			query: () => ({
				url: `storecategory`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["StoreCategory"],
		}),

		// Change Product Status
		etlobhaChangeSpecial: builder.mutation({
			query: ({ id }) => ({
				url: `etlobhachangeSpecial/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["EtlobhaProducts"],
		}),

		// Change Status For All Products
		etlobhaChangeSatusAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `etlobhachangeSatusall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["EtlobhaProducts"],
		}),

		// Delete All Products Selector
		etlobhaDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `etlobhadeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["EtlobhaProducts"],
		}),

		// delete singe product
		etlbhaDeleteProduct: builder.mutation({
			query: ({ id }) => ({
				url: `etlobhadeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["EtlobhaProducts"],
		}),

		// Add New Product
		addNewEtlobhaProduct: builder.mutation({
			query: ({ body }) => {
				return {
					url: `etlobha`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["EtlobhaProducts"],
		}),

		// edit New Product
		editEtlobhaProduct: builder.mutation({
			query: ({ body, souqProductId }) => {
				return {
					url: `etlobha/${souqProductId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["EtlobhaProducts"],
		}),

		getFilteredEtlobhaProducts: builder.mutation({
			query: ({ number, category_id, subcategory_ids, page }) => ({
				url: `etlobha?&number=${number}&category_id=${category_id}&${subcategory_ids}&page=${page}`,
				method: "GET",
			}),
		}),
	}),
});

// Export endpoints and hooks
export const {
	useEtlobhaProductsDataQuery,

	useGetEtlobhaProductsDataQuery,

	useStoreCategoryDataQuery,
	useEtlobhaChangeSpecialMutation,
	useEtlobhaChangeSatusAllMutation,
	useEtlbhaDeleteProductMutation,
	useEtlobhaDeleteAllMutation,
	useAddNewEtlobhaProductMutation,
	useEditEtlobhaProductMutation,
	useGetFilteredEtlobhaProductsMutation,
} = etlobhaApi;
