import React, { useState, useEffect, useContext } from "react";

/* Components */
import TrainingCourses from "./TrainingCourses/TrainingCourses";
import AcademyLessons from "./AcademyLessons/AcademyLessons";
import LiveCourses from "./LiveCourses/LiveCourses";
import AcademyTabs from "./AcademyTabs";
import Context from "../../store/context";

const tabs = [
	// { id: 1, title: "الدورات التدريبية", active: true },
	// { id: 2, title: "الدورات المباشرة", active: false },
	{ id: 3, title: "شروحات", active: false },
];

const OtlobhaAcademyComp = ({ setSelectTab }) => {
	const contextStore = useContext(Context);
	const { selectedId, setSelectedId } = contextStore;

	useEffect(() => {
		setSelectTab(selectedId);
	}, [selectedId, setSelectTab]);

	return (
		<>
			<AcademyTabs
				tabs={tabs}
				selectedId={selectedId}
				setSelectedId={setSelectedId}
			/>

			{selectedId === 1 ? (
				<TrainingCourses />
			) : selectedId === 2 ? (
				<LiveCourses />
			) : selectedId === 3 ? (
				<AcademyLessons />
			) : null}
		</>
	);
};

export default OtlobhaAcademyComp;
