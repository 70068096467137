import React, { useContext } from "react";
import styles from "../../../components/OrderPageComp/ServiceOrder/ServiceOrder.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
/* Custom Hook */

/* Contexts */
import { LoadingContext } from "../../../store/LoadingProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* Custom Button */
import Button from "../../../UI/Button/Button";

/* MUI */
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import CircularProgress from "@mui/material/CircularProgress";
/* Icons */
import { MdDomainVerification } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import { IoCalendar } from "react-icons/io5";
import { Category, Call, Store } from "../../../assets/Icons";
import {} from "../../../RTK/apiSlices/domainOrdersApi";

import {
	useGetWebsiteOrderDataByIdQuery,
	useAcceptDomainOrderByIdMutation,
	useRejectDomainOrderByIdMutation,
} from "../../../RTK/apiSlices/domainOrdersApi";

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#FDE3D8",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#FDE3D8",
	},
}));

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const DomainOrdersDetails = () => {
	const { id } = useParams();

	const { data: fetchedData, isLoading: loading } =
		useGetWebsiteOrderDataByIdQuery({ id });

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const navigate = useNavigate();

	const [rejectServiceById] = useRejectDomainOrderByIdMutation();
	const rejectServiceByIdHandel = async (formData) => {
		try {
			const res = await rejectServiceById({
				id: fetchedData?.websiteorders?.id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/domain-orders");
			} else {
				setLoadingTitle(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const rejectService = () => {
		setLoadingTitle("جاري رفض الخدمة");
		let formData = new FormData();
		rejectServiceByIdHandel(formData);
	};

	const [acceptServiceById] = useAcceptDomainOrderByIdMutation();

	const acceptServiceByIdHandel = async (formData) => {
		try {
			const res = await acceptServiceById({
				id: fetchedData?.websiteorders?.id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/domain-orders");
			} else {
				setLoadingTitle(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAcceptService = () => {
		setLoadingTitle("جاري قبول الخدمة");
		let formData = new FormData();
		acceptServiceByIdHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | تفاصيل طلب خدمة</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/domain-orders");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30  ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
					backgroundColor: "rgba(235, 235, 235, 1)",
				}}>
				<div className='flex flex-col justify-between h-full '>
					<div className='flex items-center justify-between p-4 pt-24 md:p-4 md:pl-16 md:pr-16'>
						<div className='w-full'>
							<h2 className='font-semibold md:text-2xl text-[20px] mb-3'>
								تفاصيل الطلب
							</h2>
							<div className='flex'>
								<div className={`flex items-center gap-2 `}>
									<div
										onClick={() => {
											navigate("/domain-orders");
										}}
										className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
										<GoArrowRight
											style={{ color: "#1948a0", fontSize: "1.2rem" }}
										/>
									</div>

									<h2 className='font-semibold md:text-[18px] text-[14px] md:ml-4 ml-2'>
										{" "}
										الطلبات{" "}
									</h2>
								</div>

								<h2
									onClick={() => {
										navigate("/domain-orders");
									}}
									className='font-semibold md:text-[18px] text-[14px] md:ml-4 ml-2 cursor-pointer'>
									{" "}
									/ طلبات الدومين{" "}
								</h2>

								<h3
									className='font-medium md:text-[18px] text-[14px]'
									style={{ color: "#4875c2" }}>
									/ تفاصيل طلب الدومين
								</h3>
							</div>
						</div>
						<div className='flex-col hidden md:flex'>
							<h2
								className={
									"font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0]"
								}
								style={{ color: "#4D4F5C" }}>
								كود الطلب
							</h2>
							<div
								className='flex items-center justify-center h-16 mt-1 rounded-lg shadow-lg w-44'
								style={{ backgroundColor: "#1948a0" }}>
								<h2 className='text-2xl font-medium text-slate-50'>
									{loading ? (
										<CircularProgress color='inherit' size='16px' />
									) : (
										fetchedData?.websiteorders?.order_number
									)}
								</h2>
							</div>
						</div>
					</div>
					<div
						className={`flex-1 overflow-y-scroll md:pl-2 p-2  ${styles.content}`}
						style={{ backgroundColor: "rgb(235, 235, 235)" }}>
						<Box
							className='p-2 md:pt-4 md:pr-2'
							style={{ backgroundColor: "#F6F6F6" }}>
							<TabContext value={"1"}>
								<Box
									sx={{
										"& path, & g": {
											fill: "#eae8ee",
										},
									}}>
									<div
										className={
											"md:mt-8 md:gap-12 gap-0 md:p-6 p-2 flex md:flex-row flex-col justify-between rounded-md"
										}
										style={{
											backgroundColor: "#fff",
											maxWidth: "100%",
										}}>
										{loading ? (
											<div className='flex flex-row items-center justify-center w-full gap-4'>
												<CircularProgress size='24px' />{" "}
												<span>جاري التحميل</span>
											</div>
										) : (
											<>
												<div className='flex flex-col items-center mb-8 md:hidden'>
													<h2
														className={
															"font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0]"
														}
														style={{ color: "#4D4F5C" }}>
														رقم الخدمة
													</h2>
													<div
														className='flex items-center justify-center h-16 mt-1 rounded-lg shadow-lg w-44'
														style={{ backgroundColor: "#1948a0" }}>
														<h2 className='text-2xl font-medium text-slate-50'>
															{loading ? (
																<CircularProgress color='inherit' size='16px' />
															) : (
																fetchedData?.websiteorders?.order_number
															)}
														</h2>
													</div>
												</div>
												<div className='flex-1'>
													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex gap-2'
															style={{ width: "136px" }}>
															<Store></Store>
															<h2>اسم المتجر</h2>
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium'
																style={{ color: "#0077FF" }}>
																{fetchedData?.websiteorders?.store?.store_name}
															</h2>
														</div>
													</div>

													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex gap-2'
															style={{ width: "136px" }}>
															<Call></Call>
															<h2>الهاتف</h2>
														</div>

														<BootstrapTooltip
															className={"p-0"}
															TransitionProps={{ timeout: 300 }}
															TransitionComponent={Zoom}
															title='ارسال رسالة عبر الواتساب'
															placement='top-start'>
															<div
																className={
																	"flex-1 flex items-center justify-center rounded-lg"
																}
																style={{
																	backgroundColor: "#EFF9FF",
																	height: "70px",
																	width: "180px",
																}}>
																<a
																	className='font-medium'
																	style={{
																		color: "#0077FF",
																		direction: "ltr",
																	}}
																	href={`https://wa.me/${
																		fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																			"+966"
																		) ||
																		fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																			"00966"
																		)
																			? fetchedData?.websiteorders?.store
																					?.phonenumber
																			: `+966${fetchedData?.websiteorders?.store?.phonenumber}`
																	}`}
																	target='_blank'
																	rel='noreferrer'>
																	{fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																		"+966"
																	) ||
																	fetchedData?.websiteorders?.store?.phonenumber?.startsWith(
																		"00966"
																	)
																		? fetchedData?.websiteorders?.store
																				?.phonenumber
																		: `+966${fetchedData?.websiteorders?.store?.phonenumber}`}
																</a>
															</div>
														</BootstrapTooltip>
													</div>

													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex gap-2'
															style={{ width: "136px" }}>
															<Category></Category>
															<h2>نوع الدومين</h2>
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium'
																style={{ color: "#0077FF" }}>
																{fetchedData?.websiteorders?.store
																	?.domain_type === "has_domain"
																	? "لدي دومين"
																	: fetchedData?.websiteorders?.store
																			?.domain_type === "pay_domain"
																	? "أريد شراء دومين"
																	: fetchedData?.websiteorders?.store
																			?.domain_type === "later_time"
																	? "في وقت لاحق"
																	: null}
															</h2>
														</div>
													</div>
												</div>
												<div className='flex-1'>
													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex items-center gap-2'
															style={{ width: "136px" }}>
															<IoCalendar></IoCalendar>
															<h2>تاريخ الطلب</h2>
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium'
																style={{ color: "#0077FF" }}>
																{moment(
																	fetchedData?.websiteorders?.created_at
																).format("DD/MM/YYYY")}
															</h2>
														</div>
													</div>

													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex items-center gap-2'
															style={{ width: "136px" }}>
															<MdDomainVerification />
															<h2>اسم الخدمة</h2>
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium px-1'
																style={{ color: "#0077FF" }}>
																{fetchedData?.websiteorders?.services?.map(
																	(service) => service?.name
																)}
															</h2>
														</div>
													</div>
													<div className='flex items-center gap-4 mb-5'>
														<div
															className='flex items-center gap-2'
															style={{ width: "136px" }}>
															<MdDomainVerification />
															<h2>الدومين</h2>
														</div>
														<div
															className={
																"flex-1 flex items-center justify-center rounded-lg"
															}
															style={{
																backgroundColor: "#EFF9FF",
																height: "70px",
																width: "180px",
															}}>
															<h2
																className='font-medium px-1'
																style={{ color: "#0077FF" }}>
																{fetchedData?.websiteorders?.store?.domain}
															</h2>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
								</Box>
							</TabContext>
						</Box>
					</div>

					{fetchedData?.websiteorders?.status === "منتهية" ? (
						<div
							className='flex justify-center gap-4 p-8'
							style={{
								height: "110px",
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<Button
								className={"h-14 w-44"}
								style={{ backgroundColor: `#3AE374` }}
								type={"normal"}
								onClick={() => navigate("/domain-orders")}>
								اغلاق
							</Button>
						</div>
					) : (
						<div
							className='flex justify-center gap-4 p-8'
							style={{
								height: "110px",
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							{permissions?.includes("admin.websiteorder.acceptService") && (
								<Button
									className={"h-14 w-44"}
									style={{ backgroundColor: `#3AE374` }}
									type={"normal"}
									onClick={handleAcceptService}>
									قبول الطلب
								</Button>
							)}
							{permissions?.includes("admin.websiteorder.rejectService") && (
								<Button
									className={"h-14 w-44"}
									style={{ borderColor: `#FF3838` }}
									textStyle={{ color: "#FF3838" }}
									type={"outline"}
									onClick={rejectService}>
									رفض الطلب
								</Button>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default DomainOrdersDetails;
