import React, { useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../components/PageNavigate/PageNavigate";
import ChangeLogoSec from "../../components/TemplatePageComps/MainPage/ChangeLogoSec/ChangeLogoSec";
import ChangeBannerSec from "../../components/TemplatePageComps/MainPage/ChangeBannerSec/ChangeBannerSec";
import { useGetHomePageDataQuery } from "../../RTK/apiSlices/templateApi";

const TemplatePage = () => {
	// Fetch data from API
	const { data: fetchedData, isLoading: loading } = useGetHomePageDataQuery();

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	if (!permissions?.includes("admin.homepage.index")) return null;

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | القالب</title>
			</Helmet>
			<div
				className={`px-4 md:pt-4 pt-0 pb-10`}
				style={{ backgroundColor: "#EEF3FC" }}>
				<div
					className='md:pr-5 py-3 px-2  font-normal md:text-lg text-[14px] bg-[#FDE2D8] rounded-md mb-2'
					style={{ color: "#f75e22" }}>
					هذه الواجهة خاصة بإعدادات الصفحة الرئيسية للموقع الإلكتروني
				</div>
				<div className='mt-4 md:mt-6'>
					<PageNavigate currentPage={"تنسيق القالب"} />
				</div>
				<div>
					{/** change logo AND Change Favicon */}
					<ChangeLogoSec
						loading={loading}
						fetchedData={fetchedData}
						updateLogoPermission={permissions?.includes(
							"admin.homepage.logoUpdate"
						)}
					/>

					{/** change banners  */}
					<ChangeBannerSec
						loading={loading}
						fetchedData={fetchedData}
						updateBannersPermission={permissions?.includes(
							"admin.homepage.banarUpdate"
						)}
					/>
				</div>
			</div>
		</>
	);
};

export default TemplatePage;
