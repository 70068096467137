import React, { useContext, useState } from "react";
import styles from "./NotificationsMenu.module.css";

/* Third party */
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Context from "../../store/context";
import moment from "moment-with-locales-es6";

/* MUI */
import Badge from "@mui/material/Badge";

/* Icons */
import CloseIcon from "@mui/icons-material/Close";
import { MdNotifications } from "react-icons/md";

import {
	useGetNotificationsQuery,
	useMarkSingleNotificationAsReadMutation,
	useNotificationsDeleteItemMutation,
} from "../../RTK/apiSlices/notificationsApi";

const BackDrop = ({ closeMenu }) => {
	return (
		<div
			onClick={closeMenu}
			className='fixed bottom-0 left-0 z-10 w-full h-full back_drop'></div>
	);
};

const NotificationsMenu = () => {
	// get data from api
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const [open, setOpen] = React.useState(false);
	const [countOfNotifications, setCountOfNotifications] = useState(false);
	const { data: fetchedData, error } = useGetNotificationsQuery();

	// delete single item
	const [notificationsDeleteItem] = useNotificationsDeleteItemMutation();
	const deleteNotification = async (id) => {
		try {
			await notificationsDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
						setOpen(false);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	// mark notification as Read api function

	// Mark single notification as read
	const [markSingleNotificationAsRead] =
		useMarkSingleNotificationAsReadMutation();

	const handleMarkSingleNotificationAsRead = async (id) => {
		if (fetchedData?.count_of_notifications === 0) return;

		try {
			await markSingleNotificationAsRead({ notificationId: id })
				.unwrap()

				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					}
				});
		} catch (err) {
			console.error("Failed to delete the markSingleNotificationAsRead", err);
		}
	};

	// formatDate
	const formatDate = (date) => {
		const calcPassedMinutes = (date1, date2) =>
			Math.round(Math.abs(date2 - date1) / (1000 * 60));

		const currentMinutes = calcPassedMinutes(+new Date(), +new Date(date));

		if (currentMinutes < 1) {
			return "الآن";
		} else if (currentMinutes === 1) {
			return "منذ دقيقة";
		} else if (currentMinutes === 2) {
			return "منذ دقيقتين";
		} else if (currentMinutes <= 10) {
			return `منذ ${currentMinutes} دقائق`;
		} else if (currentMinutes < 60 && currentMinutes >= 11) {
			return `منذ ${currentMinutes} دقيقة`;
		} else if (currentMinutes === 60) {
			return "منذ ساعة";
		} else if (currentMinutes === 120) {
			return "منذ ساعتين";
		} else if (currentMinutes < 1440) {
			let hours = Math.floor(currentMinutes / 60);
			let min = currentMinutes % 60;
			if (hours === 1) {
				return `منذ ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else if (hours === 2) {
				return `منذ  ساعتين و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else if (hours <= 10) {
				return `منذ ${hours} ساعات و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else {
				return `منذ ${hours} ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			}
		}

		const currentDate = Math.round(currentMinutes / 60 / 24);

		if (currentDate === 1) {
			return "أمس، الساعة " + moment(date).locale("ar").format(" h:mm a");
		} else if (currentDate === 2) {
			return " منذ يومين، الساعة" + moment(date).locale("ar").format(" h:mm a");
		} else if (currentDate <= 7) {
			return (
				`منذ ${currentDate}  أيام، الساعة` +
				moment(date).locale("ar").format(" h:mm a")
			);
		}

		return moment(date).locale("ar").format("D MMMM YYYY");
	};
	// -----------------------------------------------------------------

	return (
		<div className={`relative ${styles.notification_badge}`}>
			<Badge
				max={50}
				badgeContent={
					countOfNotifications ? 0 : fetchedData?.count_of_notifications
				}
				sx={{
					"& .MuiBadge-badge": {
						backgroundColor: "#ffc06a",
						border: "1px solid #fff",
						color: "#fff",
					},
				}}>
				<MdNotifications
					onClick={() => {
						setOpen(!open);
						setCountOfNotifications(!countOfNotifications);
					}}
					title='الاشعارات'
					style={{
						width: "24px",
						height: "24px",

						cursor: "pointer",
					}}
				/>
			</Badge>
			<div className={`${open ? "flex" : "hidden"}`}>
				<BackDrop
					closeMenu={() => {
						setOpen(false);
					}}
				/>
				<div className={`${styles.notificationMenu} z-20`}>
					{error === "Request failed with status code 403" ? (
						<div className='flex items-center justify-center h-full text-xl text-[#f75e22]'>
							ليس لديك صلاحية الوصول!
						</div>
					) : fetchedData?.notifications.length === 0 ? (
						<div className='flex items-center justify-center h-full text-xl text-[#f75e22]'>
							لا يوجد اشعارات حتي هذة اللحظة!
						</div>
					) : (
						fetchedData?.notifications?.map((item) => (
							<div
								key={item?.id}
								className={`${styles.notification_box} ${
									item?.read_at === null || item?.read_at === ""
										? "bg-[#fafafa]"
										: ""
								} w-full flex flex-row items-center gap-4 hover:bg-[#f0f7fb]`}>
								<div
									style={{ borderRadius: "50%", border: "1px solid #dddddd" }}
									className='img_icons'>
									<img
										className='img_icons'
										src={item?.user?.map((user) => user?.image)}
										alt='notification-img'
										loading='lazy'
									/>
								</div>

								<div className='flex flex-col flex-1'>
									<Link
										to='/notifications'
										onClick={() => {
											setOpen(!open);
											handleMarkSingleNotificationAsRead(item?.id);
										}}>
										<h6
											className={`${
												item?.read_at === null || item?.read_at === ""
													? "font-semibold"
													: ""
											} md:text-[16px] text-[14px] text-blue-400 mb-2`}>
											{item?.store_name}
										</h6>
									</Link>
									<div className='flex flex-row justify-between'>
										<p
											className={` ${
												item?.read_at === null || item?.read_at === ""
													? " font-medium"
													: ""
											} ${
												styles.notification_desc
											} md:text-[16px] text-[14px]`}>
											{item?.message}
										</p>
										<span className='md:text-[14px] text-[12px] text-gray-400 whitespace-nowrap'>
											{formatDate(item?.created_at)}
										</span>
									</div>
								</div>
								<button
									onClick={() => deleteNotification(item?.id)}
									className='p-1 bg-gray-50 rounded-sm'>
									<CloseIcon className='md:text-[1.2rem] text-[0.9rem] cursor-pointer' />
								</button>
							</div>
						))
					)}
				</div>
			</div>
		</div>
	);
};
export default NotificationsMenu;
