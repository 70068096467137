import React, { useEffect, useContext, useState } from "react";
import styles from "./CurrentSubscribersTable.module.css";
import PropTypes from "prop-types";

/* Context */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { NotificationContext } from "../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import { toast } from "react-toastify";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import PlaceholderImage from "../../../assets/images/placeholderImage.png";

/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import { FiSend } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import {
	SortIcon,
	CheckedSquare,
	SwitchOn,
	Delete,
} from "../../../assets/Icons";
import { MdAutorenew } from "react-icons/md";
import {
	useSearchInSubscriptionsMutation,
	useSubscriptionsChangeStatusAllMutation,
	useSubscriptionsDeleteAllMutation,
	useSubscriptionsDeleteItemMutation,
} from "../../../RTK/apiSlices/subscriptionsApi";
import NewPagination from "../../PaginationComp/NewPagination";

import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../DeleteModal/DeleteModal";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "status",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		textAlign: "center",
		sortLabel: "status",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "ينتهى خلال",
		sort: true,
		textAlign: "center",
		sortLabel: "left",
	},
	{
		id: "code",
		numeric: false,
		disablePadding: false,
		label: "كود الخصم",
	},
	{
		id: "payment_method",
		numeric: false,
		disablePadding: false,
		label: "طريقة الدفع",
	},

	{
		id: "payment_method",
		numeric: false,
		disablePadding: false,
		label: "نوع الباقة",
	},

	{
		id: "store_name",
		numeric: true,
		disablePadding: false,
		label: "اسم المتجر",
	},
	{
		id: "ref_number",
		numeric: false,
		disablePadding: false,
		label: "رقم المرجع ",
	},

	{
		id: "number",
		numeric: false,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#feb17e" }}>
			<TableRow>
				{headCells.map((headCell, index) => (
					<TableCell
						key={headCell?.label}
						className='text-lg font-medium '
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#1948a0",
							textAlign: headCell?.textAlign,
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}
								className='text-base font-medium'>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		rowCount,
		onSelectAllClick,
		search,
		setSearch,
		itemsSelected,
	} = props;
	const NotificationStore = useContext(NotificationContext);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	return (
		<Toolbar
			className='flex flex-row flex-wrap items-center justify-end gap-2 p-0 px-2 pt-2 mb-4 md:mb-0 lg:pt-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			<div className={styles.search}>
				<input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='يمكنك البحث عن طريق اسم  الباقة أو اسم المتجر'
				/>
				<AiOutlineSearch color='#898989' size={"18px"} />
			</div>
			<div className='flex flex-col-reverse flex-wrap items-end justify-end flex-1 gap-4 p-0 mb-2 sm:flex-row md:flex-nowrap'>
				<div className='flex items-center gap-2'>
					{permissions?.includes("admin.subscriptions.changeSatusall") &&
						numSelected > 0 && (
							<div
								className='gap-2 px-2 rounded-full fcc'
								style={{
									width: "114px",
									backgroundColor: "rgba(255, 159, 26, 0.04)",
								}}
								onClick={() => {
									setNotificationTitle(
										"سيتم تغيير حالة جميع الاشتراكات التي قمت بتحديدها"
									);

									setItems(itemsSelected);
									setActionType("ChangeStatus");
								}}>
								<h2 className={"font-semibold"} style={{ color: "#FF9F1A" }}>
									تعطيل
								</h2>
								<Box
									sx={{
										"& #Path_820": {
											fill: "#FF9F1A",
										},
									}}>
									<SwitchOn
										style={{
											cursor: "pointer",
											color: "red",
											fontSize: "0.5rem",
										}}
										className={"w-5"}
									/>
								</Box>
							</div>
						)}
					{permissions?.includes("admin.subscriptions.deleteall") &&
						numSelected > 0 && (
							<div
								className='rounded-full fcc'
								style={{
									width: "130px",
									backgroundColor: "rgba(255, 56, 56, 0.1)",
									cursor: "pointer",
								}}
								onClick={() => {
									setNotificationTitle(
										"سيتم حذف جميع الاشتراكات التي قمت بتحديدها"
									);
									setItems(itemsSelected);
									setActionType("deleteAll");
								}}>
								<h2 className={"font-medium"} style={{ color: "#FF3838" }}>
									حذف الكل
								</h2>
								<IconButton>
									<Delete
										style={{
											cursor: "pointer",
											color: "red",
											fontSize: "0.8rem",
										}}
									/>
								</IconButton>
							</div>
						)}
				</div>
				<div className='flex items-center'>
					<h2 className='font-medium'>تحديد الكل</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#1948a0",
							"& .MuiSvgIcon-root": {
								color: "#1948a0",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</div>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	openTraderAlert,
	openEditModal,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	let stores = fetchedData?.stores || [];

	if (search !== "") {
		stores = searchResults?.stores;
	} else {
		stores = fetchedData?.stores;
	}

	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;

	const { notificationTitle } = NotificationStore;
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = stores?.map((n) => n?.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	const [subscriptionsChangeStatusAll] =
		useSubscriptionsChangeStatusAllMutation();

	const subscriptionsChangeStatusAllHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await subscriptionsChangeStatusAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	const [subscriptionsDeleteAll] = useSubscriptionsDeleteAllMutation();
	const subscriptionsDeleteAllHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await subscriptionsDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	const [subscriptionsDeleteItem] = useSubscriptionsDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await subscriptionsDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const [searchInSubscriptions, { isLoading: searchIsLoading }] =
		useSearchInSubscriptionsMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInSubscriptions({
							query: search,
						});

						setSearchResults(response?.data?.data?.plans);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(stores);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={stores?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={stores?.length || 0}
						/>
						<TableBody>
							{loading || searchIsLoading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{stores?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(stores, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row?.id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row?.id}
														selected={isItemSelected}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center justify-center gap-2'>
																{permissions?.includes(
																	"admin.subscriptions.addAlert"
																) && (
																	<FiSend
																		title='ارسال تنبية'
																		onClick={() => {
																			openTraderAlert(row);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "#1948a0",
																			fontSize: "1.2rem",
																		}}
																	/>
																)}

																<>
																	{permissions?.includes(
																		"admin.subscriptions.deleteall"
																	) && (
																		<Delete
																			title='حذف'
																			onClick={() => {
																				setActionDelete(
																					"سيتم حذف اشتراك المتجر وهذة الخطوة غير قابلة للرجوع"
																				);
																				setItemId(row?.id);
																			}}
																			style={{
																				cursor: "pointer",
																				color: "red",
																				fontSize: "1rem",
																			}}
																		/>
																	)}
																</>

																{permissions?.includes(
																	"admin.subscriptions.updateSubscriptionsAll"
																) &&
																	row?.left <= 30 && (
																		<MdAutorenew
																			title=' تعديل مدة الاشتراك'
																			className='text-green-500 w-6 h-6 cursor-pointer'
																			onClick={() => {
																				openEditModal(row);
																			}}
																			width={"18px"}
																		/>
																	)}
															</div>
														</TableCell>

														<TableCell align='center'>
															<div
																className='w-16 text-sm h-full py-[1px] rounded-2xl'
																style={{
																	backgroundColor:
																		row.status === "نشط"
																			? "rgba(58, 227, 116, 0.4)"
																			: "#D3D3D3",
																	margin: "auto",
																}}>
																<h2>{row?.status}</h2>
															</div>
														</TableCell>

														<TableCell align='right'>
															<>
																<h2
																	dir='rtl'
																	className='text-lg font-normal text-center'>
																	{row?.left === 0 ? (
																		<span className='text-red-500 text-xs'>
																			انتهت مدة الإشتراك
																		</span>
																	) : row?.left <= 30 ? (
																		<span className='text-yellow-500 text-xs font-medium'>
																			<div>
																				( متبقي{" "}
																				{row?.left === 1
																					? "يوم واحد"
																					: row?.left === 2
																					? "يومين"
																					: row?.left >= 1 &&
																					  `${row?.left} أيام`}
																				)
																			</div>
																		</span>
																	) : (
																		<div className='flex items-center justify-center gap-1 text-base text-[#1948a0]'>
																			<span>{row?.left}</span>
																			<span>يوم</span>
																		</div>
																	)}
																</h2>
															</>
														</TableCell>
														<TableCell align='right'>
															<div
																className={`${
																	row?.code ? "bg-[#00CCF266]" : ""
																} h-8 w-24 font-normal text-md rounded-3xl ml-auto flex justify-center items-center whitespace-nowrap px-1`}>
																{row?.code ?? (
																	<span className='text-red-500 text-xs'>
																		لا يوجد كود خصم
																	</span>
																)}
															</div>
														</TableCell>

														<TableCell align='right'>
															<h2 className='text-lg font-normal text-center'>
																{row?.payment_type}
															</h2>
														</TableCell>

														<TableCell align='right'>
															<div
																className={` max-w-[120px] font-normal text-md rounded-3xl text-center px-2 py-1 text-ellipsis`}
																style={{
																	backgroundColor:
																		row?.package_name === "متجر ماي براند"
																			? "#a4a1fb66"
																			: row?.package_name === "متجر الاعمال" ||
																			  row?.package_name === "متجر الأعمال"
																			? "#00CCF266"
																			: "#dfdce566",
																}}>
																{row?.package_name}
															</div>
														</TableCell>
														<TableCell
															align='right'
															sx={{
																display: "flex",
																flexDirection: "row-reverse",
																alignItems: "center",
																gap: "0.5rem",
																p: "24px 0",
															}}>
															<div
																className='img_icons'
																style={{
																	minWidth: "35px",
																	minHeight: "35px",
																	maxWidth: "35px",
																	maxHeight: "35px",
																}}>
																<img
																	src={row?.logo ? row?.logo : PlaceholderImage}
																	alt='store_icon'
																	loading='lazy'
																	style={{
																		width: "100%",
																		height: "100%",
																		objectFit: "cover",
																		borderRadius: "50%",
																	}}
																/>
															</div>
															<h2
																style={{
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	whiteSpace: "nowrap",
																	direction: "rtl",
																	width: "130px",
																}}
																className='text-lg font-normal'>
																{row?.store_name}
															</h2>
														</TableCell>

														<TableCell align='right'>
															<h2 className='text-md font-normal text-center'>
																{row?.referance}
															</h2>
														</TableCell>
														<TableCell padding='none' align={"right"}>
															<div className='flex items-center justify-center'>
																<div className='text-lg font-normal'>
																	{(index + 1).toLocaleString("en-US", {
																		minimumIntegerDigits: 2,
																		useGrouping: false,
																	})}
																</div>
																<Checkbox
																	checkedIcon={<CheckedSquare />}
																	sx={{
																		color: "#1948a0",
																		"& .MuiSvgIcon-root": {
																			color: "#1948a0",
																		},
																	}}
																	checked={isItemSelected}
																	onClick={(event) =>
																		handleClick(event, row?.id)
																	}
																	inputProps={{
																		"aria-labelledby": labelId,
																	}}
																/>
															</div>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{!search && (
				<>
					<NewPagination
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						page_count={page_count}
					/>
				</>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleDeleteAllItems={subscriptionsDeleteAllHandel}
					handleChangeAllItemsStatus={subscriptionsChangeStatusAllHandel}
				/>
			)}
		</Box>
	);
}
