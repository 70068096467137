import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create API slice
export const loginApi = createApi({
	reducerPath: "loginApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://backend.modelss.co/api/",
	}),

	endpoints: (builder) => ({
		// create login endpoint
		login: builder.mutation({
			query: (credentials) => ({
				url: `loginadminapi`,
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

// Export endpoints and hooks
export const { useLoginMutation } = loginApi;
