import React from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	ResponsiveContainer,
	CartesianGrid,
} from "recharts";

import "./Graphsec.css";
const GraphSec = ({ array_store }) => {
	const data = [
		{
			name: array_store ? Object.keys(array_store)[0] : "...",
			pv: array_store ? Object.values(array_store)[0] : 0,
		},
		{
			name: array_store ? Object.keys(array_store)[1] : "...",
			pv: array_store ? Object.values(array_store)[1] : 0,
		},
		{
			name: array_store ? Object.keys(array_store)[2] : "...",
			pv: array_store ? Object.values(array_store)[2] : 0,
		},
		{
			name: array_store ? Object.keys(array_store)[3] : "...",
			pv: array_store ? Object.values(array_store)[3] : 0,
		},
		{
			name: array_store ? Object.keys(array_store)[4] : "...",
			pv: array_store ? Object.values(array_store)[4] : 0,
		},
		{
			name: array_store ? Object.keys(array_store)[5] : "...",
			pv: array_store ? Object.values(array_store)[5] : 0,
		},
		{
			name: array_store ? Object.keys(array_store)[6] : "...",
			pv: array_store ? Object.values(array_store)[6] : 0,
		},
	];

	return (
		<div className='h-full'>
			<div className='md:pr-8 pr-5'>
				<h2 className='md:pb-4 font-medium md:text-[22px] text-[16px] text-[#1948a0]'>
					إحصائيات المتاجر
				</h2>
				<h2
					className='font-bold md:text-[22px] text-[14px]'
					style={{ color: "#fc8d5d" }}>
					Avg.
					{array_store
						? (
								Object.values(array_store)[0] ||
								0 + Object.values(array_store)[1] ||
								0 + Object.values(array_store)[2] ||
								0 + Object.values(array_store)[3] ||
								0 + Object.values(array_store)[4] ||
								0 + Object.values(array_store)[5] ||
								0 + Object.values(array_store)[6] ||
								0 / Number(Object.values(array_store).length)
						  ).toFixed(2)
						: "..."}
				</h2>
			</div>
			<div className='flex h-full gap-4'>
				<div
					className='md:mt-8 mt-2'
					style={{ width: "100%", height: "200px" }}>
					<ResponsiveContainer>
						<LineChart width={600} height={100} data={data}>
							<CartesianGrid horizontal={false} />
							<XAxis
								axisLine={false}
								dataKey='name'
								interval={0}
								dx={0}
								tickLine={false}
							/>
							<YAxis
								scale='sqrt'
								tickMargin='60'
								tickLine={false}
								axisLine={false}
								interval='preserveStartEnd'
								orientation={"right"}
								tickFormatter={(e) => {
									return `${e}`;
								}}
							/>

							<Line
								type=''
								dot={false}
								dataKey='pv'
								strokeWidth={3}
								stroke='#feb17e'
								activeDot={{ r: 8 }}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
};

export default GraphSec;
