import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const stockApi = createApi({
	reducerPath: "stockApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),
	tagTypes: ["Stock"],

	endpoints: (builder) => ({
		// Get Stock Data
		stockData: builder.query({
			query: (args) => ({
				url: `stock?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Stock"],
		}),

		// Get Stock Product Data
		getStockProductsData: builder.query({
			query: ({ storageProductId }) => ({
				url: `stock/${storageProductId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Stock", id }],
		}),

		// Search In Stock Products
		searchInStock: builder.mutation({
			query: (arg) => ({
				url: `searchStockName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		// Add To Stock Data
		addProductInStock: builder.mutation({
			query: ({ body }) => {
				return {
					url: `stock`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Stock"],
		}),

		// Edit Product Into Stock Data
		editProductInStock: builder.mutation({
			query: ({ storageProductId, body }) => {
				return {
					url: `stock/${storageProductId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Stock"],
		}),

		// Upload CVS File
		uploadImportProducts: builder.mutation({
			query: ({ body }) => {
				return {
					url: `importproducts`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Stock"],
		}),

		// Delete Select Stock Products
		stockDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `stockdeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Stock"],
		}),

		stockDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `stockdeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Stock"],
		}),

		// Add To Cart Stock
		addToStoreCart: builder.mutation({
			query: ({ id }) => ({
				url: `addToStore/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["Stock"],
		}),

		filterStockByCategory: builder.mutation({
			query: ({ number, category_id, page }) => ({
				url: `stock?&number=${number}&category_id=${category_id}&page=${page}`,
				method: "GET",
			}),
		}),
	}),
});

// Export endpoints and hooks
export const {
	useStockDataQuery,
	useGetStockProductsDataQuery,
	useSearchInStockMutation,
	useAddProductInStockMutation,
	useEditProductInStockMutation,
	useUploadImportProductsMutation,
	useStockDeleteAllMutation,
	useStockDeleteItemMutation,
	useAddToStoreCartMutation,
	useFilterStockByCategoryMutation,
} = stockApi;
