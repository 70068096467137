import React from "react";
/* Custom Hook */

/* Components */
import CircularLoading from "../../UI/CircularLoading/CircularLoading";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useGetReviewDataQuery } from "../../RTK/apiSlices/reviewsApi";

function ReviewDetails({ cancel, id }) {
	const { data: fetchedData, isLoading: loading } = useGetReviewDataQuery({
		id,
	});

	return (
		<>
			<div
				onClick={cancel}
				className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50 z-10`}
				style={{ height: "calc(100% - 4rem)" }}></div>
			<div
				className='fixed md:min-w-[600px] min-w-[90%] max-w-[90%] flex flex-col items-center gap-[22px] bg-[#FFFFFF] top-36 left-[50%] z-10 md:py-10 md:px-20 p-4'
				style={{
					transform: "translateX(-50%)",
					boxShadow: "0px 3px 6px #1948a00F",
					borderRadius: "8px",
				}}>
				<IoMdCloseCircleOutline
					size={"1.25rem"}
					color={"#1948a0"}
					className={"cursor-pointer absolute left-2 top-2"}
					onClick={cancel}></IoMdCloseCircleOutline>
				<p className='md:text-[18px] text-[16px]  text-[#1948a0]text-[#1948a0] text-center'>
					معاينة ظهور التقييم على الصفحة الرئيسية
				</p>
				{loading ? (
					<CircularLoading />
				) : (
					<div
						className='w-full flex flex-col items-center gap-5 p-5 bg-[#EEEEEF]'
						style={{ boxShadow: "0px 3px 6px #1948a00F" }}>
						<div className='rounded-full border-[2px] border-[#dddddd] w-14 h-14 p-2'>
							<img
								src={fetchedData?.comments?.store?.logo}
								alt=' '
								className='w-100 h-100'
								loading='lazy'
							/>
						</div>

						<p
							className='md:text-[18px] text-[16px]  text-[#1948a0]text-[#011723] text-center'
							dangerouslySetInnerHTML={{
								__html: fetchedData?.comments?.comment_text,
							}}
						/>
					</div>
				)}
			</div>
		</>
	);
}

export default ReviewDetails;
