import React from "react";
import Button from "../../../UI/Button/Button";

const Buttons = ({ updatePartitions }) => {
	return (
		<div className='flex gap-4 mt-8'>
			<Button
				onClick={updatePartitions}
				className={"rounded save_button h-14"}
				style={{ width: "180px" }}
				fontSize={"text-xl"}
				type={"normal"}>
				تعديل
			</Button>
			<Button
				className={"rounded h-14"}
				style={{
					backgroundColor: "#fff",
					border: "1px solid #f75e22",
					width: "180px",
				}}
				textStyle={{ color: "#f75e22" }}
				fontSize={"text-xl"}
				type={"normal"}>
				إلغاء
			</Button>
		</div>
	);
};

export default Buttons;
