import React from "react";
/* Custom Hook */

/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import { useGetStatisticsProductsDataQuery } from "../../../../RTK/apiSlices/statisticsApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const ProductDetails = ({ items, closeDetails }) => {
	const { data: fetchedData, isLoading: loading } =
		useGetStatisticsProductsDataQuery({
			id: items?.id,
		});

	return (
		<>
			<BackDrop onClick={closeDetails} />
			<div
				className='fixed flex flex-col top-24 translate-x-2/4 right-2/4 z-20 rounded-md overflow-hidden md:h-[38rem] h-[33rem]'
				style={{ width: "52.25rem", maxWidth: "90%" }}>
				<div
					className='flex items-center justify-between w-full px-4'
					style={{ backgroundColor: "#EEF4FC", minHeight: "65px" }}>
					<h3 className='text-slate-50 md:text-xl text-[16px] font-medium '>
						إحصائيات المنتج - {items?.name}
					</h3>
					<IoMdCloseCircleOutline
						color={"#fff"}
						className={"cursor-pointer w-5 h-5"}
						onClick={closeDetails}></IoMdCloseCircleOutline>
				</div>
				{loading ? (
					<div className='flex flex-col items-center justify-center w-full h-full py-8 bg-white'>
						<CircularLoading />
					</div>
				) : (
					<>
						<div
							className='flex-1 px-5 py-6 md:px-44 md:pt-10'
							style={{ backgroundColor: "rgb(246,246,246)", overflow: "auto" }}>
							<div className='flex flex-col items-center gap-4 md:flex-row md:gap-12'>
								<div className=''>
									<img
										className='object-contain rounded-sm h-28 w-28'
										src={fetchedData?.product?.cover}
										alt='img'
										loading='lazy'
									/>
								</div>
								<div>
									<h2 className='md:mb-8 mb-2 font-medium md:text-2xl text-[18px]'>
										{fetchedData?.product?.name}
									</h2>
									<h2 className='md:text-xl text-[18px] font-normal md:text-right text-center'>
										سعر البيع
										<span className='font-bold md:text-2xl text-[20px] mr-4 '>
											{fetchedData?.product?.selling_price}
										</span>
									</h2>
								</div>
							</div>
							<div className='mt-8'>
								<div className='flex items-center gap-8 mb-4 md:gap-24'>
									<div
										className='md:w-[82px] w-[68px] md:h-14 h-[45px] flex items-center justify-center bg-white rounded font-medium text-[22px]'
										style={{ border: "1px solid #ADB5B9" }}>
										{fetchedData?.import_count}
									</div>
									<div className='flex-1 '>
										<h2 className='font-medium md:text-[22px] text-[18px]'>
											مرات تنزيل المنتج
										</h2>
									</div>
								</div>
								<div className='flex items-center gap-8 mb-4 md:gap-24'>
									<div
										className='md:w-[82px] w-[68px] md:h-14 h-[45px] flex items-center justify-center bg-white rounded font-medium text-[22px]'
										style={{ border: "1px solid #ADB5B9" }}>
										{fetchedData?.number_of_sold_product}
									</div>
									<div className='flex-1 '>
										<h2 className='font-medium md:text-[22px] text-[18px]'>
											مرات بيع المنتج
										</h2>
									</div>
								</div>
								<div className='flex items-center gap-8 mb-4 md:gap-24'>
									<div
										className='md:w-[82px] w-[68px] md:h-14 h-[45px] flex items-center justify-center bg-white rounded font-medium text-[22px]'
										style={{ border: "1px solid #ADB5B9" }}>
										{fetchedData?.total}
									</div>
									<div className='flex-1 '>
										<h2 className='font-medium md:text-[22px] text-[18px]'>
											اجمالي مبيعات المنتج
										</h2>
									</div>
								</div>
							</div>
							<Button
								onClick={closeDetails}
								type={"normal"}
								className={
									"text-center cancel_button text-xl md:h-14 h-[45px] md:w-[474px] w-full rounded md:mt-14 mt-8"
								}>
								اغلاق
							</Button>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default ProductDetails;
