import React from "react";

import FormControl from "@mui/material/FormControl";
import { AiOutlineSearch } from "react-icons/ai";

const SupportSearchForm = ({ search, setSearch }) => {
	return (
		<div className='flex flex-col justify-between xl:flex-row'>
			<div>
				<h2
					className={
						"font-bold md:text-2xl text-[18px] whitespace-nowrap md:mb-5 mb-3"
					}
					style={{ color: "#1948a0" }}>
					جدول الشكاوي والاستفسارات
				</h2>
			</div>
			<div>
				<h3
					className={"md:text-lg text-[16px] font-medium mb-2"}
					style={{ color: "#1948a0" }}>
					البحث بـ عنوان الشكوى
				</h3>
				<div className='w-full mb-4'>
					<FormControl
						className='flex flex-row w-full gap-4'
						sx={{ minWidth: 120, flex: "1" }}>
						<label className={`flex-1  w-full h-14 relative `}>
							<input
								className='bg-[#ECFEFF00] h-14 w-[474px] outline-0 pr-12 rounded-lg '
								placeholder=' ادخل عنوان الشكوى'
								type='text'
								name='name'
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								style={{
									border: "1px solid #DCE6F9",
									maxWidth: "100%",
								}}
							/>
							<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
								<AiOutlineSearch color='#b6b1c4' size={"20px"} />
							</div>
						</label>
					</FormControl>
				</div>
			</div>
		</div>
	);
};

export default SupportSearchForm;
