import React, { useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import moment from "moment-with-locales-es6";
/* Contexts */
import Context from "../../../../store/context";
import { NotificationContext } from "../../../../store/NotificationProvider";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* MUI */
import Checkbox from "@mui/material/Checkbox";
/* Icons */
import {
	CheckedSquare,
	CommunicationSendOutlined,
	Delete,
	ShowStoreRequest,
} from "../../../../assets/Icons/index";
import {
	useEmailIndexDeleteAllByParamsMutation,
	useEmailIndexDeleteItemMutation,
} from "../../../../RTK/apiSlices/emailIndexApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";

const EmailSettingPage = ({
	openTraderAlert,
	setShowEmailInfo,
	fetchedData,
	loading,
}) => {
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const [selected, setSelected] = React.useState([]);
	const isSelected = (id) => selected.indexOf(id) !== -1;
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle, setNotificationTitle, setActionType, setItems } =
		NotificationStore;

	// formatDate
	const formatDate = (date) => {
		const calcPassedMinutes = (date1, date2) =>
			Math.round(Math.abs(date2 - date1) / (1000 * 60));

		const currentMinutes = calcPassedMinutes(+new Date(), +new Date(date));

		if (currentMinutes < 1) {
			return "الآن";
		} else if (currentMinutes === 1) {
			return "منذ دقيقة";
		} else if (currentMinutes === 2) {
			return "منذ دقيقتين";
		} else if (currentMinutes <= 10) {
			return `منذ ${currentMinutes} دقائق`;
		} else if (currentMinutes < 60 && currentMinutes >= 11) {
			return `منذ ${currentMinutes} دقيقة`;
		} else if (currentMinutes === 60) {
			return "منذ ساعة";
		} else if (currentMinutes === 120) {
			return "منذ ساعتين";
		} else if (currentMinutes < 1440) {
			let hours = Math.floor(currentMinutes / 60);
			let min = currentMinutes % 60;
			if (hours === 1) {
				return `منذ ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else if (hours === 2) {
				return `منذ  و ساعتين ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else if (hours <= 10) {
				return `منذ ${hours} ساعات و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else {
				return `منذ ${hours} ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			}
		}

		const currentDate = Math.round(currentMinutes / 60 / 24);

		if (currentDate === 1) {
			return "أمس، الساعة " + moment(date).locale("ar").format(" h:mm a");
		} else if (currentDate === 2) {
			return " منذ يومين، الساعة" + moment(date).locale("ar").format(" h:mm a");
		} else if (currentDate <= 7) {
			return (
				`منذ ${currentDate}  أيام، الساعة` +
				moment(date).locale("ar").format(" h:mm a")
			);
		}

		return moment(date).locale("ar").format("D MMMM YYYY");
	};
	// -----------------------------------------------------------------

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.emails?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [emailIndexDeleteItem] = useEmailIndexDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await emailIndexDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const [emailIndexDeleteAllByParams] =
		useEmailIndexDeleteAllByParamsMutation();

	const emailIndexDeleteAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await emailIndexDeleteAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | البريد والرسائل</title>
			</Helmet>
			<div
				className={`relative xl:pb-10 2xl:pl-36 2xl:pr-24 p-4`}
				style={{ backgroundColor: "#F7F7F7" }}>
				<h3
					style={{ color: "#011723" }}
					className='md:text-[24px] text-[20px] font-bold'>
					البريد والرسائل
				</h3>
				<div className='mt-8'>
					{fetchedData?.emails?.length > 0 && (
						<div className='flex flex-row items-center gap-[53px]'>
							<div className='flex flex-row items-center gap-3'>
								<Checkbox
									checkedIcon={<CheckedSquare />}
									sx={{
										pr: "0",
										color: "#1948a0",
										"& .MuiSvgIcon-root": {
											color: "#1948a0",
										},
									}}
									indeterminate={
										selected.length > 0 &&
										selected.length < fetchedData?.emails.length
									}
									checked={
										fetchedData?.emails.length > 0 &&
										selected.length === fetchedData?.emails.length
									}
									onChange={handleSelectAllClick}
									inputProps={{
										"aria-label": "select all desserts",
									}}
								/>
								<label
									className='md:text-[18px] text-[16px]'
									style={{ color: "#011723" }}
									htmlFor='all'>
									تحديد الكل
								</label>
							</div>

							<div className='flex flex-row items-center justify-center gap-2'>
								{permissions?.includes("admin.email.deleteEmailAll") &&
									selected.length > 0 && (
										<div
											className='flex flex-row items-center justify-center  cursor-pointer'
											style={{
												width: "130px",
												height: "40px",
												backgroundColor: "#FF38381A",
												borderRadius: "20px",
											}}
											onClick={() => {
												setNotificationTitle(
													"سيتم حذف جميع البريد التي قمت بتحديدها"
												);
												setItems(selected);
												setActionType("deleteAll");
											}}>
											<Delete />
											<h6
												className='md:text-[18px] text-[16px]  text-[#1948a0] font-medium'
												style={{ color: "#FF3838" }}>
												حذف الكل
											</h6>
										</div>
									)}
							</div>
						</div>
					)}
					<div className='flex flex-col flex-wrap gap-4 mt-4'>
						{loading ? (
							<CircularLoading />
						) : fetchedData?.emails?.length > 0 ? (
							fetchedData?.emails?.map((box, index) => {
								const isItemSelected = isSelected(box?.id);

								const timestamp = box?.created_at;
								const date = new Date(timestamp);
								const formattedTime = date.toLocaleString("ar", {
									hour: "numeric",
									minute: "numeric",
									hour12: true,
								});

								const day = date.toISOString().slice(0, 10);
								return (
									<div
										key={box?.id}
										style={{ boxShadow: "3px 3px 6px #00000005" }}
										className='flex flex-col items-start justify-between w-full gap-2 px-4 py-2 bg-white md:flex-row md:items-center'>
										<div className='flex flex-row items-center w-full gap-4 md:gap-8'>
											<Checkbox
												checkedIcon={<CheckedSquare />}
												sx={{
													color: "#4875c2",
													"& .MuiSvgIcon-root": {
														color: "#ADB5B9",
													},
												}}
												checked={isItemSelected}
												onClick={(event) => handleClick(event, box?.id)}
											/>
											<div className='flex flex-row items-center justify-between w-full md:pl-20'>
												<div className='flex flex-col gap-1'>
													<h2
														style={{ color: "#011723" }}
														className='md:text-[20px] text-[18px] font-medium whitespace-nowrap'>
														{box?.subject}
													</h2>
													<p
														className='md:text-[18px] text-[16px]'
														style={{ color: "#011723" }}>
														{box?.store?.store_name}
													</p>
												</div>
												<div className='hidden md:flex'>
													<p
														style={{ color: "#A7A7A7" }}
														className='md:text-[16px] text-[14px] font-light'>
														{formatDate(box?.created_at)}
													</p>
												</div>
											</div>
										</div>
										<div className='flex flex-row items-center justify-between w-full md:w-auto'>
											<div className='flex flex-row items-center gap-[10px] md:mr-0 mr-[1rem]'>
												{permissions?.includes("admin.email.store") && (
													<CommunicationSendOutlined
														className='cursor-pointer'
														onClick={() => {
															openTraderAlert(box);
															setShowEmailInfo(false);
														}}
													/>
												)}
												{permissions?.includes("admin.email.show") && (
													<ShowStoreRequest
														className='cursor-pointer'
														title='عرض الطلب'
														onClick={() => {
															openTraderAlert(box);
															setShowEmailInfo(true);
														}}
													/>
												)}
												{permissions?.includes(
													"admin.email.deleteEmailAll"
												) && (
													<Delete
														className='cursor-pointer'
														onClick={() => {
															setActionDelete(
																"سيتم حذف البريد وهذة الخطوة غير قابلة للرجوع"
															);
															setItemId(box?.id);
														}}
													/>
												)}
											</div>
											<div className='flex md:hidden'>
												<p
													style={{ color: "#A7A7A7" }}
													className='md:text-[16px] text-[14px] font-light'>
													{formatDate(box?.created_at)}
												</p>
											</div>
										</div>
									</div>
								);
							})
						) : (
							<div className='flex flex-col items-center justify-center text-lg'>
								لا توجد رسائل
							</div>
						)}
					</div>
				</div>
			</div>

			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleDeleteAllItems={emailIndexDeleteAllByParamsHandel}
				/>
			)}
		</>
	);
};

export default EmailSettingPage;
