import React, { useState, useContext, useEffect } from "react";
import styles from "./AddUnit.module.css";
/* Context */
import Context from "../../../../../store/context";
/* Custom Button */
import Button from "../../../../../UI/Button/Button";
/* MUI */
import Box from "@mui/material/Box";
/* Icons */
import { GrAttachment } from "react-icons/gr";

/* Third party */
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css";
import { AddIcon } from "../../../../../assets/Icons";
require("prismjs/components/prism-jsx");

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const formTitleClasses =
	"md:w-[315px] w-full md:text-[18px] text-[16px]  text-[#1948a0]font-normal md:mb-0 mb-2";

const formInputClasses =
	"md:w-[555px] w-full md:h-14 h-[45px] p-4 outline-0 rounded-md";
const formInputStyle = {
	border: "1px solid #A8C5F0",
	borderRadius: "8px",
	backgroundColor: "#EDF3FC",
};

const AddUnit = ({ cancel, unitDetails }) => {
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const [unitTitleError, setUnitTitleError] = useState("");
	const [fields, setFields] = useState([{ id: 1, value: "" }]);

	const handleFieldValueChange = (id, value) => {
		const updatedFields = fields?.map((field) => {
			if (field.id === id) {
				return { ...field, value };
			}
			return field;
		});
		setFields(updatedFields);
	};

	const handleAddField = () => {
		const newField = { id: Date.now(), value: "" };
		setFields([...fields, newField]);
	};

	const [fileTypeError, setFileTypeError] = useState("");
	const [vedioTypeError, setVedioTypeError] = useState({});

	const [unit, setUnit] = useState({
		title: "",
		documents: [],
		videos: [],
	});
	useEffect(() => {
		setUnit({ ...unit, videos: fields?.map((input) => input?.value) });
	}, [fields]);

	return (
		<>
			<BackDrop onClick={cancel}></BackDrop>
			<div
				className={`fixed bottom-0 left-0 bg-[#EEF4FC] z-30 otlobha_new_product ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<div className='flex flex-col justify-between h-full'>
					<div
						className='p-4 pt-24 md:p-8 md:pt-8'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<h2 className='font-bold md:text-2xl text-[20px]  mb-3'>
							اضافة درس
						</h2>
						<h2 className='md:text-xl text-[16px] font-normal'>
							اضف درس جديد للدورة
						</h2>
					</div>
					<div
						className={`flex-1 overflow-y-scroll md:py-12 md:pr-8 p-4 ${styles.content}`}>
						<div className='flex flex-col mb-8 md:flex-row'>
							<h2 className={formTitleClasses}>
								عنوان الدرس<span className='text-red-500'>*</span>
							</h2>
							<label>
								<input
									value={unit?.title}
									onChange={(e) => setUnit({ ...unit, title: e.target.value })}
									className={formInputClasses}
									style={formInputStyle}
									placeholder='عنوان الدرس'
									type='text'
									name='name'
								/>
								{unitTitleError && (
									<div className='text-[red] text-[16px]'>{unitTitleError}</div>
								)}
							</label>
						</div>
						<div className='flex flex-col mb-8 md:flex-row'>
							<h2 className={formTitleClasses}>ملفات مرفقة</h2>
							<label
								htmlFor='files'
								className='md:w-[555px] w-full md:h-14 h-[45px] flex p-4 items-center rounded-lg'
								style={{ border: "1px solid #BACEF3", cursor: "pointer" }}>
								<input
									id='files'
									hidden
									multiple
									onChange={(e) => {
										setFileTypeError("");
										if (
											e?.target?.files?.[0]?.type === "application/pdf" ||
											e?.target?.files?.[0]?.type === "application/doc" ||
											e?.target?.files?.[0]?.type === "application/excel"
										) {
											setUnit({ ...unit, documents: e.target.files });
											setFileTypeError("");
										} else {
											setUnit({ ...unit, documents: [] });
											setFileTypeError(
												"يجب ان  تكون صيغة الملف من نوع  (PDF أو DOC أو EXCEL)"
											);
										}
									}}
									className={`flex-1 rounded-lg ${styles.file_select}`}
									type='file'
									placeholder='asdasdasd'
									accept='.pdf,.doc,.excel'
								/>
								{unit?.documents?.length > 0 ? (
									<span
										className={`flex-1 rounded-lg text-gray-400 ${styles.file_select}`}>
										{unit?.documents?.[0]?.name}
										{unit?.documents?.[1] &&
											`, ${unit?.documents?.[1]?.name} ...`}
									</span>
								) : fileTypeError ? (
									<span className='flex-1 text-red-500 text-[16px]'>
										{fileTypeError}
									</span>
								) : (
									<span
										className={`flex-1 rounded-lg text-gray-400 ${styles.file_select}`}>
										ملفات الوحده يجب ان تكون PDF أو DOC أو EXCEL
									</span>
								)}
								<div>
									<GrAttachment></GrAttachment>
								</div>
							</label>
						</div>
						<div className='flex flex-col mb-8 md:flex-row'>
							<h2 className={formTitleClasses}> اضافة فيديو الدرس</h2>
							<div>
								{fields?.map((field) => (
									<>
										<div key={field.id} className='flex flex-col mb-5'>
											<div className='md:w-[555px] w-full flex gap-5'>
												<div className={styles.add_video}>
													<Editor
														placeholder='قم بنسخ رابط الامبدد الخاص بالفيديو من اليوتيوب'
														value={field.value}
														onValueChange={(value) =>
															handleFieldValueChange(field.id, value)
														}
														highlight={(value) =>
															highlight(value, languages.jsx)
														}
														padding={10}
														style={{
															fontFamily: '"Fira code", "Fira Mono", monospace',
															fontSize: 16,
															direction: `${
																field.value === "" ? "rtl" : "ltr"
															}`,
														}}
													/>
												</div>
											</div>
											{vedioTypeError?.[field?.id] && (
												<span className='flex-1 text-red-500 text-[16px]'>
													{vedioTypeError?.[field?.id]}
												</span>
											)}
										</div>
									</>
								))}
								<div className='md:w-[555px] w-full md:h-14 h-[45px] flex gap-5 mb-5'>
									<div
										className='w-full md:h-14 h-[45px]'
										onClick={handleAddField}>
										<div
											className='w-full md:h-14 h-[45px] fcc p-3 gap-4  cursor-pointer rounded-lg'
											style={{
												backgroundColor: "#EEF3FC",
												border: "2px dashed #A7A7A7",
											}}>
											<Box
												sx={{
													"& svg": {
														width: "24px",
													},
													"& circle": { fill: "#ADB5B9" },
												}}>
												<AddIcon></AddIcon>
											</Box>
											<h2
												className='md:text-[18px] text-[16px]'
												style={{ color: "#ADB5B9" }}>
												اضف درس جديد للدورة
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className='md:h-[135px] h-[100px] md:p-8 p-4 flex items-center justify-center gap-4'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<Button
							className={
								"md:h-14 h-[45px] md:w-44 w-full md:text-xl text-[16px] font-medium"
							}
							style={{ backgroundColor: `rgba(2, 70, 106, 1)` }}
							type={"normal"}
							onClick={() => {
								if (unit?.title) {
									unitDetails(unit);
									setEndActionTitle("تم اضافة درس جديد بنجاح");
									cancel();
								} else {
									setUnitTitleError("حقل عنوان الدرس مطلوب");
								}
							}}
							btnType={"button"}
							disabled={unit?.title === ""}>
							حفظ
						</Button>
						<Button
							style={{
								borderColor: `rgba(2, 70, 106, 1)`,
							}}
							textStyle={{ color: "rgba(2, 70, 106, 1)" }}
							className={
								"md:h-14 h-[45px] md:w-44 w-full md:text-xl text-[16px] font-medium"
							}
							type={"outline"}
							onClick={cancel}
							btnType={"button"}>
							إلغاء
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddUnit;
