import React from "react";
import { useNavigate } from "react-router-dom";

/* Custom Button */
import Button from "../../../UI/Button/Button";

// Components
import PageNavigate from "../../PageNavigate/PageNavigate";

/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { useSelectPackagesPeriodTypesQuery } from "../../../RTK/apiSlices/selectorApi";
import { MenuItem, Select } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";

const PackagesHead = ({ permissions, periodType, setPeriodType }) => {
	const navigate = useNavigate();
	const { data: packagesPeriodList } = useSelectPackagesPeriodTypesQuery();

	return (
		<>
			<div className='mb-5'>
				<PageNavigate currentPage={"الباقات"} />
			</div>

			<div className='flex flex-col-reverse gap-3 md:flex-row align-center justify-between mb-12'>
				<Select
					displayEmpty
					value={periodType}
					required
					name='periodType'
					onChange={(e) => {
						setPeriodType(e.target.value);
					}}
					IconComponent={() => {
						return <IoIosArrowDown size={"1rem"} />;
					}}
					renderValue={(selected) => {
						if (!selected) {
							return <h2 className='text-[#1948a0]'>فرز حسب مدة الإشتراك</h2>;
						}
						const result = packagesPeriodList?.filter(
							(item) => item?.name === selected
						);
						return result[0]?.name_ar;
					}}
					sx={{
						"& .MuiOutlinedInput-notchedOutline": {
							border: "none",
						},
						"& .MuiOutlinedInput-input": {
							paddingRight: "10px !important",
						},
					}}
					className=' w-full md:w-1/2 bg-[#FFFF] text-lg text-[#1948a0] border-[1px] border-[#A8C1F0] md:h-[56px] h-[45px] px-4 py-2 outline-none  rounded-md'>
					{packagesPeriodList?.map((item, index) => {
						return (
							<MenuItem
								key={index}
								className='w-full bg-[#FFF] hover:bg-[#EFF9FF] h-12'
								value={item?.name}>
								{item?.name_ar}
							</MenuItem>
						);
					})}
				</Select>

				{permissions?.includes("admin.package.store") && (
					<Button
						className='md:w-[265px] save_button w-full md:h-[56px] h-[45px] text-xl'
						svg={<AiOutlinePlus color='#fff' className='w-5 h-5' />}
						type={"normal"}
						onClick={() => {
							navigate("/packages/add-package");
						}}>
						اضافة باقة جديدة
					</Button>
				)}
			</div>
		</>
	);
};

export default PackagesHead;
