import React, { useContext } from "react";
import ReactDom from "react-dom";
/* Context */
import { NotificationContext } from "../../store/NotificationProvider";
/* Custom Button */
import Button from "../../UI/Button/Button";
import { Warning } from "../../assets/Icons";
/* Icons */

const BackDrop = () => {
	return (
		<div
			className='fixed bottom-0 left-0 z-50 w-full back_drop opacity-70'
			style={{
				height: "100vh",
				backgroundColor: "#242424",
			}}></div>
	);
};
const DeleteModal = ({
	handleDeleteAllItems,
	handleChangeAllItemsStatus,
	handleChangeAllItemsSpecific,
}) => {
	const NotificationProvider = useContext(NotificationContext);

	const {
		notificationTitle,
		setNotificationTitle,
		setItems,
		items,
		actionType,
		setActionType,
	} = NotificationProvider;

	const handleClose = () => {
		setNotificationTitle(null);
		setActionType(null);
		setItems(null);
	};

	const confirmDeleteAll = () => {
		handleDeleteAllItems(items);
		handleClose();
	};

	const confirmChangeStatusAll = () => {
		handleChangeAllItemsStatus(items);
		handleClose();
	};
	const confirmChangeSpecificAll = () => {
		handleChangeAllItemsSpecific(items);
		handleClose();
	};

	return (
		<>
			<BackDrop />
			<div
				className='fixed shadow-lg p[40px] fcc back_drop -translate-x-1/2 rounded-lg left-1/2 bg-white z-50 md:h-[331px] h-[250px]'
				style={{
					width: "572px",
					maxWidth: "90%",
					top: "100px",
					boxShadow: "0px 3px 6px #00000029",
				}}>
				<div className='flex flex-col items-center justify-center'>
					<div className='flex flex-row items-center gap-4'>
						<h6
							className='text-[22px] md:text-[38px]'
							style={{ fontWeight: "500", color: "#FF3838" }}>
							تنبيه
						</h6>
						<Warning fill='#FF3838' className='w-[20px] md:w-[38px]' />
					</div>
					<p
						className='mt-[21px] text-[18px] md:text-[20px]'
						style={{ fontWeight: "700", color: "#011723" }}>
						هل أنت متأكد !
					</p>
					<h6
						style={{
							fontSize: "20px",
							fontWeight: "500",
							color: "#0077FF",
							textAlign: "center",
						}}>
						{notificationTitle}
					</h6>
					<div className='flex flex-row items-center gap-4 md:mt-[64px] mt-4'>
						<Button
							type={"normal"}
							textStyle={{ color: "#fff", fontSize: "20px" }}
							className={
								"md:w-[156px] w-[135px] save_button md:h-[56px] h-[45px] rounded-lg px-4"
							}
							onClick={() => {
								actionType === "deleteAll"
									? confirmDeleteAll()
									: actionType === "ChangeStatus"
									? confirmChangeStatusAll()
									: confirmChangeSpecificAll();
							}}>
							تأكيد
						</Button>
						<Button
							type={"outline"}
							style={{
								backgroundColor: "#EEF3FC",
								border: "1px solid #1948a0",
							}}
							textStyle={{ color: "#1948a0", fontSize: "20px" }}
							className={
								"md:w-[156px] w-[135px] md:h-[56px] h-[45px] rounded-lg px-4"
							}
							onClick={() => {
								handleClose();
							}}>
							الغاء
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

const DeleteModalComp = ({
	title,
	cancelEarly,
	handleDeleteAllItems,
	handleChangeAllItemsStatus,
	handleChangeAllItemsSpecific,
}) => {
	return (
		<>
			{ReactDom.createPortal(
				<DeleteModal
					title={title}
					cancelEarly={cancelEarly}
					handleDeleteAllItems={handleDeleteAllItems}
					handleChangeAllItemsStatus={handleChangeAllItemsStatus}
					handleChangeAllItemsSpecific={handleChangeAllItemsSpecific}
				/>,
				document.getElementById("action_div")
			)}
		</>
	);
};

export default DeleteModalComp;
