import React from "react";
/* Icons */
import { Store } from "../../../../assets/Icons/index";

const TotalMarkets = ({ marketsInfo }) => {
	return (
		<div className='flex flex-row flex-wrap gap-4 xl:flex-col'>
			<div
				className='flex-1 w-64 rounded-lg flex md:flex-row flex-col items-center p-4 min-w-[250px]'
				style={{ backgroundColor: "#1948a0" }}>
				<div className='w-16 ml-2'>
					<div
						className='flex items-center justify-center w-12 h-12 mx-auto rounded-lg md:mx-0'
						style={{ backgroundColor: "#CBDEF6" }}>
						<Store className={`brightness-[200]`} />
					</div>
				</div>
				<div className='flex flex-col justify-between flex-1 h-full pt-4'>
					<h2 className='text-lg font-medium text-[#FFFFFF]'>اجمالي المتاجر</h2>
					<div className='flex flex-col items-center md:items-start'>
						<h2 className='text-[#FFFFFF] font-semibold md:text-[18px] text-[20px]'>
							{marketsInfo?.countStore || 0}
						</h2>
						<h6 className='mt-3 text-xs text-center text-[#F2F2F2]'>
							% {marketsInfo?.averageStore || 0} + خلال هذا الشهر
						</h6>
					</div>
				</div>
			</div>
			<div
				className='flex-1 w-64 rounded-lg flex flex-row items-center md:gap-14 gap-4 p-4 min-w-[250px]'
				style={{ backgroundColor: "#CBDEF6" }}>
				<div className='flex flex-col justify-between flex-1 h-full'>
					<div className='flex-1 flex flex-row md:items-center items-baseline md:gap-14 gap-4 text-[#1948a0]'>
						<div className='w-4 h-4 bg-[#1948a0] rounded-full'></div>
						<div className='flex flex-col'>
							<h3 className=' font-semibold md:text-[18px] text-[20px]'>
								{marketsInfo?.activeStore || 0}
							</h3>
							<h6 className='mt-1 text-sm font-medium '>متاجر فعالة</h6>
						</div>
					</div>
					<div className='flex-1 flex flex-row md:items-center items-baseline md:gap-14 gap-4 text-[#1948a0]'>
						<div className='w-4 h-4 rounded-full bg-[rgb(220, 232, 249)]'></div>
						<div className='flex flex-col'>
							<h3 className=' font-semibold md:text-[18px] text-[20px]'>
								{marketsInfo?.notActiveStore || 0}
							</h3>
							<h6 className='mt-1 text-sm font-medium '>متاجر غير فعالة</h6>
						</div>
					</div>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default TotalMarkets;
