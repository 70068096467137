import React, { useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import ChangeSliderSec from "../../../components/TemplatePageComps/SliderPage/ChangeSliderSec/ChangeSliderSec";
import { useGetHomePageDataQuery } from "../../../RTK/apiSlices/templateApi";

const TemplatePage = () => {
	const { data: fetchedData, isLoading: loading } = useGetHomePageDataQuery();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	if (!permissions?.includes("admin.homepage.index")) return null;
	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | السلايدر</title>
			</Helmet>
			<div
				className={`px-4 md:pt-4 pt-0 pb-10`}
				style={{ backgroundColor: "#EEF3FC" }}>
				<div
					className='md:pr-5 py-3 px-2  font-normal md:text-lg text-[14px] bg-[#FDE2D8] rounded-md mb-2'
					style={{ color: "#f75e22" }}>
					هذه الواجهة خاصة بإعدادات الصفحة الرئيسية للموقع الإلكتروني
				</div>
				<div className='mt-4 '>
					<PageNavigate
						className='text-lg font-normal'
						parentPage={"القالب"}
						route={"template_page"}
						currentPage={"السلايدر"}
					/>
				</div>
				<div>
					<ChangeSliderSec
						fetchedData={fetchedData}
						sliderUpdatePermission={permissions?.includes(
							"admin.homepage.sliderUpdate"
						)}
						loading={loading}
					/>
				</div>
			</div>
		</>
	);
};

export default TemplatePage;
