import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./TableComp.module.css";

/* Contexts */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import { FaCheck } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { CgSandClock } from "react-icons/cg";
import { SortIcon, NewIcon, Delete, InfoIcon } from "../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import NewPagination from "../../PaginationComp/NewPagination";

import { toast } from "react-toastify";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import { useDomainOrdersDeleteItemMutation } from "../../../RTK/apiSlices/domainOrdersApi";

const stateChanges = [
	{ value: "منتهية", color: "#3AE374", icon: <FaCheck fill='#fff' /> },
	{
		value: "غير منتهية",
		color: "#D3D3D3",
		icon: <BsClockHistory fill='#fff' />,
	},
	{
		value: "قيد المعالجة",
		color: "#FF9F1A",
		icon: <CgSandClock fill='#fff' />,
	},
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		sortLabel: "status",
	},

	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "نوع الطلب",
		sort: true,
		sortLabel: "type",
	},

	{
		id: "serversName",
		numeric: true,
		disablePadding: false,
		label: "اسم الطلب",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "كود الطلب",
		sort: true,
		sortLabel: "order_number",
	},
	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#feb17e" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						className='font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0]'
						key={headCell.id}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#FFF",
							whiteSpace: "nowrap",
						}}>
						{headCell?.sort && (
							<TableSortLabel
								sx={{ "& path": { fill: "#FFF" } }}
								IconComponent={() => {
									return <SortIcon fill='#FFF' />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;
	return (
		<Toolbar
			className='gap-4 md:gap-8'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				justifyContent: "flex-end",
			}}></Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function DomainOrdersTable({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	search,
}) {
	const navigate = useNavigate();
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [domainOrdersDeleteItem] = useDomainOrdersDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await domainOrdersDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData?.length) : 0;
	return (
		<Box sx={{ width: "100%", mb: 2, pb: 2 }}>
			<Paper
				sx={{
					width: "100%",
					mb: "1rem",
					backgroundColor: "transparent",
					boxShadow: "none",
				}}>
				<EnhancedTableToolbar
					numSelected={selected.length || 0}
					rowCount={fetchedData?.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>

				<TableContainer sx={{ backgroundColor: "#fff" }}>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={fetchedData?.length || 0}
						/>

						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{fetchedData?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(fetchedData, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row?.id);
												const labelId = `enhanced-table-checkbox-${index}`;
												const findStateChanges = stateChanges.find(
													(i) => row?.status === i.value
												);
												const newMarket = row?.type === "متجر جديد";
												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}
														className={
															row?.services?.some(
																(serive) =>
																	serive?.description === "" &&
																	serive?.price === "0"
															)
																? "bg-gray-100"
																: ""
														}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center gap-2'>
																{permissions?.includes(
																	"admin.WebsiteDomainOrder.deleteall"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف الطلب وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row?.id);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.WebsiteDomainOrder.show"
																) && (
																	<InfoIcon
																		title='تفاصيل'
																		onClick={() => {
																			navigate(
																				`/domain-orders/show-details/${row?.id}`
																			);
																		}}
																		style={{
																			cursor: "pointer",
																			fontSize: "1.2rem",
																		}}
																	/>
																)}
															</div>
														</TableCell>

														<TableCell
															className='pr-0'
															align='right'
															sx={{ "& path": { fill: "#fff" } }}>
															<div
																className={
																	"flex ml-auto gap-1 items-center justify-center rounded-full p-2"
																}
																style={{
																	backgroundColor: findStateChanges?.color,
																	width: "131px",
																}}>
																<h2 className='font-normal md:text-[16px] text-[14px] whitespace-nowrap text-slate-50'>
																	{row?.status}
																</h2>
																<div
																	className={`w-5 h-5 flex items-center justify-center ${styles.icons}`}>
																	{findStateChanges?.icon}
																</div>
															</div>
														</TableCell>

														<TableCell
															align='right'
															className='items-center'
															sx={{
																display: "flex",
																gap: "0.5rem",
																p: "24px 0",
															}}>
															{newMarket && <NewIcon width='1.25rem' />}

															<h2 className='font-normal md:text-[18px] text-[14px] whitespace-nowrap'>
																{row?.type}
															</h2>
														</TableCell>

														<TableCell align='right'>
															<h2 className='font-normal md:text-[18px] text-[14px] whitespace-nowrap'>
																{row?.services?.map((service) => service?.name)}
															</h2>
														</TableCell>

														<TableCell align='right'>
															<h2 className='font-normal md:text-[18px] text-[14px] whitespace-nowrap'>
																{row?.order_number}
															</h2>
														</TableCell>

														<TableCell
															align='right'
															className='font-normal md:text-[18px] text-[14px] whitespace-nowrap'>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{search ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
		</Box>
	);
}
