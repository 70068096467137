import React, { useEffect, useContext } from "react";
import styles from "./ContactUsDetails.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
/* Custom Hook */

/* Context */
import { TextEditorContext } from "../../../store/TextEditorProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import { TextEditor } from "../../../components/TextEditor";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { Email, User, Call, StatusIcon } from "../../../assets/Icons";
import { useGetAtlobhaContactDataQuery } from "../../../RTK/apiSlices/contactUsApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const stateChanges = [
	{
		value: "منتهية",
		BgColor: "#3AE374",
		color: "#ffffff",
	},
	{
		value: "غير منتهية",
		BgColor: "#D3D3D3",
		color: "#ffffff",
	},
];

const ContactUsDetails = ({ cancel, contactUsDetails }) => {
	const { data: fetchedData, isLoading: loading } =
		useGetAtlobhaContactDataQuery({ contactUsDetails });

	const editorContent = useContext(TextEditorContext);
	const { setEditorValue } = editorContent;

	const findStateChanges = stateChanges.find(
		(i) => fetchedData?.atlobhaContacts?.status === i.value
	);

	useEffect(() => {
		setEditorValue(fetchedData?.atlobhaContacts?.content || "");
	}, [fetchedData?.atlobhaContacts?.content]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | تفاصيل تواصل معنا</title>
			</Helmet>
			<BackDrop onClick={cancel}></BackDrop>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
				}}>
				<div className='flex flex-col justify-between h-full'>
					<div
						className='  md:px-[70px] px-4 md:py-4 pt-24 pb-3'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<h2
							style={{ color: "#011723" }}
							className='md:text-[24px] text-[20px] font-bold mb-3'>
							تفاصيل تواصل معنا
						</h2>
						<div className='flex'>
							<div className={`flex items-center gap-2 `}>
								<div
									onClick={cancel}
									className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
									<GoArrowRight
										style={{ color: "#1948a0", fontSize: "1.2rem" }}
									/>
								</div>

								<Link
									to={"/"}
									className='md:text-[18px] text-[16px]  text-[#1948a0]ml-4'
									style={{ color: "#011723", whiteSpace: "nowrap" }}>
									الرئيسية
								</Link>
							</div>

							<h2
								onClick={cancel}
								className='md:text-[18px] text-[16px]  text-[#1948a0]ml-4 cursor-pointer'
								style={{ color: "#011723", whiteSpace: "nowrap" }}>
								/ تواصل معنا
							</h2>

							<h3
								className='md:text-[18px] text-[16px]'
								style={{ color: "#7C7C7C", whiteSpace: "nowrap" }}>
								/ التفاصيل
							</h3>
						</div>
					</div>
					<div
						className={`flex-1 flex flex-col md:items-start items-center overflow-y-scroll lg:pt-[30px] lg:pb-[10px] lg:pr-[70px] lg:pl-[155px] p-4 ${styles.content}`}
						style={{ backgroundColor: "#F6F6F6" }}>
						{loading ? (
							<div className='flex flex-col items-center w-full'>
								<CircularLoading />
							</div>
						) : (
							<div className='w-full'>
								<div
									className={
										"lg:w-[752px] w-full mt-[20px] lg:gap-12 gap-4 p-[20px] pr-[22px] flex md:flex-row flex-col justify-between rounded-lg"
									}
									style={{
										maxWidth: "100%",
										backgroundColor: "#fff",
										boxShadow: "0px 3px 6px #0000000F",
									}}>
									<div className='flex flex-col flex-1 gap-5'>
										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ minWidth: "160px" }}>
												<User className={styles.icons}></User>
												<h2
													style={{
														fontSize: "18px",
														color: "#1948a0",
														whiteSpace: "nowrap",
													}}>
													الاسم
												</h2>
											</div>
											<div
												className={
													"w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
													height: "70px",
													width: "180px",
												}}>
												<h2 style={{ fontSize: "18px", color: "#0077FF" }}>
													{fetchedData?.atlobhaContacts?.name}
												</h2>
											</div>
										</div>
										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ minWidth: "160px" }}>
												<Email className={styles.icons}></Email>
												<h2
													style={{
														fontSize: "18px",
														color: "#1948a0",
														whiteSpace: "nowrap",
													}}>
													البريد الالكتروني
												</h2>
											</div>
											<div
												className={
													"w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2
													className='flex flex-row gap-[14px]'
													style={{ fontSize: "18px", color: "#0077FF" }}>
													{fetchedData?.atlobhaContacts?.email}
												</h2>
											</div>
										</div>

										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ minWidth: "160px" }}>
												<Call className={styles.icons}></Call>
												<h2
													style={{
														fontSize: "18px",
														color: "#1948a0",
														whiteSpace: "nowrap",
													}}>
													عنوان الرسالة
												</h2>
											</div>
											<div
												className={
													"w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: "#EFF9FF",
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2 style={{ fontSize: "18px", color: "#0077FF" }}>
													{fetchedData?.atlobhaContacts?.title}
												</h2>
											</div>
										</div>

										<div className='flex flex-col items-start gap-3 md:flex-row md:items-center md:gap-4'>
											<div
												className='flex gap-[10px]'
												style={{ minWidth: "160px" }}>
												<StatusIcon className={styles.icons}></StatusIcon>
												<h2
													style={{
														fontSize: "18px",
														color: "#1948a0",
														whiteSpace: "nowrap",
													}}>
													الحالة
												</h2>
											</div>
											<div
												className={
													"w-full md:h-[70px] h-[45px] flex items-center justify-center rounded-lg"
												}
												style={{
													backgroundColor: findStateChanges?.BgColor,
													boxShadow: "0px 3px 6px #0000000F",
												}}>
												<h2
													style={{
														fontSize: "18px",
														color: findStateChanges?.color,
													}}>
													{findStateChanges?.value}
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div className='mt-10 flex flex-col gap-[10px] max-w-full'>
									<h2 style={{ fontSize: "18px", color: "#011723" }}>
										محتوى الرسالة
									</h2>
									<div className={`${styles.editor} lg:w-[752px] w-full`}>
										<TextEditor
											ToolBar={"Details"}
											placeholder={"تفاصيل الرسالة"}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className='p-4 flex justify-center items-center gap-4  bg-[rgb(220, 232, 249)]'>
						<Button
							className={
								"md:h-[55px] h-[45px] md:w-1/3 w-11/12  rounded-lg px-8 py-3"
							}
							style={{
								backgroundColor: "#EEF3FC",
								border: "1px solid #1948a0",
							}}
							type={"outline"}
							onClick={cancel}>
							<h2
								className='md:text-[24px] text-[20px]'
								style={{ color: "#1948a0" }}>
								إغلاق
							</h2>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUsDetails;
