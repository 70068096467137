import React, { useContext } from "react";
import styles from "./FunctionalRoles.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Contexts */
import Context from "../../../store/context";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { FiEdit } from "react-icons/fi";
import { Delete } from "../../../assets/Icons";
import {
	useDeleteRoleIdMutation,
	useGetRolesQuery,
} from "../../../RTK/apiSlices/rolesApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const FunctionalRoles = () => {
	const navigate = useNavigate();
	const { data: fetchedData, isLoading: loading } = useGetRolesQuery();
	const RolePermissionsStore = useContext(RolePermissionsContext);

	const { permissions } = RolePermissionsStore;
	const [deleteRoleId] = useDeleteRoleIdMutation();
	const deleteRole = async (id) => {
		try {
			await deleteRoleId({ id }).unwrap();
		} catch (err) {
			console.error("Failed to delete", err);
		}
	};
	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | الأدوار الوظيفية</title>
			</Helmet>

			<BackDrop
				onClick={() => {
					navigate("/users");
				}}></BackDrop>
			<div
				className={`fixed bottom-0  lg:left-28 left-0 bg-slate-50 z-30 otlobha_new_product ${styles.container}`}
				style={{
					width: "978px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
				}}>
				<div className='flex flex-col justify-between h-full'>
					<div
						className='md:px-8 pt-24 md:pt-3 py-3 px-5 flex flex-col justify-between gap-[10px]'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<h2 className='text-2xl font-bold'>الأدوار الوظيفية</h2>
						<div className='flex flex-col items-start justify-between gap-2 md:flex-row md:items-center'>
							<div className='flex flex-col'>
								<h2 className='text-[#7C7C7C] md:text-xl text font-medium'>
									<span className='text-black '>جدول المستخدمين / </span>
									الأدوار الوظيفية
								</h2>
							</div>
							{permissions?.includes("admin.role.store") && (
								<Button
									type={"normal"}
									className={
										"md:h-14 save_button h-[45px] md:w-[217px] w-full font-normal text-xl "
									}
									onClick={() => {
										navigate("/users/roles/add-role");
									}}>
									إنشاء دور
								</Button>
							)}
						</div>
					</div>
					<div
						className={`flex-1 overflow-y-scroll py-12 p-4 h-[708px]   ${styles.content}`}>
						{loading ? (
							<div className='flex flex-col items-center justify-center'>
								<CircularLoading />
							</div>
						) : (
							<div>
								{fetchedData?.roles?.map((role, index) => {
									return (
										<div
											key={index}
											className='flex justify-between items-center bg-[#FAFAFA] shadow-[0px_3px_6px_#0000000F] mb-3 py-2 mx-2 px-4'>
											<h2 className='font-medium text-[20px]'>{role?.name}</h2>
											<div className='flex flex-col gap-4 sm:flex-row '>
												{permissions?.includes("admin.role.update") && (
													<Button
														className='w-[111px] save_button md:h-[47px] h-[40px] md:text-lg text'
														svg={
															<FiEdit
																color='#fff'
																className='w-4 h-4 md:w-6 md:h-6'
															/>
														}
														type={"normal"}
														onClick={() => {
															navigate(`/users/roles/edit-role/${role?.id}`);
														}}>
														تحرير
													</Button>
												)}
												{permissions?.includes("admin.role.destroy") && (
													<Button
														className='w-[111px] md:h-[47px] h-[40px] md:text-lg text'
														textStyle={{ color: "#FF3838" }}
														style={{ backgroundColor: "#FFF7F7" }}
														svg={
															<Delete
																color='#FF3838'
																className='w-4 h-4 md:w-6 md:h-6'
															/>
														}
														type={"normal"}
														onClick={() => {
															deleteRole(role?.id);
														}}>
														حذف
													</Button>
												)}
											</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
					<div
						className='flex justify-center gap-4 px-8 py-4'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<Button
							style={{
								borderColor: `rgba(2, 70, 106, 1)`,
							}}
							textStyle={{ color: "rgba(2, 70, 106, 1)" }}
							className={"h-14 w-11/12 md:w-1/2 text-xl font-normal"}
							type={"outline"}
							onClick={() => {
								navigate("/users");
							}}>
							اغلاق
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default FunctionalRoles;
