import { Pie } from "@ant-design/plots";

const PieChartSec = ({ pending, finished, technicalCount }) => {
	const data = [
		{ name: "Group A", value: finished || 0 },
		{ name: "Group B", value: pending || 0 },
	];
	const COLORS = ["#dfdce5", "#1948a0"];
	const TITLES = ["شكأوى تمت معالجتها", "شكأوى قيد المعالجة"];
	const total = technicalCount || 0;

	const config = {
		appendPadding: 10,
		data,
		angleField: "value",
		colorField: "name",
		tooltip: false,
		radius: 5,
		color: COLORS,
		radius: 1,
		height: 140,
		width: 200,
		innerRadius: 0.85,
		legend: false,
		annotations: [
			{
				type: "regionFilter",
				position: ["", "0"],
				content: "0",
				style: {
					fill: "red",
				},
			},
		],
		label: {
			content: "",
		},
		statistic: {
			title: false,
			content: {
				style: {
					whiteSpace: "pre-wrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					fontSize: "1rem",
					lineHeight: "1.5rem",
					fontWeight: "400",
					color: "#1948a0",
				},
				content: `${total}\nشكوى`,
			},
		},
	};
	return (
		<div className='flex flex-wrap gap-2 md:flex-nowrap'>
			<div className='flex-1'>
				{/* <PieChart width={200} height={130}>
          <Pie
            activeIndex={0}
            activeShape={renderActiveShape}
            data={data}
            innerRadius={70}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                className={`${index}`}
              />
            ))}
          </Pie>
        </PieChart> */}
				<Pie {...config} />
			</div>

			<div className='flex flex-col justify-center flex-1 gap-4'>
				{COLORS?.map((color, idx) => {
					return (
						<div key={idx} className='flex gap-3 '>
							<div
								className='w-5 h-5 translate-y-1 rounded-full'
								style={{ backgroundColor: `${color}` }}></div>
							<div>
								<h2
									className='font-medium md:text[18px] text-[16px]'
									style={{ color: color }}>
									{TITLES[idx]}
								</h2>
								<h2
									className='font-medium md:text[18px] text-[16px]'
									style={{ color: color }}>
									{data[idx].value}
								</h2>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PieChartSec;
