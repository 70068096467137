import React, { useState, useContext, useEffect } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import DelegatesPageTable from "../../../components/DelegatesPageComp/DelegatesTablePageComp/DelegatesPageTable/DelegatesPageTable";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import {
	useGetMarketersQuery,
	useSearchInMarketersMutation,
} from "../../../RTK/apiSlices/marketersApi";

const DelegatesTablePage = () => {
	const navigate = useNavigate();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetMarketersQuery({
		page,
		number: rowsPerPage,
	});
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	let resultData = fetchedData?.marketers || [];
	if (search !== "") {
		resultData = searchResults?.marketers;
	} else {
		resultData = fetchedData?.marketers;
	}

	const [searchInMarketers, { isLoading: searchIsLoading }] =
		useSearchInMarketersMutation();
	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInMarketers({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(resultData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | المناديب</title>
			</Helmet>
			<div className={`p-4 2xl:pl-36 pl-4 bg-[#EEF4FC]`}>
				<div className='flex flex-col items-start justify-between md:flex-row md:items-center gap-y-4'>
					<PageNavigate currentPage={"عرض المندوبين"} />
					{permissions?.includes("admin.marketer.store") && (
						<Button
							className={
								"flex justify-center save_button items-center md:h-14 h-[45px] md:w-[234px] w-full md:text-lg text-[16px]"
							}
							type={"normal"}
							svg={<AiOutlinePlus color='#fff' className='w-5 h-5' />}
							color={"white"}
							onClick={() => {
								navigate("/add_marketer");
							}}>
							اضافة مندوب جديد
						</Button>
					)}
				</div>
				<div className='mt-5'>
					<label className={` flex-1 relative `}>
						<input
							className='w-full p-4 pr-10 bg-transparent rounded-lg h-14 outline-0'
							placeholder=' ابحث عن مندوب'
							type='text'
							name='name'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							style={{
								border: "1px solid #1948a0",
							}}
						/>
						<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
							<AiOutlineSearch color='#1948a0' size={"18px"} />
						</div>
					</label>
				</div>

				<div className='mt-4 md:mt-10' dir={"ltr"}>
					<DelegatesPageTable
						fetchedData={resultData}
						loading={loading || searchIsLoading}
						pageNumber={page}
						setPageNumber={setPage}
						currentPage={rowsPerPage}
						setCurrentPage={setRowsPerPage}
						page_count={fetchedData?.page_count}
						search={search}
					/>
				</div>
			</div>
		</>
	);
};

export default DelegatesTablePage;
