import React, { useState, useContext, useEffect } from "react";
import styles from "./EditUser.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
/* Contexts */
import { LoadingContext } from "../../../store/LoadingProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { HiOutlineMail } from "react-icons/hi";
import { IoIosCall } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import {
	PasswordClose,
	PasswordOpen,
	UploadOutlined,
} from "../../../assets/Icons";
import {
	useGetUserDataQuery,
	useUpdateUserMutation,
} from "../../../RTK/apiSlices/usersApi";
import { useAdminRolesQuery } from "../../../RTK/apiSlices/selectorApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}
		/>
	);
};

const EditUser = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data: fetchedData, isLoading: loading } = useGetUserDataQuery({
		id,
	});

	const { data: roleList } = useAdminRolesQuery();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [showPassword, setShowPassword] = React.useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			user_name: "",
			email: "",
			phonenumber: "",
			role: "",
			password: "",
			confirm_password: "",
		},
	});

	const [userData, setUserData] = useState({
		image: "",
		name: "",
		user_name: "",
		email: "",
		phonenumber: "",
		role: "",
	});

	useEffect(() => {
		if (fetchedData?.status === 200) {
			setUserData({
				...userData,
				image: fetchedData?.users?.image,
				name: fetchedData?.users?.name,
				user_name: fetchedData?.users?.user_name,
				email: fetchedData?.users?.email,
				phonenumber: fetchedData?.users?.phonenumber?.startsWith("+966")
					? fetchedData?.users?.phonenumber?.slice(4)
					: fetchedData?.users?.phonenumber?.startsWith("00966")
					? fetchedData?.users?.phonenumber.slice(5)
					: fetchedData?.users?.phonenumber,
				role: fetchedData?.users?.role?.name,
			});
		}
	}, [fetchedData]);

	useEffect(() => {
		reset(userData);
	}, [userData, reset]);

	const [images, setImages] = useState([]);
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChangeImage = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList?.every(
			(image) => image?.file?.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};

	const [DataError, setDataError] = useState({
		name: "",
		user_name: "",
		email: "",
		phonenumber: "",
		role: "",
		password: "",
		confirm_password: "",
		image: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			user_name: "",
			email: "",
			phonenumber: "",
			role: "",
			password: "",
			confirm_password: "",
			image: "",
		});
	};

	const [updateUser] = useUpdateUserMutation();

	const updateUserHandel = async (formData) => {
		try {
			const res = await updateUser({
				id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/users");
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					user_name: res?.data?.message?.en?.user_name?.[0],
					email: res?.data?.message?.en?.email?.[0],
					phonenumber: res?.data?.message?.en?.phonenumber?.[0],
					role: res?.data?.message?.en?.role?.[0],
					password: res?.data?.message?.en?.password?.[0],
					password_confirm: res?.data?.message?.en?.password_confirm?.[0],
					image: res?.data?.message?.en?.image?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.user_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.email?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.phonenumber?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.role?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.password?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.password_confirm?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.image?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleUpdateUser = (data) => {
		setLoadingTitle("جاري تعديل المستخدم");
		resetData();
		const formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("name", data?.name);
		formData.append("user_name", data?.user_name);
		formData.append("email", data?.email);
		formData.append(
			"phonenumber",
			data?.phonenumber?.startsWith("+966") ||
				data?.phonenumber?.startsWith("00966")
				? data?.phonenumber
				: `+966${data?.phonenumber}`
		);
		formData.append("role", data?.role);
		formData.append("confirm_password", data?.confirm_password);
		if (data?.password !== "") {
			formData.append("password", data?.password);
		}
		if (images.length !== 0) {
			formData.append("image", images[0]?.file || null);
		}
		updateUserHandel(formData);
	};

	// handle paste phone number to handle remove space
	const handlePastePhoneNumber = (e) => {
		e.preventDefault();
		let pastedData = (e.clipboardData || window.clipboardData).getData("text");
		pastedData = pastedData.replace(/\s+/g, "");
		e.target.value = pastedData;
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | تعديل المستخدم</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/users");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-20 otlobha_new_product ${styles.container} w-['1104px'] max-w-full`}
				style={{
					height: "calc(100% - 4rem)",
				}}>
				<form onSubmit={handleSubmit(handleUpdateUser)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div className='md:p-8 p-5 md:h-[135px] h-[100px] bg-[rgba(235, 235, 235, 1)]'>
							<h2 className='font-bold md:text-2xl text-[18px] mb-3'>
								تعديل بيانات المستخدم
							</h2>
							<div className='flex'>
								<h2 className='font-medium md:text-lg text-[14px] md:ml-4 ml-1'>
									جدول المستخدمين
								</h2>

								<h3 className='font-medium md:text-lg text-[14px] text-[#7C7C7C]'>
									/ تعديل بيانات المستخدم
								</h3>
							</div>
						</div>
						{loading ? (
							<div className='flex flex-col items-center justify-center w-full h-full'>
								<CircularLoading />
							</div>
						) : (
							<>
								<div
									className={`flex-1 md:px-20 px-5 overflow-y-scroll md:py-12 py-5  ${styles.content}`}>
									<div className='flex flex-col-reverse justify-between md:flex-row'>
										<div className='flex flex-col items-center gap-4 md:flex-row md:items-start'>
											<div className='w-20 h-20 md:h-44 md:w-44'>
												<img
													src={images[0]?.data_url || userData?.image}
													alt='profile-img'
													loading='lazy'
												/>
											</div>
											<div>
												<h2 className='md:text-xl text-[18px] font-medium mb-3  text-center'>
													{userData?.name}
												</h2>
												<h2 className='md:text-lg text-[16px] font-normal mb-3 text-[#b6b1c4] flex gap-2 items-center'>
													<HiOutlineMail className=' cursor-pointer text-[#1948a0] text-2xl' />
													{userData?.email}
												</h2>
												<h2 className='md:text-lg text-[16px] font-normal mb-3 flex gap-2 items-center '>
													<IoIosCall className='cursor-pointer text-[#1948a0] text-2xl' />
													<span style={{ direction: "ltr" }}>
														{userData?.phonenumber}
													</span>
												</h2>
											</div>
										</div>
										<div>
											<Button
												btnType={"button"}
												className='save_button md:h-14 h-[45px] md:w-[278px] w-full cursor-auto md:text-xl text-[18px] font-normal md:mb-0 mb-4'
												type={"normal"}>
												{userData?.role}
											</Button>
										</div>
									</div>
									<div className='flex flex-col gap-4 mt-4 lg:flex-row md:mt-12 md:gap-48'>
										<div className='flex-1'>
											<div className='mt-4 md:mt-6'>
												<h2 className='font-normal md:text-lg text-[16px] text-[#1948a0] mb-2'>
													الدور الوظيفى<span className='text-red-500'>*</span>
												</h2>
												<Controller
													name={"role"}
													control={control}
													rules={{ required: "The role field is required" }}
													render={({ field: { onChange, value } }) => (
														<Select
															className='w-full md:h-14 h-[45px] outline-none rounded-lg'
															value={value}
															onChange={onChange}
															displayEmpty
															inputProps={{ "aria-label": "Without label" }}
															IconComponent={() => {
																return <IoIosArrowDown size={"1rem"} />;
															}}
															renderValue={(selected) => {
																if (!selected) {
																	return <h2>اختر نوع الدور الوظيفي</h2>;
																}
																const result = roleList?.roles?.filter(
																	(item) => item?.name === selected
																);
																return result?.[0]?.name;
															}}
															sx={{
																height: "3.5rem",
																width: "100%",
																border: "none",
																pl: "1rem",
																backgroundColor: "#EEF4FC",
																"& .MuiOutlinedInput-notchedOutline": {
																	border: "none",
																},
															}}>
															{roleList?.roles?.map((item, index) => {
																return (
																	<MenuItem
																		key={index}
																		className='souq_storge_category_filter_items'
																		sx={{
																			backgroundColor: "#EEF4FC",
																			height: "3rem",
																			"&:hover": {},
																		}}
																		value={`${item?.name}`}>
																		{item?.name}
																	</MenuItem>
																);
															})}
														</Select>
													)}
												/>
											</div>
											<div className='mt-4 md:mt-6'>
												<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
													اسم المستخدم<span className='text-red-500'>*</span>
												</h2>
												<div className='flex flex-col w-full gap-2'>
													<Controller
														name={"user_name"}
														control={control}
														rules={{
															required: "حقل اسم المُستخدم مطلوب",
															pattern: {
																value: /^[^-\s][a-zA-Z0-9_]+$/,
																message:
																	"يجب ان يتكون اسم المُستخدم من أحرف انجليزية",
															},
														}}
														render={({ field: { onChange, value } }) => (
															<input
																className='w-full md:h-14 h-[45px] outline-none p-4 rounded-lg'
																style={{
																	border: "none",
																	backgroundColor: "#EEF4FC",
																}}
																type='text'
																placeholder='ادخل حروف فقط'
																name='user_name'
																value={value}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
															/>
														)}
													/>
												</div>
												<span className='text-red-500 text-[16px]'>
													{DataError?.user_name}
													{errors?.user_name && errors.user_name.message}
												</span>
											</div>
											<div className='mt-4 md:mt-6'>
												<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
													البريد الالكترونى
													<span className='text-red-500'>*</span>
												</h2>
												<div className='flex flex-col w-full gap-2'>
													<Controller
														name={"email"}
														control={control}
														rules={{
															required: "حقل البريد الالكتروني مطلوب",
															pattern: {
																value: /\S+@\S+\.\S+/,
																message:
																	"يجب أن يكون البريد الالكتروني عنوان بريد إلكتروني صحيح البُنية",
															},
														}}
														render={({ field: { onChange, value } }) => (
															<input
																className='border-none bg-[rgb(220, 232, 249)] w-full md:h-14 h-[45px] outline-none p-4 rounded-lg'
																type='email'
																name='email'
																value={value}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
																placeholder='sample@sa.com'
															/>
														)}
													/>
												</div>
												<span className='text-red-500 text-[16px]'>
													{DataError?.email}
													{errors?.email && errors.email.message}
												</span>
											</div>
											<div className='mt-4 md:mt-6'>
												<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
													رقم الهاتف<span className='text-red-500'>*</span>
												</h2>
												<div className='flex flex-col w-full gap-2'>
													<div
														className='border-none max-w-full min-w-[375px] flex flex-row items-center gap-[1px] h-14 p-4 mt-3 rounded-lg bg-[rgb(220, 232, 249)]'
														style={{
															direction: "ltr",
														}}>
														<span>+966</span>
														<Controller
															name={"phonenumber"}
															control={control}
															rules={{
																required: "حقل  رقم الجوال مطلوب",
																pattern: {
																	value: /^(5\d{8})$/,
																	message:
																		"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
																},
															}}
															render={({ field: { onChange, value } }) => (
																<input
																	className='w-full h-full outline-none max-w-full border-none bg-transparent '
																	style={{
																		direction: "ltr",
																	}}
																	type='tel'
																	placeholder='515131515'
																	maxLength={9}
																	name='phonenumber'
																	value={value}
																	onPaste={(e) => {
																		handlePastePhoneNumber(e);
																	}}
																	onChange={(e) => {
																		onChange(e.target.value.trim());
																	}}
																/>
															)}
														/>
													</div>
													<span className='text-red-500 text-[16px]'>
														{DataError?.phonenumber}
														{errors?.phonenumber && errors.phonenumber.message}
													</span>
												</div>
											</div>
											<div className='mt-4 md:mt-6'>
												<div className='flex flex-col mb-2'>
													<label className='font-normal md:text-lg text-[16px] text-[#1948a0] text-[#011723]'>
														الصورة الشخصية
													</label>
													<span className='text-gray-400 font-light text-[14px]'>
														(الحد الأقصى للصورة 1MB)
													</span>
												</div>
												<ImageUploading
													value={images}
													onChange={onChangeImage}
													maxNumber={2}
													dataURLKey='data_url'
													acceptType={["jpg", "png", "jpeg", "svg", "webp"]}>
													{({ onImageUpload, dragProps }) => (
														<div
															className='border-none bg-[rgb(220, 232, 249)] text-[#011723] relative flex items-center overflow-hidden rounded-lg upload__image-wrapper h-14'
															{...dragProps}>
															<h2
																className='w-full p-4 outline-none cursor-pointer'
																style={{
																	color: "#aaa",
																}}
																onClick={() => {
																	onImageUpload();
																}}>
																{images[0]?.file?.name || ""}
															</h2>
															<div className='w-[3.5rem] bg-[#1948a0] flex items-center justify-center h-full'>
																<img
																	src={UploadOutlined}
																	alt='upload-icon'
																	loading='lazy'
																/>
															</div>
														</div>
													)}
												</ImageUploading>
											</div>
											<span className='text-red-500 text-[16px]'>
												{DataError?.image}
												{errors?.image && errors.image.message}
											</span>
										</div>
										<div className='flex-1'>
											<div className='mt-0 md:mt-6'>
												<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
													كلمة المرور
												</h2>
												<div className='flex flex-col w-full gap-2'>
													<div className='flex flex-row border-none bg-[rgb(220, 232, 249)] overflow-hidden items-center justify-between w-full p-4 md:h-14 h-[45px]'>
														<Controller
															name={"password"}
															control={control}
															rules={{}}
															render={({ field: { onChange, value } }) => (
																<input
																	className='w-full border-none bg-[rgb(220, 232, 249)] pl-2 -mb-[8px] rounded-lg outline-none'
																	style={{
																		direction: "ltr",
																	}}
																	type={showPassword ? "text" : "password"}
																	placeholder='00000000'
																	name='password'
																	value={value}
																	onChange={(e) => {
																		onChange(e.target.value);
																	}}
																/>
															)}
														/>
														{showPassword ? (
															<PasswordOpen
																className={styles.password}
																onClick={() => setShowPassword(!showPassword)}
															/>
														) : (
															<PasswordClose
																className={styles.password}
																onClick={() => setShowPassword(!showPassword)}
															/>
														)}
													</div>
													<p className='md:text-base text-[14px] font-normal text-[#ADB5B9]'>
														أدخل أرقام وحروف ورموز
													</p>
													<span className='text-red-500 text-[16px]'>
														{DataError?.password}
														{errors?.password && errors.password.message}
													</span>
												</div>
											</div>
											<div className='mt-4 md:mt-6'>
												<h2 className='font-medium md:text-lg text-[16px] mb-2'>
													تأكيد كلمة المرور
												</h2>
												<div className='flex flex-col w-full gap-2'>
													<div className='flex border-none bg-[rgb(220, 232, 249)] overflow-hidden flex-row items-center justify-between w-full p-4 md:h-14 h-[45px]'>
														<Controller
															name={"confirm_password"}
															control={control}
															rules={{}}
															render={({ field: { onChange, value } }) => (
																<input
																	className='w-full border-none bg-[rgb(220, 232, 249)] pl-2 -mb-[8px] md:h-14 h-[45px] outline-none p-4 rounded-lg'
																	style={{
																		direction: "ltr",
																	}}
																	type={
																		showPasswordConfirm ? "text" : "password"
																	}
																	placeholder='00000000'
																	name='confirm_password'
																	value={value}
																	onChange={(e) => {
																		onChange(e.target.value);
																	}}
																/>
															)}
														/>
														{showPasswordConfirm ? (
															<PasswordOpen
																className={styles.password}
																onClick={() =>
																	setShowPasswordConfirm(!showPasswordConfirm)
																}
															/>
														) : (
															<PasswordClose
																className={styles.password}
																onClick={() =>
																	setShowPasswordConfirm(!showPasswordConfirm)
																}
															/>
														)}
													</div>
													<p className='md:text-base text-[14px] font-normal text-[#ADB5B9]'>
														أدخل أرقام وحروف ورموز
													</p>
													<span className='text-red-500 text-[16px]'>
														{DataError?.confirm_password}
														{errors?.confirm_password &&
															errors.confirm_password.message}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className='md:h-[135px] h-[100px] p-8 flex justify-center gap-4'
									style={{
										backgroundColor: "rgba(235, 235, 235, 1)",
									}}>
									<div className='flex flex-row items-center gap-4'>
										<Button
											btnType={"submit"}
											textStyle={{ color: "#fff" }}
											className={
												"md:h-14 h-[45px] w-[181px] md:text-2xl text-[18px] save_button"
											}
											type={"outline"}>
											حفظ
										</Button>
										<Button
											textStyle={{ color: "#f75e22" }}
											className={
												"md:h-14 h-[45px] w-44 md:text-2xl text-[18px] bg-[#f75e22]"
											}
											type={"outline"}
											onClick={() => {
												navigate("/users");
											}}>
											اغلاق
										</Button>
									</div>
								</div>
							</>
						)}
					</div>
				</form>
			</div>
		</>
	);
};

export default EditUser;
