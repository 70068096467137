import React from "react";
/* Components */
import IncreaseRateChartPiece from "./IncreaseRateChartPiece/IncreaseRateChartPiece";
/* MUI */
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
/* Icons */
import { IoIosArrowDown } from "react-icons/io";

const IncreaseRateChart = ({ chartInfo, getYear }) => {
	const [year, setYear] = React.useState(new Date().getFullYear());
	const handleChange = (event) => {
		setYear(event.target.value);
		getYear(event.target.value);
	};
	const array = [];
	const thisYear = new Date().getFullYear();

	for (let index = 0; index < 10; index++) {
		array.push(thisYear - index);
	}
	return (
		<div className='bg-slate-50 flex-1 shadow-lg'>
			<div className='flex flex-row px-4 pt-4 flex-wrap gap-4'>
				<div>
					<h2 className='md:text-[18px] text-[14px] font-medium'>
						إحصائيات توضح معدل الزيادة
					</h2>
					<div className='flex flex-row gap-2 mt-4'>
						<h2 className='flex-1 flex flex-row items-baseline gap-2 whitespace-nowrap'>
							متاجر فعلة
							<span
								className='inline-flex h-3 w-3 rounded-full'
								style={{ border: "3px solid #1948a0" }}></span>
						</h2>
						<h2 className='flex-1 flex flex-row items-baseline gap-2 whitespace-nowrap'>
							متاجر غير فعالة
							<span
								className='inline-flex h-3 w-3 rounded-full'
								style={{ border: "3px solid #eae8ee" }}></span>
						</h2>
					</div>
				</div>

				<Box sx={{ minWidth: 100, marginRight: "auto" }}>
					<FormControl fullWidth>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							value={year}
							onChange={handleChange}
							IconComponent={() => {
								return (
									<IoIosArrowDown
										className='select_arrow duration-200'
										size={"1.5rem"}
									/>
								);
							}}
							sx={{
								height: "2.25rem",
								backgroundColor: "#fff",
								width: "100%",
								color: "#1948a0",
								pl: "1rem",
								py: "0.5rem",
								"& path": {
									fill: "#1948a0",
								},
								"& .MuiSelect-select": { color: "#1948a0" },
								"&.Mui-focused .select_arrow": {
									transform: "rotate(180deg)",
								},
								"& .MuiOutlinedInput-notchedOutline": {
									border: "1px solid #1948a0",
								},
								"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
									border: "1px solid #1948a0",
								},
							}}>
							{array?.map((item) => {
								return (
									<MenuItem key={item} value={item}>
										{item}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Box>
			</div>
			<IncreaseRateChartPiece chartInfo={chartInfo} />
		</div>
	);
};

export default IncreaseRateChart;
