import React, { useState, useContext, useEffect } from "react";
import styles from "../../SoquStorageComp/NewProduct/NewProduct.module.css";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
/* Custom Hook */

/* Context */
import { LoadingContext } from "../../../store/LoadingProvider";

/* Custom Button */
import Button from "../../../UI/Button/Button";
import {
	useEditPackageFeaturesMutation,
	useShowPackageFeaturesQuery,
} from "../../../RTK/apiSlices/subscriptionsApi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import { TextEditorContext } from "../../../store/TextEditorProvider";
import { TextEditor } from "../../TextEditor";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const EditPackageFeatures = () => {
	const { id } = useParams();

	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;

	const navigate = useNavigate();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [packagesFeaturesData, setPackagesFeaturesData] = useState({
		name: "",
	});

	const { data: currentPackageFeature, isLoading } =
		useShowPackageFeaturesQuery({ id });
	useEffect(() => {
		if (currentPackageFeature) {
			setPackagesFeaturesData({
				...packagesFeaturesData,
				name: currentPackageFeature?.name,
			});

			setEditorValue(currentPackageFeature?.description);
		} else {
			setEditorValue("");
		}
	}, [currentPackageFeature]);

	const [DataError, setDataError] = useState({
		name: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
		});
	};

	const closePage = () => {
		navigate("/packages/package-features");
		setEditorValue("");
	};

	const [editPackageFeatures, { isLoading: editIsLoading }] =
		useEditPackageFeaturesMutation();
	const handleEditPackageFeatures = async () => {
		resetData();
		setLoadingTitle("جاري تعديل الخطة");

		let formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("name", packagesFeaturesData?.name);
		formData.append("description", editorValue);

		try {
			const res = await editPackageFeatures({
				id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				closePage();
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
			}
		} catch (err) {
			console.error("Failed to addPackageFeatures", err);
		}
	};

	return (
		<>
			<BackDrop
				onClick={() => {
					closePage();
				}}
			/>
			<div
				className=' absolute flex flex-col  top-3  translate-x-2/4 right-2/4 rounded-lg z-50'
				style={{ width: "60.25rem", maxWidth: "90%" }}>
				<div
					className='flex items-center justify-between w-full h-16 px-4 py-4 trader_alert'
					style={{
						backgroundColor: "#fc8d5d",
					}}>
					{isLoading ? (
						<h2
							style={{ color: "#ECFEFF" }}
							className='md:text-[22px] text-[18px] font-medium text-center flex-1'>
							...
						</h2>
					) : (
						<h2
							style={{ color: "#ECFEFF" }}
							className='md:text-[22px] text-[18px] font-medium text-center flex-1'>
							{`تعديل : ${currentPackageFeature?.name}`}
						</h2>
					)}
					<IoMdCloseCircleOutline
						width='20px'
						height='20px'
						size={"1.25rem"}
						color={"#fff"}
						className={"cursor-pointer"}
						onClick={() => {
							closePage();
						}}
					/>
				</div>
				<div className='md:px-14 md:py-10 px-4 py-5  bg-white text-right overflow-y-auto'>
					{isLoading ? (
						<div className='w-full flex flex-col items-center justify-center min-h-[50vh]'>
							<CircularLoading />
						</div>
					) : (
						<>
							<div className='flex flex-col gap-1 mb-5'>
								<label className='md:text-[18px] text-[#011723]  text-[16px]'>
									اسم الخطة<span className='text-red-500'>*</span>
								</label>
								<input
									id='store_name'
									className='md:text-md text-[#1948a0] border-[1px] border-[#A7C1F1] bg-[#EDF3FC] text-base py-[12px] px-[24px] rounded-md outline-none'
									type='text'
									placeholder='ادخل اسم الخطة'
									value={packagesFeaturesData?.name}
									onChange={(e) =>
										setPackagesFeaturesData({
											...packagesFeaturesData,
											name: e.target.value,
										})
									}
								/>

								<span className='text-red-500 text-[16px]'>
									{DataError?.name}
								</span>
							</div>

							<div className='flex flex-col gap-1 mb-5'>
								<label className='md:text-[18px] text-[#011723]  text-[16px]'>
									وصف الخطة<span className='text-red-500'>*</span>
								</label>
								<div className={styles.editor}>
									<TextEditor
										ToolBar={"createOrEditPages"}
										placeholder={"ادخل وصف قصير عن الخطة ..."}
									/>
								</div>
							</div>
						</>
					)}
				</div>
				<div className='flex flex-row items-center'>
					<Button
						disabled={isLoading || editIsLoading}
						onClick={handleEditPackageFeatures}
						type={"normal"}
						className='md:h-[60px] save_button h-[45px] md:text-[18px] text-[16px]  text-[#1948a0]text-center w-full py-4 rounded-none font-medium'>
						حفظ التعديل
					</Button>
					<Button
						onClick={() => closePage()}
						type={"outline"}
						style={{ borderColor: "#f75e22" }}
						textStyle={{ color: "#f75e22" }}
						className='md:h-[60px] h-[45px] md:text-[18px] text-[16px]  text-[#1948a0]text-center w-full py-4 rounded-none font-medium'>
						الغاء
					</Button>
				</div>
			</div>
		</>
	);
};

export default EditPackageFeatures;
