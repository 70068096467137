import React, { useState, useContext, Fragment } from "react";
import { Helmet } from "react-helmet";
import moment from "moment-with-locales-es6";
import Context from "../../../store/context";
import { NotificationContext } from "../../../store/NotificationProvider";
import { DeleteContext } from "../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
import TraderAlert from "../../../components/SettingComp/NotificationsPageComp/TraderAlert/TraderAlert";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../../components/DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../components/DeleteModal/DeleteModal";
import {
	CommunicationSendOutlined,
	Delete,
	ShowStoreRequest,
	CheckedSquare,
} from "../../../assets/Icons/index";
import {
	useGetNotificationsQuery,
	useNotificationsChangeStatusAllByParamsMutation,
	useNotificationsDeleteAllByParamsMutation,
	useNotificationsDeleteItemMutation,
} from "../../../RTK/apiSlices/notificationsApi";

const NotificationsPage = () => {
	const { data: fetchedData, isLoading: loading } = useGetNotificationsQuery();
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const [traderAlert, setTraderAlert] = useState(false);
	const [showNotificationInfo, setShowNotificationInfo] = useState(false);
	const [traderPackageDetails, setTraderPackageDetails] = useState([]);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle, setNotificationTitle, setActionType, setItems } =
		NotificationStore;

	const formatDate = (date) => {
		const calcPassedMinutes = (date1, date2) =>
			Math.round(Math.abs(date2 - date1) / (1000 * 60));
		const currentMinutes = calcPassedMinutes(+new Date(), +new Date(date));
		if (currentMinutes < 1) {
			return "الآن";
		} else if (currentMinutes === 1) {
			return "منذ دقيقة";
		} else if (currentMinutes === 2) {
			return "منذ دقيقتين";
		} else if (currentMinutes <= 10) {
			return `منذ ${currentMinutes} دقائق`;
		} else if (currentMinutes < 60 && currentMinutes >= 11) {
			return `منذ ${currentMinutes} دقيقة`;
		} else if (currentMinutes === 60) {
			return "منذ ساعة";
		} else if (currentMinutes === 120) {
			return "منذ ساعتين";
		} else if (currentMinutes < 1440) {
			let hours = Math.floor(currentMinutes / 60);
			let min = currentMinutes % 60;
			if (hours === 1) {
				return `منذ ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else if (hours === 2) {
				return `منذ  ساعتين و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else if (hours <= 10) {
				return `منذ ${hours} ساعات و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			} else {
				return `منذ ${hours} ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
			}
		}
		const currentDate = Math.round(currentMinutes / 60 / 24);
		if (currentDate === 1) {
			return "أمس، الساعة " + moment(date).locale("ar").format(" h:mm a");
		} else if (currentDate === 2) {
			return " منذ يومين، الساعة" + moment(date).locale("ar").format(" h:mm a");
		} else if (currentDate <= 7) {
			return (
				`منذ ${currentDate}  أيام، الساعة` +
				moment(date).locale("ar").format(" h:mm a")
			);
		}
		return moment(date).locale("ar").format("D MMMM YYYY");
	};
	// -----------------------------------------------------------------

	const [selected, setSelected] = React.useState([]);
	const isSelected = (id) => selected.indexOf(id) !== -1;
	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.notifications?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [notificationsChangeStatusAllByParams] =
		useNotificationsChangeStatusAllByParamsMutation();
	const notificationsChangeStatusAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await notificationsChangeStatusAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const [notificationsDeleteAllByParams] =
		useNotificationsDeleteAllByParamsMutation();
	const notificationsDeleteAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await notificationsDeleteAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	const [notificationsDeleteItem] = useNotificationsDeleteItemMutation();
	const handleDeleteSingleItem = async (id) => {
		try {
			await notificationsDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	return (
		<Fragment>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | الاشعارات</title>
			</Helmet>
			{traderAlert && (
				<TraderAlert
					cancel={() => {
						setTraderAlert(false);
					}}
					traderPackageDetails={traderPackageDetails}
					showNotificationInfo={showNotificationInfo}
				/>
			)}
			<div
				className={`relative xl:pb-10 2xl:pl-36 2xl:pr-24 p-4`}
				style={{ backgroundColor: "#F7F7F7" }}>
				<h3
					style={{ color: "#011723" }}
					className='md:text-[24px] text-[20px] font-bold'>
					الاشعارات
				</h3>
				<div className='mt-8'>
					<div className='flex flex-row items-center gap-[53px]'>
						<div className='flex flex-row items-center gap-3'>
							<Checkbox
								checkedIcon={<CheckedSquare />}
								sx={{
									pr: "0",
									color: "#1948a0",
									"& .MuiSvgIcon-root": {
										color: "#1948a0",
									},
								}}
								indeterminate={
									selected.length > 0 &&
									selected.length < fetchedData?.notifications.length
								}
								checked={
									fetchedData?.notifications.length > 0 &&
									selected.length === fetchedData?.notifications.length
								}
								onChange={handleSelectAllClick}
								inputProps={{
									"aria-label": "select all desserts",
								}}
							/>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}
								htmlFor='all'>
								تحديد الكل
							</label>
						</div>

						<div className='flex flex-row items-center justify-center gap-2'>
							{permissions?.includes("admin.notification.deleteall") &&
								selected.length > 0 && (
									<div
										className='flex flex-row items-center justify-center  cursor-pointer'
										style={{
											width: "130px",
											height: "40px",
											backgroundColor: "#FF38381A",
											borderRadius: "20px",
										}}
										onClick={() => {
											setNotificationTitle(
												"سيتم حذف جميع الاشعارات التي قمت بتحديدها"
											);
											setItems(selected);
											setActionType("deleteAll");
										}}>
										<Delete />
										<h6
											className='md:text-[18px] text-[16px]  text-[#1948a0] font-medium'
											style={{ color: "#FF3838" }}>
											حذف الكل
										</h6>
									</div>
								)}
						</div>
					</div>
					<div className='flex flex-col flex-wrap gap-4 mt-4'>
						{loading ? (
							<CircularLoading />
						) : (
							fetchedData?.notifications?.map((box, index) => {
								const isItemSelected = isSelected(box?.id);
								return (
									<div
										key={box?.id}
										style={{ boxShadow: "3px 3px 6px #00000005" }}
										className='flex flex-col items-start justify-between w-full gap-2 px-4 py-2 bg-white md:flex-row md:items-center'>
										<div className='flex flex-row items-center w-full gap-4 md:gap-8'>
											<Checkbox
												checkedIcon={<CheckedSquare />}
												sx={{
													color: "#4875c2",
													"& .MuiSvgIcon-root": {
														color: "#ADB5B9",
													},
												}}
												checked={isItemSelected}
												onClick={(event) => handleClick(event, box?.id)}
											/>
											<div className='flex flex-row items-center justify-between w-full md:pl-20'>
												<div className='flex flex-col gap-1'>
													<h2
														style={{ color: "#011723" }}
														className='md:text-[20px] text-[18px] font-medium whitespace-nowrap'>
														{box?.message}
													</h2>
													<p
														className='md:text-[18px] text-[16px]'
														style={{ color: "#011723" }}>
														{box?.store_name}
													</p>
												</div>
												<div className='hidden md:flex'>
													<p
														style={{ color: "#A7A7A7" }}
														className='md:text-[16px] text-[14px] font-light'>
														{formatDate(box?.created_at)}
													</p>
												</div>
											</div>
										</div>
										<div className='flex flex-row items-center justify-between w-full md:w-auto'>
											<div className='flex flex-row items-center gap-[10px] md:mr-0 mr-[1rem]'>
												{permissions?.includes("admin.notification.show") && (
													<ShowStoreRequest
														className='cursor-pointer'
														title='تفاصيل'
														onClick={() => {
															setTraderAlert(true);
															setShowNotificationInfo(true);
															setTraderPackageDetails(box);
														}}
													/>
												)}
												{permissions?.includes("admin.notification.show") && (
													<CommunicationSendOutlined
														title='ارسال بريد'
														className='cursor-pointer'
														onClick={() => {
															setTraderAlert(true);
															setShowNotificationInfo(false);
															setTraderPackageDetails(box);
														}}
													/>
												)}
												{permissions?.includes(
													"admin.notification.deleteall"
												) && (
													<Delete
														title='حذف'
														className='cursor-pointer'
														onClick={() => {
															setActionDelete(
																"سيتم حذف الاشعار وهذة الخطوة غير قابلة للرجوع"
															);
															setItemId(box?.id);
														}}
													/>
												)}
											</div>
											<div className='flex md:hidden'>
												<p
													style={{ color: "#A7A7A7" }}
													className='md:text-[16px] text-[14px] font-light'>
													{formatDate(box?.created_at)}
												</p>
											</div>
										</div>
									</div>
								);
							})
						)}
					</div>
				</div>
			</div>
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleDeleteAllItems={notificationsDeleteAllByParamsHandel}
					handleChangeAllItemsStatus={
						notificationsChangeStatusAllByParamsHandel
					}
				/>
			)}
		</Fragment>
	);
};

export default NotificationsPage;
