import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const usersApi = createApi({
	reducerPath: "usersApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),
	tagTypes: ["Users"],

	endpoints: (builder) => ({
		getUsers: builder.query({
			query: (args) => ({
				url: `user?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Users"],
		}),

		getUserData: builder.query({
			query: ({ id }) => ({
				url: `user/${id}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Users", id }],
		}),

		searchInUsers: builder.mutation({
			query: (arg) => ({
				url: `searchUserName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		usersChangeStatusAll: builder.mutation({
			query: ({ id }) => ({
				url: `userchangeSatusall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Users"],
		}),

		userChangeStatusAllByParams: builder.mutation({
			query: ({ queryParams }) => ({
				url: `userchangeSatusall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Users"],
		}),

		userDeleteAllByParams: builder.mutation({
			query: ({ queryParams }) => ({
				url: `userdeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Users"],
		}),

		updateUser: builder.mutation({
			query: ({ id, body }) => {
				return {
					url: `user/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Users"],
		}),

		addNewUser: builder.mutation({
			query: ({ body }) => {
				return {
					url: `user`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Users"],
		}),

		usersDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `userdeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Users"],
		}),

		filterUsersByRole: builder.mutation({
			query: ({ number, id, page }) => ({
				url: `user?&number=${number}&id=${id}&page=${page}`,
				method: "GET",
			}),
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetUsersQuery,
	useGetUserDataQuery,
	useSearchInUsersMutation,
	useUsersChangeStatusAllMutation,
	useUserChangeStatusAllByParamsMutation,
	useUserDeleteAllByParamsMutation,
	useUpdateUserMutation,
	useAddNewUserMutation,
	useUsersDeleteItemMutation,
	useFilterUsersByRoleMutation,
} = usersApi;
