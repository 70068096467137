import React, { useState, useContext, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { Navbar, SideBar } from "./components/index";

/* Third party */
import { ToastContainer } from "react-toastify";

/* Contexts */
import Context from "./store/context";
import { LoadingContext } from "./store/LoadingProvider";
import { RolePermissionsContext } from "./store/RolePermissionsProvider";

/* Notification Modal */
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import ActionCompleteComp from "./components/ActionCompleteComp/ActionCompleteComp";
import SendingLoadingComp from "./components/SendingLoadingComp/SendingLoadingComp";

/* Global Search Input */
import SearchInput from "./components/SearchInput/SearchInput";

// RTK Query
import { useProfileDataQuery } from "./RTK/apiSlices/profileApi";
import {
	myAccountContext,
	profileContext,
} from "./store/MyAccountInfoProvider";

function PrivateRoutes() {
	const location = useLocation();
	const token = localStorage.getItem("token");
	const [openSidebar, setOpenSidebar] = useState(false);
	const contextStore = useContext(Context);
	const { title } = contextStore;
	const LoadingStore = useContext(LoadingContext);
	const { loadingTitle } = LoadingStore;

	// to send permissions into setPermissions context
	const { data: profileData, isLoading } = useProfileDataQuery();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions, setRole, setPermissions } = RolePermissionsStore;

	// store profileData to context api
	const myAccountDataStore = useContext(myAccountContext);
	const { setMyAccountInfo } = myAccountDataStore;

	useEffect(() => {
		setRole(profileData?.users?.role?.name);
		setMyAccountInfo(profileData);

		setPermissions(
			profileData?.users?.role?.permissions?.map(
				(permissions) => permissions?.name
			)
		);
	}, [profileData]);

	// to set verification count in local storage
	localStorage?.setItem("verification_count", profileData?.verification_count);

	const routes = [
		{
			path: "/",
			permissions: "admin.mainpage.index",
		},

		{
			path: "/profile",
			permissions: "admin.profile.show",
		},

		{
			path: "/edit-profile",
			permissions: "admin.profile.update",
		},

		{
			path: "/souq_atlbha",
			permissions: "admin.etlobha.index",
		},
		{
			path: "/souq_atlbha/add-product",
			permissions: "admin.etlobha.store",
		},
		{
			path: "/souq_atlbha/edit-product/:id",
			permissions: "admin.etlobha.update",
		},
		{
			path: "/souq_atlbha/duplicate-product/:id",
			permissions: "admin.etlobha.store",
		},
		{
			path: "/talabat_souq_atlbha",
			permissions: "admin.adminOrder.index",
		},
		{
			path: "/talabat_souq_atlbha/show-details/:id",
			permissions: "admin.adminOrder.show",
		},
		{
			path: "/general_souq",
			permissions: "admin.platform.index",
		},
		{
			path: "/general_souq/add-souq",
			permissions: "admin.platform.store",
		},
		{
			path: "/general_souq/edit-souq/:id",
			permissions: "admin.platform.update",
		},
		{
			path: "/storage",
			permissions: "admin.stock.index",
		},
		{
			path: "/storage/add-product",
			permissions: "admin.stock.store",
		},
		{
			path: "/storage/edit-product/:id",
			permissions: "admin.stock.update",
		},
		{
			path: "/markets_section",
			permissions: "admin.store.index",
		},
		{
			path: "/markets",
			permissions: "admin.store.index",
		},
		{
			path: "/markets/add-market",
			permissions: "admin.store.store",
		},
		{
			path: "/markets/edit-market/:id",
			permissions: "admin.store.update",
		},
		{
			path: "/markets/show-market/:id",
			permissions: "admin.store.show",
		},
		{
			path: "/products",
			permissions: "admin.product.index",
		},
		{
			path: "/verification",
			permissions: "admin.verification.index",
		},
		{
			path: "/verification/edit-verification/:id",
			permissions: "admin.verification.verification_update",
		},
		{
			path: "/verification/show-verification/:id",
			permissions: "admin.verification.show",
		},
		{
			path: "/packages",
			permissions: "admin.package.index",
		},
		{
			path: "/packages/add-package",
			permissions: "admin.package.store",
		},
		{
			path: "/subscriptions",
			permissions: "admin.subscriptions.index",
		},
		{
			path: "/services",
			permissions: "admin.service.index",
		},
		{
			path: "/services/add-service",
			permissions: "admin.service.store",
		},
		{
			path: "/services/edit-service/:id",
			permissions: "admin.service.update",
		},
		{
			path: "/services/show-service/:id",
			permissions: "admin.service.show",
		},

		{
			path: "/atlbha_academy",
			permissions: "admin.course.index",
		},
		{
			path: "/atlbha_academy/add-course",
			permissions: "admin.course.store",
		},
		{
			path: "/atlbha_academy/add-lesson",
			permissions: "admin.explainvideo.store",
		},
		{
			path: "/atlbha_academy/edit-lesson/:id",
			permissions: "admin.explainvideo.update",
		},
		{
			path: "/template_page",
			permissions: "admin.homepage.index",
		},
		{
			path: "/sliders",
			permissions: "admin.homepage.index",
		},
		{
			path: "/partitions",
			permissions: "admin.homepage.index",
		},
		{
			path: "/pages",
			permissions: "admin.page.index",
		},
		{
			path: "/pages/add-page",
			permissions: "admin.page.store",
		},
		{
			path: "/pages/edit-page/:id",
			permissions: "admin.page.update",
		},
		{
			path: "/users",
			permissions: "admin.user.index",
		},
		{
			path: "/users/add-user",
			permissions: "admin.user.store",
		},
		{
			path: "/users/edit-user/:id",
			permissions: "admin.user.update",
		},
		{
			path: "/users/show-user/:id",
			permissions: "admin.user.show",
		},
		{
			path: "/users/roles",
			permissions: "admin.role.index",
		},
		{
			path: "/users/roles/add-role",
			permissions: "admin.role.store",
		},
		{
			path: "/users/roles/edit-role/:id",
			permissions: "admin.role.update",
		},
		{
			path: "/coupons",
			permissions: "admin.coupon.index",
		},
		{
			path: "/coupons/add-coupon",
			permissions: "admin.coupon.store",
		},
		{
			path: "/coupons/edit-coupon/:id",
			permissions: "admin.coupon.update",
		},
		{
			path: "/coupons/show-coupon/:id",
			permissions: "admin.coupon.show",
		},
		{
			path: "/marketers",
			permissions: "admin.marketer.index",
		},
		{
			path: "/add_marketer",
			permissions: "admin.marketer.store",
		},
		{
			path: "/edit_marketer/:id",
			permissions: "admin.marketer.update",
		},
		{
			path: "/show_marketer/:id",
			permissions: "admin.marketer.show",
		},
		{
			path: "/registration_marketers_status",
			permissions: "admin.marketer.registration_marketer_show",
		},
		{
			path: "/categories_activities",
			permissions: "admin.category.index",
		},
		{
			path: "/categories_activities/add-category",
			permissions: "admin.category.store",
		},
		{
			path: "/categories_activities/edit-category/:id",
			permissions: "admin.category.update",
		},
		{
			path: "/orders",
			permissions: "admin.websiteorder.index",
		},
		{
			path: "/orders/show-details/:id",
			permissions: "admin.websiteorder.show",
		},
		{
			path: "/technical_support",
			permissions: "admin.technicalsupport.index",
		},
		{
			path: "/technical_support/show-details/:id",
			permissions: "admin.technicalsupport.show",
		},

		{
			path: "/contact_us",
			permissions: "admin.atlobhaContact.index",
		},
		{
			path: "/shipping_companies",
			permissions: "admin.shippingtype.index",
		},
		{
			path: "/payment_gates",
			permissions: "admin.paymenttype.index",
		},
		{
			path: "/basic_settings",
			permissions: "admin.setting.index",
		},
		{
			path: "/countries",
			permissions: "admin.country.index",
		},
		{
			path: "/countries/add-country",
			permissions: "admin.country.store",
		},
		{
			path: "/countries/edit-country",
			permissions: "admin.country.update",
		},
		{
			path: "/countries/show-country",
			permissions: "admin.country.show",
		},
		{
			path: "/cities",
			permissions: "admin.city.index",
		},
		{
			path: "/cities/add-city",
			permissions: "admin.city.store",
		},
		{
			path: "/cities/edit-city",
			permissions: "admin.city.update",
		},
		{
			path: "/cities/show-city",
			permissions: "admin.city.show",
		},
		{
			path: "/currencies",
			permissions: "admin.currency.index",
		},
		{
			path: "/currencies/add-currency",
			permissions: "admin.currency.store",
		},
		{
			path: "/emails",
			permissions: "admin.email.index",
		},
		{
			path: "/social_media",
			permissions: "admin.websitesocialmedia.index",
		},
		{
			path: "/social_media/add-social-media",
			permissions: "admin.websitesocialmedia.store",
		},
		{
			path: "/social_media/edit-social-media/:id",
			permissions: "admin.websitesocialmedia.update",
		},
		{
			path: "/registrations",
			permissions: "admin.registration.update",
		},
		{
			path: "/notifications",
			permissions: "admin.notification.index",
		},
		{
			path: "/seo_setting",
			permissions: "admin.seo.index",
		},
		{
			path: "/reviews",
			permissions: "admin.comment.index",
		},
		{
			path: "/faqs",
			permissions: "admin.commonquestion.index",
		},
		{
			path: "/faqs/add-question",
			permissions: "admin.commonquestion.store",
		},
		{
			path: "/faqs/edit-question/:id",
			permissions: "admin.commonquestion.update",
		},
	];

	const currentRoute = routes?.find((route) => {
		return route?.path || route?.path.contains(":id") === location?.pathname;
	});

	const requiresPermission = currentRoute && currentRoute?.permissions;
	const hasRequiredPermission =
		localStorage.getItem("permissions")?.includes(requiresPermission) ||
		permissions?.includes(requiresPermission);

	//  to handle show / hidden souq atlbha orders details page
	const showAndHiddenOrdersDetailsPageContext = useContext(Context);
	const { showNewOrderInfo } = showAndHiddenOrdersDetailsPageContext;

	if (isLoading) {
		return <LoadingPage />;
	}

	return token ? (
		<>
			<Navbar
				openSidebar={() => {
					setOpenSidebar(!openSidebar);
				}}
			/>

			{/* This is Toast Library to Handle errors modal in dashboard*/}
			<ToastContainer
				rtl
				draggable
				closeOnClick
				pauseOnHover
				autoClose={5000}
				pauseOnFocusLoss
				position='top-left'
				newestOnTop={false}
				hideProgressBar={false}
				style={{ fontSize: "14px", color: "#000" }}
			/>

			{title && <ActionCompleteComp></ActionCompleteComp>}
			{loadingTitle && <SendingLoadingComp></SendingLoadingComp>}

			{/* Dashboard content */}
			<div className='flex mx-auto mt-20'>
				<SideBar
					openSidebar={openSidebar}
					closeSidebar={() => setOpenSidebar(!openSidebar)}
					closeSidebarOnly={() => setOpenSidebar(false)}
				/>
				<div className='flex-1 p-4 app-page'>
					{!showNewOrderInfo && (
						<div className='md:hidden flex relative h-12 max-w-[90%] mx-auto mb-4 mt-2'>
							<SearchInput />
						</div>
					)}

					{hasRequiredPermission ? (
						<Outlet />
					) : (
						<Navigate
							state={{ from: location }}
							replace
							to={hasRequiredPermission ? currentRoute : "/"}
						/>
					)}
				</div>
			</div>
		</>
	) : (
		<Navigate state={{ from: location }} replace to='/login' />
	);
}

export default PrivateRoutes;
