import React from "react";
import styles from "./Button.module.css";

const Button = ({
	type,
	color,
	fontSize,
	fontWeight,
	disabled = false,
	img,
	svg,
	children,
	onClick,
	className,
	style,
	textStyle,
	fontColor = "#1948a0",
	btnType,
}) => {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={`${styles["btn"]} ${styles[type]} ${className} duration-100`}
			style={style}
			type={btnType}>
			{svg && svg}
			{img && (
				<img className={`${styles[color]}`} src={img} alt='' loading='lazy' />
			)}
			<h2
				className={`font-medium ${fontSize} ${fontWeight} ${fontColor}`}
				style={textStyle}>
				{children}
			</h2>
		</button>
	);
};

export default Button;
