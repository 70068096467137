import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
/* Third party */
/* Contexts */
import Context from "../../../../store/context";
import { NotificationContext } from "../../../../store/NotificationProvider";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

/* Icons */
import {
	EditButton,
	Delete,
	SortIcon,
	SwitchOn,
	CheckedSquare,
} from "../../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import NewPagination from "../../../PaginationComp/NewPagination";
import {
	useCategoryChangeStatusAllByParamsMutation,
	useCategoryChangeStatusAllMutation,
	useCategoryDeleteAllByParamsMutation,
	useCategoryDeleteItemMutation,
} from "../../../../RTK/apiSlices/categoriesApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "active",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "الأنشطة الفرعية	",
	},
	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "الأنشطة",
	},
	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "الرمز",
	},
	{
		id: "number",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead sx={{ backgroundColor: "#feb17e" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='text-lg font-medium'
						key={index}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#FFF",
							whiteSpace: "nowrap",
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon style={{ marginRight: "8px" }} />;
								}}>
								{headCell.label}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const { numSelected, rowCount, onSelectAllClick, itemsSelected } = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;
	const { permissions } = RolePermissionsStore;

	return (
		<Toolbar
			className='gap-4 md:gap-8'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				justifyContent: "flex-end",
			}}>
			<div className='flex flex-col items-center gap-2 py-1 md:flex-row md:py-1'>
				{permissions?.includes("admin.category.changesatusall") &&
					numSelected > 0 && (
						<div
							className='gap-2 px-2 rounded-full fcc'
							style={{
								width: "114px",
								backgroundColor: "rgba(255, 159, 26, 0.04)",
							}}
							onClick={() => {
								setNotificationTitle(
									"سيتم تغيير حالة جميع الأنشطة الفرعيةالتي قمت بتحديدهم"
								);
								setItems(itemsSelected);
								setActionType("ChangeStatus");
							}}>
							<h2 className={"font-semibold"} style={{ color: "#FF9F1A" }}>
								تعطيل
							</h2>
							<Box
								sx={{
									"& #Path_820": {
										fill: "#FF9F1A",
									},
								}}>
								<SwitchOn
									style={{
										cursor: "pointer",
										color: "red",
										fontSize: "0.5rem",
									}}
									className={"w-5"}
								/>
							</Box>
						</div>
					)}
				{permissions?.includes("admin.category.deleteall") &&
					numSelected > 0 && (
						<div
							className='rounded-full fcc'
							style={{
								width: "130px",
								backgroundColor: "rgba(255, 56, 56, 0.1)",
								cursor: "pointer",
							}}
							onClick={() => {
								setNotificationTitle(
									"سيتم حذف جميع الأنشطة الفرعيةالتي قمت بتحديدهم"
								);
								setItems(itemsSelected);
								setActionType("deleteAll");
							}}>
							<h2 className={"font-medium"} style={{ color: "#FF3838" }}>
								حذف الكل
							</h2>
							<IconButton>
								<Delete
									style={{
										cursor: "pointer",
										color: "red",
										fontSize: "1rem",
									}}></Delete>
							</IconButton>
						</div>
					)}
			</div>

			<div className='flex items-center'>
				<h2 className='font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0] whitespace-nowrap'>
					تحديد الكل
				</h2>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						pr: "0",
						color: "#1948a0",
						"& .MuiSvgIcon-root": {
							color: "#1948a0",
						},
					}}
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{
						"aria-label": "select all desserts",
					}}
				/>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	isCategory,
}) {
	const navigate = useNavigate();
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const [categoryChangeStatusAll] = useCategoryChangeStatusAllMutation();
	const [categoryDeleteItem] = useCategoryDeleteItemMutation();

	const [categoryDeleteAllByParams] = useCategoryDeleteAllByParamsMutation();
	const [categoryChangeStatusAllByParams] =
		useCategoryChangeStatusAllByParamsMutation();
	const changeCategoryStatus = async (id) => {
		try {
			await categoryChangeStatusAll({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const categoryDeleteAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await categoryDeleteAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};
	const categoryChangeStatusAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await categoryChangeStatusAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const handleDeleteSingleItem = async (id) => {
		try {
			await categoryDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData?.length) : 0;

	return (
		<Box sx={{ width: "100%", mb: 2, pb: 2 }}>
			<Paper
				sx={{
					width: "100%",

					backgroundColor: "transparent",
					boxShadow: "none",
				}}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={fetchedData?.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer sx={{ backgroundColor: "#fff" }}>
					<Table
						sx={{ minWidth: 750, backgroundColor: "#fff" }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							onSelectAllClick={handleSelectAllClick}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{fetchedData?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										fetchedData?.map((row, index) => {
											const isItemSelected = isSelected(row?.id);
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													hover
													role='checkbox'
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={index}
													selected={isItemSelected}>
													<TableCell component='th' id={labelId} scope='row'>
														<div className='flex items-center gap-1'>
															{permissions?.includes(
																"admin.category.deleteall"
															) && (
																<Delete
																	title='حذف'
																	onClick={() => {
																		setActionDelete(
																			"سيتم حذف الأنشطة وهذة الخطوة غير قابلة للرجوع"
																		);
																		setItemId(row?.id);
																	}}
																	style={{
																		cursor: "pointer",
																		color: "red",
																		fontSize: "1.2rem",
																	}}></Delete>
															)}
															{permissions?.includes(
																"admin.category.update"
															) && (
																<EditButton
																	title='تعديل'
																	className='w-6 h-6 cursor-pointer'
																	onClick={() => {
																		navigate(
																			`/categories_activities/edit-category/${row?.id}`
																		);
																	}}
																	width={"20px"}></EditButton>
															)}
														</div>
													</TableCell>
													<TableCell align='right'>
														<div>
															<Switch
																onChange={() => {
																	if (
																		permissions?.includes(
																			"admin.category.changesatusall"
																		)
																	) {
																		changeCategoryStatus(row?.id);
																	}
																}}
																checked={row?.status === "نشط" ? true : false}
																sx={{
																	width: "50px",
																	"& .MuiSwitch-thumb": {
																		width: "11px",
																		height: "11px",
																	},
																	"& .MuiSwitch-switchBase": {
																		padding: "6px",
																		top: "9px",
																		left: "9px",
																	},
																	"& .MuiSwitch-switchBase.Mui-checked": {
																		left: "-1px",
																	},
																	"& .Mui-checked .MuiSwitch-thumb": {
																		backgroundColor: "#EEF4FC",
																	},
																	"& .MuiSwitch-track": {
																		height: "16px",
																		borderRadius: "20px",
																	},
																	"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track":
																		{
																			backgroundColor: "#3AE374",

																			opacity: 1,
																		},
																}}
															/>
														</div>
													</TableCell>

													<TableCell align='right'>
														<div
															dir={"rtl"}
															className='flex items-center gap-1'>
															{row?.subcategory?.length === 2
																? row?.subcategory?.map((tag, index) => {
																		return (
																			<div
																				key={index}
																				className='flex items-center justify-center px-2 py-1 text-center rounded-full max-w-1/2'
																				style={{
																					backgroundColor: "#EEF4FC",
																					color: "#1948a0",
																				}}>
																				<span className='text-[14px] font-medium whitespace-nowrap text-ellipsis w-full overflow-hidden '>
																					{tag?.name}
																				</span>
																			</div>
																		);
																  })
																: row?.subcategory
																		.slice(0, 4)
																		?.map((tag, index) => {
																			return (
																				<div
																					key={index}
																					className='rounded-full flex py-1 items-center justify-center text-center px-2 max-w-[180px]'
																					style={{
																						backgroundColor: "#EEF4FC",
																						color: "#1948a0",
																					}}>
																					<span className='text-[14px] font-medium whitespace-nowrap text-ellipsis w-full overflow-hidden '>
																						{tag?.name}
																					</span>
																				</div>
																			);
																		})}

															{row?.subcategory.length > 4 && (
																<div
																	onClick={() => {
																		if (
																			permissions?.includes(
																				"admin.category.update"
																			)
																		) {
																			navigate(
																				`/categories_activities/edit-category/${row?.id}`
																			);
																		}
																	}}
																	className='flex items-center justify-center px-1 text-xl font-semibold cursor-pointer rounded-2xl'
																	style={{
																		backgroundColor: "#EEF4FC",
																		color: "#1948a0",
																	}}>
																	...
																</div>
															)}
														</div>
													</TableCell>

													<TableCell align='right'>
														<h2 className='inline text-lg font-normal'>
															{row?.name}
														</h2>
													</TableCell>
													<TableCell align='right'>
														<div className='flex flex-col items-end'>
															<div className='img_icons'>
																<img
																	src={row?.icon ? row?.icon : PlaceholderImage}
																	style={{
																		width: "100%",
																		height: "100%",
																		objectFit: "cover",
																		borderRadius: "50%",
																	}}
																	alt={row?.name}
																	loading='lazy'
																/>
															</div>
														</div>
													</TableCell>
													<TableCell align='right'>
														<h2
															className='text-lg font-normal'
															style={{ color: "#011723" }}>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</h2>
													</TableCell>
													<TableCell padding='none' align={"right"}>
														<Checkbox
															sx={{
																color: "#4875c2",
																"& .MuiSvgIcon-root": {
																	color: "#1948a0",
																},
															}}
															checked={isItemSelected}
															onClick={(event) => handleClick(event, row?.id)}
															inputProps={{
																"aria-labelledby": labelId,
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{isCategory ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}

			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}

			{notificationTitle && (
				<DeleteModalComp
					handleDeleteAllItems={categoryDeleteAllByParamsHandel}
					handleChangeAllItemsStatus={categoryChangeStatusAllByParamsHandel}
				/>
			)}
		</Box>
	);
}
