import React, { useState, useEffect, Fragment } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useGetProductDataQuery } from "../../../../RTK/apiSlices/productsApi";
const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const ProductDetails = ({ cancel, details }) => {
	const { data: fetchedData } = useGetProductDataQuery({ id: details });
	const [imageUrl, setImageUrl] = useState("");
	useEffect(() => {
		setImageUrl(fetchedData?.products?.cover);
	}, [fetchedData?.products?.cover]);
	return (
		<Fragment>
			<BackDrop />
			<div
				className='fixed trader_alert   flex flex-col top-[55%] md:max-h-[600px] max-h-[550px] translate-x-2/4 -translate-y-2/4 right-2/4 z-40 rounded-2xl overflow-hidden'
				style={{ width: "51.25rem", maxWidth: "90%" }}>
				<div className='flex items-center justify-between w-full h-16 px-4 py-4'>
					<h2
						style={{ color: "#ECFEFF" }}
						className='md:text-[22px] text-[18px] font-medium text-center flex-1'>
						{fetchedData?.products?.name}
					</h2>

					<IoMdCloseCircleOutline
						width='20px'
						height='20px'
						size={"1.25rem"}
						color={"#fff"}
						className={"cursor-pointer"}
						onClick={cancel}
					/>
				</div>
				<div className='flex-1 bg-white md:px-[98px] px-4 md:pt-[72px] pt-[30px] pb-[46px] overflow-y-auto'>
					<div className='flex md:flex-row flex-col items-center gap-[18px]'>
						<div
							className='flex flex-col items-center justify-center'
							style={{
								width: "180px",
								height: "226px",
								border: "1px solid #EEEEEE",
							}}>
							<img
								className='w-full'
								src={imageUrl}
								alt='main-img'
								loading='lazy'
							/>
						</div>
						<div className='flex flex-row gap-4 md:flex-col'>
							{fetchedData?.products?.images?.map((item) => (
								<div
									key={item?.id}
									className='flex flex-col items-center justify-center'
									style={{
										width: "80px",
										height: "65px",
										border: "1px solid #EEEEEE",
									}}>
									<img
										className='w-full cursor-pointer'
										src={item?.image}
										alt='small-img'
										onClick={() => setImageUrl(item?.image)}
										loading='lazy'
									/>
								</div>
							))}
						</div>
					</div>
					<div className='flex flex-col gap-[10px] mt-[38px]'>
						<h5
							className='md:text-[20px] text-[18px]'
							style={{ color: "#011723", fontWeight: "500" }}>
							وصف المنتج
						</h5>
						<div
							style={{
								padding: "14px 20px 14px 14px ",
								border: "1px solid #EEEEEE",
							}}>
							<p
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								{fetchedData?.products?.description}
							</p>
						</div>
					</div>
					<div className='flex flex-col gap-[17px] mt-[38px]'>
						<span
							className='md:h-[50px] h-[38px] flex flex-col items-center justify-center'
							style={{
								fontSize: "16px",
								fontWeight: "400",
								color: "#1948a0",
								width: "180px",
								padding: "11px 45px",
								backgroundColor: "#dfdce51A",
								borderRadius: "25px",
								whiteSpace: "nowrap",
							}}>
							{fetchedData?.products?.category?.name}
						</span>
						<div className='flex flex-row items-center gap-4'>
							{fetchedData?.products?.subcategory?.map((item, index) => (
								<span
									key={index}
									className='md:h-[50px] h-[38px] flex flex-col items-center justify-center'
									style={{
										fontSize: "16px",
										fontWeight: "400",
										color: "#1948a0",
										padding: "10px 20px",
										backgroundColor: "#eae8ee1A",
										borderRadius: "25px",
										whiteSpace: "nowrap",
									}}>
									{item?.name}
								</span>
							))}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ProductDetails;
