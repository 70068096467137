import React from "react";
/* Components */
import DatePicker from "./DatePicker/DatePicker";
import GraphSec from "./GraphSec/GraphSec";

const OrdersGraph = ({
	loading,
	Total_orders,
	count_orders_of_Etlobha,
	getStartDate2,
	getEndDate2,
}) => {
	return (
		<div className='shadow-[0px_3px_6px_#0000001A] lg:h-96 md:mt-16 mt-5 md:py-6 md:pl-12 md:pr-4 p-4 rounded-lg md:bg-[#FFFFFF] bg-[#FAFAFA]'>
			<div className='flex flex-col justify-between w-full md:flex-row'>
				<div className='flex md:flex-col flex-row gap-x-[105px] xl:pr-16 pr-4 mb-2'>
					<h2 className='font-medium md:text-[22px] text-[16px] text-[#1948a0]'>
						إحصائيات الطلبات
					</h2>
					<h2 className='font-bold md:text-[22px] text-[18px] text-[#fc8d5d]'>
						{loading ? 0 : Total_orders || "...	"}
					</h2>
				</div>
				<DatePicker getStartDate2={getStartDate2} getEndDate2={getEndDate2} />
			</div>
			<GraphSec count_orders_of_Etlobha={count_orders_of_Etlobha} />
		</div>
	);
};

export default OrdersGraph;
