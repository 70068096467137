import * as React from "react";
import PropTypes from "prop-types";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import DeleteIcon from "@mui/icons-material/Delete";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineArrowBackIosNew,
	MdOutlineArrowForwardIos,
} from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { Gift, SortIcon } from "../../../assets/Icons/index";

function createData(name, activity, opened) {
	return {
		name,
		activity,
		opened,
	};
}

const rows = [
	createData("أمازون", "هدايا وألعاب", true),
	createData("صحتى", "مستلزمات طبية", false),
	createData("تسعة", "الكترونيات", false),
	createData("ماركت14", "هدايا وألعاب", true),
	createData("ماركت13", "مستلزمات طبية", false),
	createData("ماركت12", "الكترونيات", false),
	createData("ماركت11", "هدايا وألعاب", true),
	createData("ماركت10", "مستلزمات طبية", false),
	createData("ماركت9", "الكترونيات", false),
	createData("ماركت6", "هدايا وألعاب", true),
	createData("ماركت5", "مستلزمات طبية", false),
	createData("ماركت4", "الكترونيات", false),
	createData("7ماركت", "هدايا وألعاب", true),
	createData("ماركت3", "مستلزمات طبية", false),
	createData("ماركت2", "الكترونيات", false),
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "opened",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
	},
	{
		id: "zed",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
	},
	{
		id: "activity",
		numeric: true,
		disablePadding: false,
		label: "الأنشطة",
		sort: true,
	},
	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "اسم المتجر",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#feb17e" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{ color: "#1948a0" }}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
	const { numSelected, onClick, rowCount, onSelectAllClick } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				justifyContent: "space-between",
			}}>
			<div className='flex items-center gap-2'>
				<div></div>
				{numSelected > 0 && (
					<Tooltip onClick={onClick} title='Delete'>
						<IconButton>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}

				{numSelected > 0 && (
					<Typography
						sx={{}}
						color='inherit'
						variant='subtitle1'
						component='div'>
						{numSelected} selected
					</Typography>
				)}
			</div>

			<div className='flex items-center'>
				<h2 className='font-medium'>تحديد الكل</h2>
				<Checkbox
					sx={{
						color: "#4875c2",
						"& .MuiSvgIcon-root": {
							color: "#1948a0",
						},
					}}
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{
						"aria-label": "select all desserts",
					}}
				/>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [data, setData] = React.useState(rows);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const rowsPerPagesCount = [10, 20, 30, 50, 100];
	const handleRowsClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = data?.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	function deleteItems() {
		const array = [...data];
		selected.forEach((item, idx) => {
			const findIndex = array.findIndex((i) => item === i.name);
			array.splice(findIndex, 1);
		});
		setData(array);
		setSelected([]);
	}

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

	const allRows = () => {
		const num = Math.ceil(data.length / rowsPerPage);
		const arr = [];
		for (let index = 0; index < num; index++) {
			arr.push(index + 1);
		}
		return arr;
	};
	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					onClick={deleteItems}
					numSelected={selected.length || 0}
					rowCount={data.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={data.length || 0}
						/>
						<TableBody>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
							{stableSort(data, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								?.map((row, index) => {
									const isItemSelected = isSelected(row.name);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											//   onClick={(event) => handleClick(event, row.name)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={index}
											selected={isItemSelected}>
											<TableCell component='th' id={labelId} scope='row'>
												<div className='flex items-center gap-2'>
													<BsTrash
														onClick={() => {
															const findIndex = data.findIndex(
																(item) => item.name === row.name
															);
															const arr = [...data];
															arr.splice(findIndex, 1);
															setData(arr);
														}}
														style={{
															cursor: "pointer",
															color: "red",
															fontSize: "1rem",
														}}></BsTrash>
													<Switch
														onChange={() => {
															const findIndex = data.findIndex(
																(item) => item.name === row.name
															);
															const arr = [...data];
															arr[findIndex].opened = !arr[findIndex].opened;
															setData(arr);
														}}
														className=''
														sx={{
															"& .Mui-checked .MuiSwitch-thumb": {
																backgroundColor: "#3AE374",
															},
															"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track":
																{
																	backgroundColor: "#3AE374",
																},
														}}
														checked={row.opened}
													/>
												</div>
											</TableCell>
											<TableCell align='center'>
												<div
													className='w-20 h-full py-[2px] rounded-2xl'
													style={{
														backgroundColor: row.opened ? "#3AE374" : "#D3D3D3",
														marginLeft: "auto",
													}}>
													<h2>{row.opened ? "نشط" : "غير نشط"}</h2>
												</div>
											</TableCell>
											<TableCell
												align='right'
												sx={{ display: "flex", gap: "0.5rem", p: "24px 0" }}>
												<Gift />
												<h2 className='font-medium'>{row.activity}</h2>
											</TableCell>
											<TableCell align='right'>
												<h2 className='inline'>{row.name}</h2>
												<Checkbox
													sx={{
														color: "#4875c2",
														"& .MuiSvgIcon-root": {
															color: "#ADB5B9",
														},
													}}
													checked={isItemSelected}
													onClick={(event) => handleClick(event, row.name)}
													inputProps={{
														"aria-labelledby": labelId,
													}}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			<div className='flex items-center justify-between'>
				<div
					className='flex items-center gap-2 p-2 rounded-md'
					style={{ border: "1px solid #2D62ED" }}>
					<div
						id='basic-button'
						aria-controls={open ? "basic-menu" : undefined}
						aria-haspopup='true'
						aria-expanded={open ? "true" : undefined}
						onClick={handleRowsClick}
						className={
							"h-9 w-9 rounded-sm flex justify-center items-center cursor-pointer"
						}
						style={{ backgroundColor: "#0099FB" }}>
						<MdOutlineKeyboardArrowDown
							color='#fff'
							fontSize={"1.5rem"}></MdOutlineKeyboardArrowDown>
					</div>
					<Menu
						id='basic-menu'
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}>
						{rowsPerPagesCount?.map((rowsPer, rowsIdx) => {
							return (
								<MenuItem
									value={rowsPer}
									onClick={(e) => {
										handleChangeRowsPerPage(e);
										handleClose();
									}}
									key={rowsIdx}
									sx={{
										backgroundColor: "#FFEEEE",
										"ul:has(&)": {
											p: 0,
										},
										"ul:has(&) li:hover": {
											backgroundColor: "#C6E1F0",
										},
									}}>
									{rowsPer}
								</MenuItem>
							);
						})}
					</Menu>
					<h2 className='font-medium' style={{ color: "#0077FF" }}>
						عدد الصفوف
					</h2>
				</div>
				<div className='flex items-center gap-6'>
					<MdOutlineArrowBackIosNew
						className='cursor-pointer'
						style={{ visibility: page === 0 && "hidden" }}
						onClick={() => {
							setPage(page - 1);
						}}></MdOutlineArrowBackIosNew>

					<div className='flex gap-4'>
						{allRows()?.map((item, itemIdx) => {
							return (
								<div
									className='flex items-center justify-center w-6 h-6 font-medium rounded-lg cursor-pointer'
									style={{
										backgroundColor: item === page + 1 && "#508FF4",
										color: item === page + 1 && "#fff",
									}}
									onClick={() => {
										setPage(itemIdx);
									}}>
									{item}
								</div>
							);
						})}
					</div>
					<MdOutlineArrowForwardIos
						className='cursor-pointer'
						style={{ visibility: page + 1 === allRows().length && "hidden" }}
						onClick={() => {
							setPage(page + 1);
						}}></MdOutlineArrowForwardIos>
				</div>
				<div></div>
			</div>
		</Box>
	);
}
