import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const verificationApi = createApi({
	reducerPath: "verificationApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),
	tagTypes: ["Verification"],

	endpoints: (builder) => ({
		// Get Verification
		getVerification: builder.query({
			query: (args) => ({
				url: `verification?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Verification"],
		}),

		// Get Verification Data
		getVerificationData: builder.query({
			query: ({ verificationId }) => ({
				url: `verification/${verificationId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Verification", id }],
		}),

		// Search In Verification
		searchInVerification: builder.mutation({
			query: (arg) => ({
				url: `searchVerificationStoreName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		//Add To Store  Note
		addStoreNote: builder.mutation({
			query: (data) => {
				return {
					url: `addStoreNote`,
					method: "POST",
					data: data,
				};
			},
		}),

		//Delete All
		verificationDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `verificationdeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Verification"],
		}),

		acceptVerificationById: builder.mutation({
			query: ({ verificationId }) => ({
				url: `acceptVerification/${verificationId}`,
				method: "GET",
			}),
			invalidatesTags: ["Verification"],
		}),

		rejectVerificationById: builder.mutation({
			query: ({ verificationId }) => ({
				url: `rejectVerification/${verificationId}`,
				method: "GET",
			}),
			invalidatesTags: ["Verification"],
		}),

		// Verification Update
		verificationUpdate: builder.mutation({
			query: ({ body }) => {
				return {
					url: `verification_update`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Verification"],
		}),

		verificationDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `verificationdeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Verification"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetVerificationQuery,
	useGetVerificationDataQuery,
	useSearchInVerificationMutation,
	useAddStoreNoteMutation,
	useVerificationDeleteAllMutation,
	useAcceptVerificationByIdMutation,
	useRejectVerificationByIdMutation,
	useVerificationUpdateMutation,
	useVerificationDeleteItemMutation,
} = verificationApi;
