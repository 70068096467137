import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoIosArrowDown } from "react-icons/io";
import Button from "../../../UI/Button/Button";

const VarietiesFilterForm = ({
	category,
	setCategory,
	fetchedData,
	onClickFilter,
	subcategory,
	subCategory,
	setSubCategory,
}) => {
	return (
		<div
			className={`flex lg:flex-row flex-col lg:gap-10 gap-4 lg:pb-4 lg:pt-4 lg:pr-2 lg:pl-6 px-2 py-3 mt-4  bg-[#EEF3FC]  rounded-lg otlobha_filtering_sec`}>
			<div className='flex flex-row flex-wrap flex-1 gap-3 md:gap-8'>
				<FormControl sx={{ minWidth: 200, flex: "1" }}>
					<h2 className='mb-2 text-base font-medium'>النشاط الرئيسي</h2>
					<Select
						className='text-xl font-medium rounded-lg'
						value={category}
						IconComponent={() => {
							return (
								<IoIosArrowDown
									size={"1rem"}
									className='absolute left-2'
									fill='#f75e22'
								/>
							);
						}}
						onChange={(e) => {
							if (category !== e.target.value) {
								setSubCategory([]);
							}
							setCategory(e.target.value);
						}}
						displayEmpty
						inputProps={{ "aria-label": "Without label" }}
						renderValue={(selected) => {
							if (category === "") {
								return <h2>الكل</h2>;
							}
							const result =
								fetchedData?.categories?.filter(
									(item) => item?.id === parseInt(selected)
								) || "";
							return result[0]?.name;
						}}
						sx={{
							height: "3.5rem",
							border: "1px solid #f75e22",
							"& .MuiOutlinedInput-notchedOutline": {
								border: "none",
							},
						}}>
						<MenuItem
							className='souq_storge_category_filter_items'
							sx={{
								backgroundColor: "#fff",
								height: "3rem",
								"&:hover": {},
							}}
							value={""}>
							الكل
						</MenuItem>
						{fetchedData?.categories?.map((category, idx) => {
							return (
								<MenuItem
									key={idx}
									className='souq_storge_category_filter_items'
									sx={{
										backgroundColor: "#fff",
										height: "3rem",
										"&:hover": {},
									}}
									value={category?.id}>
									{category?.name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<FormControl sx={{ minWidth: 200, flex: "1" }}>
					<h2 className='mb-2 text-base font-medium'>الأنشطة الفرعية</h2>
					<Select
						className={`font-medium text-xl rounded-lg`}
						labelId='demo-multiple-checkbox-label'
						id='demo-multiple-checkbox'
						multiple
						displayEmpty
						IconComponent={() => {
							return (
								<IoIosArrowDown
									size={"1rem"}
									className='absolute left-2'
									fill='#f75e22'
								/>
							);
						}}
						value={subCategory}
						onChange={(e) => {
							setSubCategory(e.target.value);
						}}
						renderValue={(selected) => {
							if (subCategory?.length === 0 || category === "") {
								return (
									<div className='text-[#1948a0]'> اختر الأنشطة الفرعية</div>
								);
							}
							return selected?.map((item) => {
								const result = subcategory[0]?.subcategory?.filter(
									(sub) => sub?.id === parseInt(item)
								);
								return `${result[0]?.name} , `;
							});
						}}
						sx={{
							height: "3.5rem",
							border: "1px solid #f75e22",
							borderRadius: "4px",
							"& .MuiOutlinedInput-notchedOutline": {
								border: "none",
							},
						}}>
						{subcategory[0]?.subcategory?.map((sub, index) => (
							<MenuItem
								className='souq_storge_category_filter_items multiple_select'
								key={index}
								value={sub?.id}>
								<Checkbox checked={subCategory?.indexOf(sub?.id) > -1} />
								<ListItemText primary={sub?.name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div className='flex items-end'>
				<Button
					onClick={() => onClickFilter()}
					className='flex-1 save_button text-lg font-normal md:w-44 w-full md:h-14 h-[45px]'
					type={"normal"}>
					تنفيذ الفرز
				</Button>
			</div>
		</div>
	);
};

export default VarietiesFilterForm;
