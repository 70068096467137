import React from "react";
/* MUI */
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { Filter } from "../../../assets/Icons";

const categoryList = ["الكل", "محظور", "تم النشر"];

const Filtering = ({
	getSearch,
	getSortSelector,
	search,
	setSearch,

	selected,
	setSelected,
}) => {
	return (
		<div className='flex flex-col gap-4 mt-4 xl:flex-row md:mt-10'>
			<label className={`flex-1 relative h-full`}>
				<input
					className='h-[45px] md:h-14 xl:w-[654px] w-full outline-0 pr-10 rounded-lg'
					placeholder='ابحث عن صفحة'
					type='text'
					name='name'
					value={search}
					onChange={(e) => {
						setSearch(e.target.value);
						getSearch(e.target.value);
					}}
					style={{
						border: "1px solid #86B1EA",
					}}
				/>
				<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
					<AiOutlineSearch color='#1948a0' size={"18px"}></AiOutlineSearch>
				</div>
			</label>
			<Select
				value={selected}
				IconComponent={() => {
					return (
						<IoIosArrowDown
							className='duration-200 select_arrow'
							fill={"#1948a0"}
							size={"1.2rem"}
						/>
					);
				}}
				onChange={(e) => {
					setSelected(e.target.value);
					getSortSelector(e.target.value);
				}}
				displayEmpty
				inputProps={{ "aria-label": "Without label" }}
				renderValue={(selected) => {
					if (selected === "") {
						return (
							<h2
								style={{ color: "#1948a0" }}
								className='flex items-center gap-2'>
								<Filter /> فرز حسب الحالة
							</h2>
						);
					}
					return (
						<h2
							style={{ color: "#1948a0" }}
							className='flex items-center gap-2'>
							<Filter /> {selected}
						</h2>
					);
				}}
				className={"md:h-[3.5rem] h-[45px] rounded-lg"}
				sx={{
					backgroundColor: "#EEF4FC",
					width: "100%",
					pl: "1rem",
					"& path": {
						fill: "#1948a0",
					},
					"&.Mui-focused .select_arrow": {
						transform: "rotate(180deg)",
					},
					"& .MuiOutlinedInput-notchedOutline": {
						border: "1px solid #1948a0",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: "1px solid #1948a0",
					},
				}}>
				{categoryList?.map((item, idx) => {
					return (
						<MenuItem
							key={idx}
							className=''
							sx={{
								backgroundColor: "#EEF4FC",
								height: "3rem",
								color: "#1948a0",
								"&:hover": {},
								"ul:has(&)": {
									padding: "0",
									borderRadius: "8px",
								},
								"ul:has(&) li:hover": {
									backgroundColor: "#BAD2F3",
								},
							}}
							value={`${item}`}>
							{item}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
};

export default Filtering;
