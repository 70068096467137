import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const coursesApi = createApi({
	reducerPath: "coursesApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),
	tagTypes: ["Courses", "LiveCourses"],

	endpoints: (builder) => ({
		// Get Services Data
		getCourses: builder.query({
			query: (args) => ({
				url: `course?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Courses"],
		}),

		deleteCourse: builder.mutation({
			query: ({ id }) => ({
				url: `course/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Courses"],
		}),

		// Get Course Data
		getCourseData: builder.query({
			query: ({ id }) => ({
				url: `course/${id}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Courses", id }],
		}),

		updateCourse: builder.mutation({
			query: ({ id, body }) => {
				return {
					url: `course/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Courses"],
		}),

		addNewCourse: builder.mutation({
			query: ({ body }) => {
				return {
					url: `course`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Courses"],
		}),

		deleteVideoById: builder.mutation({
			query: ({ id }) => ({
				url: `deletevideo/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Courses"],
		}),

		deleteUnitById: builder.mutation({
			query: ({ id }) => ({
				url: `deleteunit/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Courses"],
		}),

		// Get Videos Data
		getExplainVideos: builder.query({
			query: (args) => ({
				url: `explainVideos?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Videos"],
		}),

		deleteExplainVideos: builder.mutation({
			query: ({ id }) => ({
				url: `explainVideos/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Videos"],
		}),

		addNewExplainVideos: builder.mutation({
			query: ({ body }) => {
				return {
					url: `explainVideos`,
					method: "POST",
					data: body,
				};
			},

			providesTags: ["Videos"],
		}),

		updateExplainVideos: builder.mutation({
			query: ({ lessonId, body }) => {
				return {
					url: `explainVideos/${lessonId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Videos"],
		}),

		getExplainVideoData: builder.query({
			query: ({ lessonId }) => ({
				url: `explainVideos/${lessonId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Videos", id }],
		}),

		// Live course
		getLiveCoursesData: builder.query({
			query: (args) => ({
				url: `live_course?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "LiveCourses", id }],
		}),

		addNewLiveCourse: builder.mutation({
			query: ({ body }) => {
				return {
					url: `live_course`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["LiveCourses"],
		}),

		showLiveCourse: builder.query({
			query: ({ id }) => ({
				url: `live_course/${id}`,
			}),

			transformResponse: (response) => response.data.course,
			providesTags: (result, error, id) => [{ type: "LiveCourses", id }],
		}),

		updateLiveCourse: builder.mutation({
			query: ({ id, body }) => {
				return {
					url: `live_course/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["LiveCourses"],
		}),

		deleteLiveCourse: builder.mutation({
			query: ({ id }) => ({
				url: `live_course/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["LiveCourses"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetCoursesQuery,
	useGetCourseDataQuery,
	useDeleteCourseMutation,
	useUpdateCourseMutation,
	useAddNewCourseMutation,
	useDeleteVideoByIdMutation,
	useDeleteUnitByIdMutation,
	useGetExplainVideosQuery,
	useGetExplainVideoDataQuery,
	useDeleteExplainVideosMutation,
	useAddNewExplainVideosMutation,
	useUpdateExplainVideosMutation,
	useGetLiveCoursesDataQuery,
	useAddNewLiveCourseMutation,
	useShowLiveCourseQuery,
	useUpdateLiveCourseMutation,
	useDeleteLiveCourseMutation,
} = coursesApi;
