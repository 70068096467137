import React from "react";
import styles from "./StorageStatus.module.css";
const StorageStatus = ({ fetchedData }) => {
	const INITIAL_DATA = [
		{
			id: 5,
			title: "اجمالي منتجات المخزون",
			amount: fetchedData?.total_stock || 0,
		},
		{
			id: 1,
			title: "منتجات منتهية",
			amount: fetchedData?.finished_products || 0,
		},
		{
			id: 2,
			title: "منتجات تنتهي قريباً",
			amount: fetchedData?.finished_soon || 0,
		},
		{
			id: 3,
			title: "أحدث المنتجات",
			amount: fetchedData?.last_week_product_added || 0,
		},
		{
			id: 4,
			title: "أكثر المنتجات طلباً",
			amount: fetchedData?.most_order || 0,
		},
	];

	return (
		<div className='flex flex-row flex-wrap items-center gap-4'>
			{INITIAL_DATA?.map((item) => {
				return (
					<div
						className='flex-1 flex bg-white flex-col justify-between md:h-[120px] h-[70px] md:w-48 min-w-[162px] w-full text-center md:py-4 py-2 px-2 shadow-[0px_3px_6px_#0000000F]'
						key={item.id}>
						<h2 className='font-normal md:text-lg text-[15px] whitespace-nowrap overflow-hidden w-full text-[#1948a0]'>
							{item.title}
						</h2>
						{item.icon && (
							<div className='flex items-center justify-center gap-4 '>
								<span
									className={`md:w-10 w-[30px] md:h-10 h-[30px] flex  justify-center items-center rounded-lg bg-[#FABB9E] ${styles.productIcon}`}>
									{item.icon}
								</span>
								<h2
									className='font-bold md:text-2xl text-[20px] whitespace-nowrap overflow-hidden w-full text-[#F79264]'
									style={{ textOverflow: "ellipsis" }}>
									{item.amount}
								</h2>
							</div>
						)}
						{!item.icon && (
							<h2
								className='font-bold md:text-2xl text-[20px] whitespace-nowrap overflow-hidden w-full text-[#F79264]'
								style={{ textOverflow: "ellipsis" }}>
								{item.amount}
							</h2>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default StorageStatus;
