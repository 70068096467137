import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const seoApi = createApi({
	reducerPath: "seoApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),
	tagTypes: ["SEO"],

	endpoints: (builder) => ({
		getSeoData: builder.query({
			query: () => ({ url: `seo` }),

			transformResponse: (response) => response.data,
			providesTags: ["SEO"],
		}),

		updateSeo: builder.mutation({
			query: ({ body }) => {
				return {
					url: `updateSeo`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["SEO"],
		}),
	}),
});

// Export endpoints and hooks
export const { useGetSeoDataQuery, useUpdateSeoMutation } = seoApi;
