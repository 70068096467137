import { MenuItem, Select } from "@mui/material";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";

// active status array
const activateStatus = [
	{ ar: "نشط", en: "active" },
	{ ar: "غير نشط", en: "not_active" },
];

const SelectPartitionStatus = ({
	currPartition,
	onChangeTab,
	selectStyles,
	selectItemStyles,
}) => {
	return (
		<div className='mb-5'>
			<h2 className='mb-1 text-lg font-normal' style={{ color: "#f75e22" }}>
				الحالة
			</h2>

			<Select
				displayEmpty
				value={currPartition?.status || ""}
				name='status'
				onChange={(e) =>
					onChangeTab({
						...currPartition,
						status: e.target.value,
					})
				}
				IconComponent={() => {
					return <IoIosArrowDown size={"1rem"} className='text-[#1948a0]' />;
				}}
				renderValue={(selected) => {
					if (!selected || selected === "") {
						return <h2 className='text-[#1948a0]'>الحالة</h2>;
					}
					const result = activateStatus?.find((item) => item?.ar === selected);

					return result?.ar || selected;
				}}
				className={"w-full px-3 h-14 rounded outline-none"}
				sx={selectStyles}>
				{activateStatus?.map((item, index) => {
					return (
						<MenuItem key={index} sx={selectItemStyles} value={item?.ar}>
							{item?.ar}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
};

export default SelectPartitionStatus;
