import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
import TableComp from "../../../components/MarketingPageComp/CouponsPageComp/TableComp/TableComp";
import Button from "../../../UI/Button/Button";
import { AiOutlinePlus } from "react-icons/ai";
import {
	useFilterCouponsMutation,
	useGetCouponsQuery,
} from "../../../RTK/apiSlices/couponsApi";
import CouponsFilterForm from "../../../components/MarketingPageComp/CouponsPageComp/CouponsFilterForm";

const CouponsPage = () => {
	const navigate = useNavigate();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetCouponsQuery({
		page,
		number: rowsPerPage,
	});
	const [showCoupons, setShowCoupons] = useState("");
	const [sortCoupons, setSortCoupons] = useState("");
	const [resultData, setResultData] = useState();
	const [data, setData] = useState();
	const [filterResults, setFilterResults] = useState([]);

	const [filterCoupons, { isLoading: filterIsLoading }] =
		useFilterCouponsMutation();
	const filterCouponsHandel = async () => {
		try {
			const response = await filterCoupons({
				status:
					showCoupons === "غير نشط"
						? "not_active"
						: showCoupons === "نشط"
						? "active"
						: showCoupons === "منتهي"
						? "expired"
						: null,
				code: sortCoupons ? "1" : null,
			});

			setFilterResults(response?.data?.data);

			const responseData = response.data?.data;
			if (responseData) {
				setData(responseData.coupons);
			}
		} catch (error) {
			console.error("Error filtering orders:", error);
		}
	};

	const onClickFilter = () => {
		if (showCoupons !== "" && sortCoupons !== "") {
			filterCouponsHandel();
		} else {
			setData(resultData);
		}
	};

	useEffect(() => {
		setResultData(fetchedData?.coupons);
		setData(resultData);
	}, [resultData, fetchedData?.coupons]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | الكوبونات</title>
			</Helmet>
			<div
				style={{ backgroundColor: "#EEF4FC" }}
				className='relative 2xl:pl-[134px] 2xl:pr-[56px] p-4'>
				<div className='flex flex-col items-start justify-between gap-4 md:flex-row md:items-center'>
					<h3
						style={{ fontSize: "22px", color: "#1948a0" }}
						className='font-bold '>
						جدول الكوبونات
					</h3>
					{permissions?.includes("admin.coupon.store") && (
						<Button
							type={"normal"}
							textStyle={{ color: "#fff", fontSize: "20px" }}
							className={
								"md:w-[216px] save_button w-full md:h-[56px] h-[45px] font-medium px-4"
							}
							svg={<AiOutlinePlus fill='#fff' className='w-5 h-5' />}
							onClick={() => {
								navigate("/coupons/add-coupon");
							}}>
							اضافة خصم
						</Button>
					)}
				</div>

				{/** filter */}
				<CouponsFilterForm
					showCoupons={showCoupons}
					setShowCoupons={setShowCoupons}
					sortCoupons={sortCoupons}
					setSortCoupons={setSortCoupons}
					onClickFilter={onClickFilter}
				/>

				{/**  coupon page table */}
				<div dir='ltr'>
					<TableComp
						fetchedData={data}
						loading={loading || filterIsLoading}
						pageNumber={page}
						setPageNumber={setPage}
						currentPage={rowsPerPage}
						setCurrentPage={setRowsPerPage}
						page_count={fetchedData?.page_count}
						showCoupons={showCoupons}
						sortCoupons={sortCoupons}
					/>
				</div>
			</div>
		</>
	);
};

export default CouponsPage;
