import React, { useState, useContext, useRef, useEffect } from "react";
import styles from "./AddNewPackagePlan.module.css";

/* Third party */
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { useForm, Controller } from "react-hook-form";

/* Context */
import { LoadingContext } from "../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

/* Icons */
import { IoMdCloudUpload } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { Arrow } from "../../../assets/Icons/index";

// import PackageTemplate from "../PackageTemplate/PackageTemplate";
import {
	useAdminSelectorPlansQuery,
	useSelectPackagesCoursesQuery,
	useSelectPackagesPeriodTypesQuery,
	useSelectPackagesTripsQuery,
} from "../../../RTK/apiSlices/selectorApi";
import { useAddPackageMutation } from "../../../RTK/apiSlices/subscriptionsApi";

import PageNavigate from "../../PageNavigate/PageNavigate";
const AddNewPackagePlan = () => {
	const navigate = useNavigate();

	const handleClosePage = () => {
		navigate("/packages");
	};

	// handle select
	const { data: planList } = useAdminSelectorPlansQuery();
	// const { data: coursesList } = useSelectPackagesCoursesQuery();
	// const { data: tripsList } = useSelectPackagesTripsQuery();
	const { data: packagesPeriodList } = useSelectPackagesPeriodTypesQuery();

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	// const [chooseTemplate, setChooseTemplate] = useState(false);
	// const [selectedTemplate, setSelectedTemplate] = useState([]);
	// const getSelectedTemplate = (t) => {
	// 	setSelectedTemplate(t);
	// };
	const ref = useRef(null);
	const [datapackage, setDataPackage] = useState({
		plan: [],
		// template: [],
		// course: [],
		// trip_id: "",
		periodtype: "",
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			yearly_price: "",
			discount: "",
		},
	});
	const [DataError, setDataError] = useState({
		name: "",
		// image: "",
		yearly_price: "",
		discount: "",
		plan: "",
		// template: "",
		course: "",
		// trip_id: "",
		// periodtype: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			// image: "",
			yearly_price: "",
			discount: "",
			plan: "",
			// template: "",
			course: "",
			// trip_id: "",
			// periodtype: "",
		});
	};

	// upload course image
	// const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	// const [images, setImages] = useState([]);

	// // handle upload image
	// const onChange = (imageList, addUpdateIndex) => {
	// 	// Check image size before updating state
	// 	const isSizeValid = imageList.every(
	// 		(image) => image.file.size <= maxFileSize
	// 	);

	// 	if (!isSizeValid) {
	// 		setDataError({
	// 			...DataError,
	// 			image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
	// 		});
	// 		toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
	// 			theme: "light",
	// 		});
	// 	} else {
	// 		setImages(imageList);
	// 		setDataError({ ...DataError, image: null });
	// 	}
	// };

	// handle add packages
	const [addPackage] = useAddPackageMutation();
	const addPackageHandel = async (formData) => {
		try {
			const res = await addPackage({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/packages");
			} else {
				setLoadingTitle(null);

				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					// image: res?.data?.message?.en?.image?.[0],
					yearly_price: res?.data?.message?.en?.yearly_price?.[0],
					discount: res?.data?.message?.en?.discount?.[0],
					plan: res?.data?.message?.en?.plan?.[0],
					// template: res?.data?.message?.en?.template?.[0],
					// course: res?.data?.message?.en?.course?.[0],
					periodtype: res?.data?.message?.en?.periodtype?.[0],
					// trip_id: res?.data?.message?.en?.trip_id?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });

				toast.error(res?.data?.message?.en?.yearly_price?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.discount?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.plan?.[0], { theme: "light" });
				// toast.error(res?.data?.message?.en?.template?.[0], {
				// 	theme: "light",
				// });
				// toast.error(res?.data?.message?.en?.image?.[0], {
				// 	theme: "light",
				// });
				toast.error(res?.data?.message?.en?.periodtype?.[0], {
					theme: "light",
				});
				// toast.error(res?.data?.message?.en?.course?.[0], {
				// 	theme: "light",
				// });
				// toast.error(res?.data?.message?.en?.trip_id?.[0], {
				// 	theme: "light",
				// });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handlePackagePlan = (data) => {
		resetData();
		const formData = new FormData();
		formData.append("name", data?.name);
		formData.append("periodtype", datapackage?.periodtype);
		datapackage?.plan?.forEach((plan) => {
			formData.append("plan[]", plan);
		});
		// datapackage?.course?.forEach((item) => {
		// 	formData.append("course[]", item);
		// });

		// if (selectedTemplate?.length > 0) {
		// 	selectedTemplate?.forEach((item) => {
		// 		formData.append("template[]", item);
		// 	});
		// } else {
		// 	datapackage?.template?.forEach((item) => {
		// 		formData.append("template[]", item);
		// 	});
		// }
		// formData.append("trip_id", datapackage?.trip_id);
		formData.append("discount", data?.discount);
		formData.append("yearly_price", data?.yearly_price);

		// if (images.length !== 0) {
		// 	formData.append("image", images[0]?.file || "");
		// }

		setLoadingTitle("جاري حفظ الباقة");
		addPackageHandel(formData);
	};

	const scrollToElement = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToElement();
	}, []);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | اضافة باقة</title>
			</Helmet>
			{/*{chooseTemplate && (
				<PackageTemplate
					getSelectedTemplate={getSelectedTemplate}
					cancel={() => {
						setChooseTemplate(false);
					}}
				/>
			)}*/}
			<div
				ref={ref}
				className='absolute bg-[#fafafa] lg:pb-20 lg:pl-[102px] lg:pr-4 p-4 pt-4 top-0 right-0  z-10 w-full h-auto'>
				<div className='flex items-center justify-between px-4 pt-6 mb-2 md:p-0 md:pt-0'>
					<PageNavigate
						currentPage={"اضافة باقة جديدة"}
						route={"packages"}
						parentPage={"الباقات"}
					/>
				</div>
				<form onSubmit={handleSubmit(handlePackagePlan)} className='h-full'>
					<div className='flex flex-col gap-[10px]'>
						<div
							className='flex flex-col gap-[28px] bg-white mt-6 p-8 rounded-lg'
							style={{ boxShadow: "0px 3px 6px #eae8ee0F" }}>
							<h2 className='md:text-[20px] text-[18px] font-medium text-[#0099FB]'>
								ادخل تفاصيل الباقة <span className='text-red-500'>*</span>
							</h2>

							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={4}>
									<Grid item lg={6} xs={12} container rowSpacing={4}>
										<Grid item xs={12}>
											<Box className='flex flex-col items-start gap-2 md:flex-row md:items-center'>
												<label
													className='md:text-[18px] text-[16px]  text-[#1948a0] w-44 whitespace-nowrap text-[#011723]'
													htmlFor='name'>
													اسم الباقة<span className='text-red-500'>*</span>
												</label>
												<div className='flex flex-col w-full gap-2'>
													<input
														id='name'
														className='w-full md:h-[56px] h-[45px]  px-4 py-3 outline-none rounded-lg'
														style={{
															backgroundColor: "#EFF9FF",
															"&::placeholder": "#A7A7A7",
														}}
														placeholder='أدخل اسم الباقة'
														type='text'
														name='name'
														{...register("name", {
															required: "حقل الاسم مطلوب",
															pattern: {
																value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
																message: "يجب ان يتكون الاسم من أحرف",
															},
														})}
													/>
													<span className='text-red-500 text-[16px]'>
														{DataError?.name}
														{errors?.name && errors.name.message}
													</span>
												</div>
											</Box>
										</Grid>

										<Grid item xs={12}>
											<Box className='flex flex-col items-start gap-2 md:flex-row md:items-center'>
												<label
													style={{ color: "#011723" }}
													className='md:text-[18px] text-[16px]  text-[#1948a0] w-44 whitespace-nowrap'
													htmlFor='month'>
													الخصم
												</label>
												<div className='flex flex-col w-full gap-2'>
													<Controller
														name={"discount"}
														control={control}
														rules={{
															pattern: {
																value: /^[0-9]+$/i,
																message: "حقل مبلغ الخصم يجب ان يكون رقم",
															},
														}}
														render={({ field: { onChange, value } }) => (
															<input
																id='month'
																className=' w-full md:h-[56px] h-[45px] px-4 py-3 outline-none rounded-lg'
																style={{
																	backgroundColor: "#EFF9FF",
																	"&::placeholder": "#A7A7A7",
																}}
																placeholder='أدخل مبلغ الخصم'
																type='text'
																name='discount'
																value={value}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
															/>
														)}
													/>
													<span
														className='text-red-500 text-[16px]'
														style={{ direction: "ltr", textAlign: "end" }}>
														{DataError?.discount}
														{errors?.discount && errors.discount.message}
													</span>
												</div>
											</Box>
										</Grid>
									</Grid>

									<Grid item lg={6} xs={12} container>
										<Grid item xs={12}>
											<Box className='flex flex-col items-start gap-2 md:flex-row md:items-center'>
												<label
													style={{ color: "#011723" }}
													className='md:text-[18px] text-[16px]  text-[#1948a0] w-44 whitespace-nowrap'
													htmlFor='name'>
													اختر مدة الإشتراك
													<span className='text-red-500'>*</span>
												</label>
												<div className='flex flex-col w-full gap-2'>
													<Select
														displayEmpty
														value={datapackage?.periodtype}
														required
														name='periodtype'
														onChange={(e) => {
															setDataError({
																...DataError,
																periodtype: "",
															});
															setDataPackage({
																...datapackage,
																periodtype: e.target.value,
															});
														}}
														IconComponent={() => {
															return <IoIosArrowDown size={"1rem"} />;
														}}
														renderValue={(selected) => {
															if (!selected) {
																return <h2>اختر مدة الإشتراك</h2>;
															}
															const result = packagesPeriodList?.filter(
																(item) => item?.name === selected
															);
															return result[0]?.name_ar;
														}}
														sx={{
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
															"& .MuiOutlinedInput-input": {
																paddingRight: "10px !important",
															},
														}}
														className=' bg-[#EFF9FF] text-lg placeholder:text-[#A7A7A7] md:h-[56px] h-[45px] px-4 py-3 outline-none border-none rounded-lg'>
														{packagesPeriodList?.map((item, index) => {
															return (
																<MenuItem
																	key={index}
																	className='w-full bg-[#FFF] hover:bg-[#EFF9FF] h-12'
																	value={item?.name}>
																	{item?.name_ar}
																</MenuItem>
															);
														})}
													</Select>

													<span className='text-red-500 text-[16px]'>
														{DataError?.periodtype}
													</span>
												</div>
											</Box>
										</Grid>
										<Grid item xs={12}>
											<Box className='flex flex-col items-start gap-2 md:flex-row md:items-center'>
												<label
													style={{ color: "#011723" }}
													className='md:text-[18px] text-[16px]  text-[#1948a0] w-44 whitespace-nowrap'
													htmlFor='name'>
													قيمة الإشتراك{" "}
													{datapackage?.periodtype === "year"
														? "السنوي"
														: datapackage?.periodtype === "6months"
														? "لمدة 6 أشهر"
														: datapackage?.periodtype === "3months"
														? "لمدة 3 أشهر"
														: datapackage?.periodtype === "month"
														? "الشهري"
														: ""}
													<span className='text-red-500'>*</span>
												</label>
												<div className='flex flex-col w-full gap-2'>
													<Controller
														name={"yearly_price"}
														control={control}
														rules={{
															required: "حقل قيمة الإشتراك السنوي مطلوب",
															pattern: {
																value: /^[0-9.]+$/i,
																message:
																	"حقل قيمة الإشتراك السنوي يجب ان يكون رقم",
															},
															min: {
																value: 1,
																message:
																	"حقل قيمة الإشتراك السنوي يجب ان يكون أكبر من 0",
															},
														}}
														render={({ field: { onChange, value } }) => (
															<input
																id='name'
																className='w-full bg-[#EFF9FF] placeholder:text-[#A7A7A7] md:h-[56px] h-[45px] px-4 py-3 outline-none rounded-lg'
																placeholder='2500 ر.س'
																type='text'
																name='yearly_price'
																value={value}
																onChange={(e) => {
																	onChange(
																		e.target.value.replace(
																			/[^\d.]|\.(?=.*\.)/g,
																			""
																		)
																	);
																}}
															/>
														)}
													/>
													<span className='text-red-500 text-[16px]'>
														{DataError?.yearly_price}
														{errors?.yearly_price &&
															errors.yearly_price.message}
													</span>
												</div>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</div>

						{/*<div
							className='flex flex-col gap-[28px] bg-white mt-6 p-8 rounded-lg'
							style={{ boxShadow: "0px 3px 6px #eae8ee0F" }}>
							<h2
								style={{ color: "#0099FB" }}
								className='md:text-[20px] text-[18px] font-medium'>
								صوره تعريفيه عن الباقة <span className='text-red-500'>*</span>
							</h2>
							<Box sx={{ flexGrow: 1 }}>
								<div className='flex flex-col w-full gap-2'>
									<div className='text-gray-400 font-light text-[16px]'>
										المقاس المقترح : 300 * 600
									</div>
									<ImageUploading
										value={images}
										onChange={onChange}
										maxNumber={2}
										dataURLKey='data_url'
										acceptType={["jpg", "png", "jpeg", "webp"]}>
										{({ onImageUpload, dragProps }) => (
											// write your building UI
											<div
												className='relative mx-auto overflow-hidden upload__image-wrapper'
												style={{
													width: "100%",
													padding: "8px",
													border: "1px dashed #1948a0",
													borderRadius: "10px",
													strokeDasharray: "'6%2c5'",
												}}
												onClick={() => {
													onImageUpload();
												}}
												{...dragProps}>
												<div className='w-full h-full cursor-pointer image-item'>
													<div className='flex flex-col justify-center items-center gap-[5px] h-full w-full'>
														<IoMdCloudUpload size={"2em"} />
														<h2 style={{ color: "#011723", fontSize: "16px" }}>
															اسحب الصورة هنا
														</h2>
														<div className='flex flex-col items-center gap-1'>
															<h2 className='mb-0'>
																(سيتم قبول الصور png & jpg & jpeg & webp)
															</h2>
															<span className='text-gray-400 font-light text-[14px]'>
																(الحد الأقصى للصورة 1MB)
															</span>
														</div>
													</div>
												</div>
											</div>
										)}
									</ImageUploading>
									{DataError?.image && (
										<span className='text-red-500 text-[16px]'>
											{DataError?.image}
										</span>
									)}
								</div>

								<div className='flex justify-center flex-1 relative m-auto'>
									{images[0] && (
										<div className='md:max-w-[600px] w-[100%]  h-[100%] mt-4 '>
											<img
												className=' object-cover border border-primary-800'
												src={images[0]?.data_url}
												alt='preview-img'
												loading='lazy'
											/>
										</div>
									)}
								</div>
							</Box>
						</div>*/}

						<div
							className=' bg-white mt-6 p-8 rounded-lg'
							style={{ boxShadow: "0px 3px 6px #eae8ee0F" }}>
							<h2
								style={{ color: "#0099FB", fontSize: "20px" }}
								className='font-medium'>
								خطة الباقة <span className='text-red-500'>*</span>
							</h2>
							<FormControl sx={{ width: "100%" }}>
								<Select
									className={styles.select}
									multiple
									displayEmpty
									value={datapackage?.plan}
									onChange={(e) => {
										setDataPackage({ ...datapackage, plan: e.target.value });
									}}
									IconComponent={(props) => <Arrow fill='#242424' {...props} />}
									input={<OutlinedInput />}
									sx={{
										backgroundColor: "#EFF9FF",
										"& .MuiOutlinedInput-notchedOutline": {
											borderColor: "#92D9FF !important",
										},
									}}
									renderValue={(selected) => {
										if (selected?.length === 0) {
											return "اختر وصف الباقة";
										}

										return selected?.map((item, index) => {
											const result = planList?.plans?.filter(
												(p) => p?.id === parseInt(item)
											);
											return (
												<div
													key={index}
													className='px-3 py-1 font-light rounded-lg text-slate-50'
													style={{
														maxWidth: "fit-content",
														backgroundColor: "#0099FB",
														display: "inline",
													}}>
													{result?.[0]?.name}
												</div>
											);
										});
									}}
									inputProps={{ "aria-label": "Without label" }}>
									{planList?.plans?.map((option, index) => (
										<MenuItem
											className='souq_storge_category_filter_items'
											key={index}
											value={option?.id}
											sx={{
												padding: 0,
												backgroundColor: "#FAFAFA",
												height: "56px",
												"&:hover": {
													backgroundColor: "#5EBFF2",
												},
											}}>
											<Checkbox
												checked={datapackage?.plan?.indexOf(option?.id) > -1}
											/>
											<ListItemText primary={option?.name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<span className='text-red-500 text-[16px]'>
								{DataError?.plan}
							</span>
						</div>

						{/*	<div className=' bg-white mt-6 p-8 rounded-lg'>
							<h2
								style={{ color: "#0099FB" }}
								className='md:text-[20px] text-[18px] font-medium'>
								اختر الدورات
							</h2>
							<FormControl sx={{ width: "100%" }}>
								<Select
									className={styles.select}
									multiple
									displayEmpty
									value={datapackage?.course}
									onChange={(e) => {
										setDataPackage({ ...datapackage, course: e.target.value });
									}}
									IconComponent={(props) => <Arrow fill='#242424' {...props} />}
									input={<OutlinedInput />}
									sx={{
										backgroundColor: "#EFF9FF",
										"& .MuiOutlinedInput-notchedOutline": {
											borderColor: "#92D9FF !important",
										},
									}}
									renderValue={(selected) => {
										if (selected?.length === 0) {
											return "اختر الدورات المناسبة للباقة";
										}

										return selected?.map((item, index) => {
											const result = coursesList?.filter(
												(p) => p?.id === parseInt(item)
											);
											return (
												<div
													key={index}
													className='px-3 py-1 font-light rounded-lg text-slate-50'
													style={{
														maxWidth: "fit-content",
														backgroundColor: "#0099FB",
														display: "inline",
													}}>
													{result?.[0]?.name}
												</div>
											);
										});
									}}
									inputProps={{ "aria-label": "Without label" }}>
									{coursesList?.map((option, index) => (
										<MenuItem
											className='souq_storge_category_filter_items'
											key={index}
											value={option?.id}
											sx={{
												padding: 0,
												backgroundColor: "#FAFAFA",
												height: "56px",
												"&:hover": {
													backgroundColor: "#5EBFF2",
												},
											}}>
											<Checkbox
												checked={datapackage?.course?.indexOf(option?.id) > -1}
											/>
											<ListItemText primary={option?.name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<span className='text-red-500 text-[16px]'>
								{DataError?.course}
							</span>
						</div>

						<div className=' bg-white mt-6 p-8 rounded-lg'>
							<h2
								style={{ color: "#0099FB" }}
								className='md:text-[20px] text-[18px] font-medium'>
								حدد الرحلة التجارية
							</h2>
							<FormControl sx={{ width: "100%" }}>
								<Select
									className={styles.select}
									displayEmpty
									value={datapackage?.trip_id}
									onChange={(e) => {
										setDataPackage({ ...datapackage, trip_id: e.target.value });
									}}
									IconComponent={(props) => <Arrow fill='#242424' {...props} />}
									input={<OutlinedInput />}
									sx={{
										backgroundColor: "#EFF9FF",
										"& .MuiOutlinedInput-notchedOutline": {
											borderColor: "#92D9FF !important",
										},
									}}
									renderValue={(selected) => {
										if (selected?.length === 0) {
											return "اختر الرحلة المناسبة للباقة";
										}

										const result = tripsList?.filter(
											(item) => item?.id === parseInt(selected)
										);
										return result[0]?.title;
									}}>
									{tripsList?.map((option, index) => (
										<MenuItem
											className='souq_storge_category_filter_items'
											key={index}
											value={option?.id}
											sx={{
												padding: "0 10px",
												backgroundColor: "#FAFAFA",
												height: "56px",
												"&:hover": {
													backgroundColor: "#5EBFF2",
												},
											}}>
											{option?.title}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<span className='text-red-500 text-[16px]'>
								{DataError?.trip_id}
							</span>
						</div>*/}

						{/*	<div className=' bg-white mt-6 p-8 rounded-lg'>
							<h2
								style={{ color: "#0099FB" }}
								className='md:text-[20px] text-[18px] font-medium'>
								حدد قوالب الباقة
							</h2>

							<div className='flex flex-col w-full gap-2'>
								<Button
									btnType={"button"}
									className={
										"w-full md:h-[56px] h-[45px] rounded-lg py-4 md:ml-10 ml-0"
									}
									style={{
										width: "960px",
										backgroundColor: "#EEF4FC",
										border: "1px solid #1948a0",
									}}
									svg={<Template />}
									type={"outline"}
									onClick={() => {
										setChooseTemplate(true);
									}}>
									<p
										style={{ color: "#1948a0" }}
										className='md:text-[20px] text-[18px] font-medium'>
										اختر قوالب الباقة
									</p>
								</Button>
								<span className='text-red-500 text-[16px]'>
									{DataError?.template}
								</span>
							</div>
						</div>*/}
					</div>

					<div className='flex flex-col w-full gap-6 pb-16 mt-5 md:flex-row'>
						<Button
							btnType={"submit"}
							style={{ width: "100%" }}
							textStyle={{ color: "#fff" }}
							className={
								"md:h-[64px] max-h-[64px] save_button h-[45px] md:text-[20px] text-[18px] flex-1"
							}
							type={"normal"}>
							اعتماد الباقة
						</Button>
						<Button
							style={{ borderColor: "#f75e22" }}
							textStyle={{ color: "#f75e22" }}
							className={
								"md:w-[474px] w-full md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
							}
							type={"outline"}
							onClick={handleClosePage}
							btnType={"button"}>
							إلغاء
						</Button>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddNewPackagePlan;
