import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ChartsComp from "../../components/SupportPageComp/ChartsComp/ChartsComp";
import TableComp from "../../components/SupportPageComp/TableComp/TableComp";
import {
	useGetTechnicalSupportQuery,
	useSearchInTechnicalSupportMutation,
} from "../../RTK/apiSlices/technicalSupportApi";
import SupportSearchForm from "../../components/SupportPageComp/SupportSearchForm";

const SupportPage = () => {
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetTechnicalSupportQuery(
		{
			page,
			number: rowsPerPage,
		}
	);

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	let resultData = fetchedData?.Technicalsupports || [];

	if (search !== "") {
		resultData = searchResults?.supports;
	} else {
		resultData = fetchedData?.Technicalsupports;
	}

	const [searchInTechnicalSupport, { isLoading: searchIsLoading }] =
		useSearchInTechnicalSupportMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInTechnicalSupport({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(resultData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | الدعم الفني</title>
			</Helmet>
			<div className='relative 2xl:pl-36 xl:pr-8 p-4 xl:bg-[#F7F7F7] bg-[#FFFFFF]'>
				<ChartsComp
					storeTechnicalSupports={fetchedData?.Store_Technicalsupports}
					percentStoreTechnicalSupports={
						fetchedData?.percent_of_Store_Technicalsupports
					}
					technicalCount={fetchedData?.TechnicalsupportsCount}
					pending={fetchedData?.pending_Technicalsupports}
					finished={fetchedData?.finished_Technicalsupports}
				/>
				<div className={"mt-8"} style={{ backgroundColor: "#FFF" }}>
					<div className='p-4'>
						<SupportSearchForm search={search} setSearch={setSearch} />

						<div dir={"ltr"}>
							<TableComp
								technicalsupports={resultData}
								loading={loading || searchIsLoading}
								pageNumber={page}
								setPageNumber={setPage}
								currentPage={rowsPerPage}
								setCurrentPage={setRowsPerPage}
								page_count={fetchedData?.page_count}
								search={search}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SupportPage;
