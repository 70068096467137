import React, { useState, useEffect } from "react";
import styles from "./ShowCity.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
/* Icons */
import { AiFillStar } from "react-icons/ai";
import { GoArrowRight } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import { useAdminCountriesDataQuery } from "../../../../RTK/apiSlices/selectorApi";
import { useGetCityDataQuery } from "../../../../RTK/apiSlices/citiesApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 opacity-50  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const formTitleClasses =
	"md:w-[315px] w-full font-normal md:text-[18px] text-[16px]  text-[#1948a0]md:mb-0 mb-2";
//
const formInputClasses =
	"md:w-[555px] w-full md:h-14 h-[45px] px-4 outline-0 rounded-lg";
const formInputStyle = {
	backgroundColor: "#FFFFF",
	border: "1px solid #86B1EA",
	borderRadius: "8px",
};

const ShowCity = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: countries } = useAdminCountriesDataQuery();
	const { data: fetchedData, isLoading: loading } = useGetCityDataQuery({
		showCityId: id,
	});
	const [cityData, setCityData] = useState({
		name: "",
		name_en: "",
		code: "",
		country_id: "",
	});
	const { control, reset } = useForm({
		mode: "onBlur",
		defaultValues: {
			code: "",
			name: "",
			name_en: "",
		},
	});

	useEffect(() => {
		if (fetchedData?.status === 200) {
			setCityData({
				...cityData,
				code: fetchedData?.cities?.code,
				name: fetchedData?.cities?.name,
				name_en: fetchedData?.cities?.name_en,
				country_id: fetchedData?.cities?.country?.id,
			});
		}
	}, [fetchedData]);

	useEffect(() => {
		reset(cityData);
	}, [cityData, reset]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | تفاصيل مدينة</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/cities");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-[#EEF4FC] z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
				}}>
				<form className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='p-4 pt-24 md:p-8 md:pt-8'
							style={{
								backgroundColor: "#EDF3FC",
							}}>
							<h2 className='font-bold md:text-2xl text-[20px] mb-3'>
								تفاصيل مدينة
							</h2>
							<div className='flex'>
								<div className={`flex items-center gap-2 `}>
									<div
										onClick={() => {
											navigate("/cities");
										}}
										className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
										<GoArrowRight
											style={{ color: "#1948a0", fontSize: "1.2rem" }}
										/>
									</div>

									<h2 className='font-normal md:text-lg text-[16px] text-[#1948a0] md:ml-4 ml-2'>
										{" "}
										الإعدادات{" "}
									</h2>
								</div>

								<h2 className='font-normal md:text-lg text-[16px] text-[#1948a0] md:ml-4 ml-2'>
									{" "}
									/ جدول المدن{" "}
								</h2>
								<h3
									className='font-normal md:text-lg text-[16px] text-[#1948a0]'
									style={{ color: "#4875c2" }}>
									/ تفاصيل مدينة
								</h3>
							</div>
						</div>
						{loading ? (
							<div className='flex flex-col items-center justify-center w-full h-full'>
								<CircularLoading />
							</div>
						) : (
							<>
								<div
									className={`flex-1 overflow-y-scroll md:py-12 md:pr-8 p-4 ${styles.content}`}>
									<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
										<h2 className={formTitleClasses}>
											<AiFillStar
												style={{
													display: "inline-block",
													marginLeft: "0.5rem",
													color: "red",
												}}></AiFillStar>
											رقم الدولة<span className='text-red-500'>*</span>
										</h2>
										<div className='flex flex-col w-full md:w-auto'>
											<Select
												className='md:w-[555px] w-full md:h-[3.5rem] h-[45px] rounded-lg bg-white '
												value={cityData?.country_id}
												IconComponent={() => {
													return <IoIosArrowDown size={"1.2rem"} />;
												}}
												onChange={(e) =>
													setCityData({
														...cityData,
														country_id: e.target.value,
													})
												}
												disabled={true}
												displayEmpty
												inputProps={{ "aria-label": "Without label" }}
												renderValue={(selected) => {
													if (cityData?.country_id === "") {
														return (
															<h2 style={{ color: "#011723" }}>اختر الدولة</h2>
														);
													}
													const result =
														countries?.countries?.filter(
															(item) => item?.id === parseInt(selected)
														) || cityData?.country_id;
													return (
														<div className='flex flex-row items-center justify-between'>
															<span>{result[0]?.code}</span>
															<span>{result[0]?.name}</span>
														</div>
													);
												}}
												sx={{
													border: "1px solid #86B1EA",
													pl: "1rem !important",
													"& .MuiSelect-select": {
														pr: "1rem !important",
													},
													"& .MuiOutlinedInput-notchedOutline": {
														border: "none",
													},
												}}>
												{countries?.countries?.map((item, idx) => {
													return (
														<MenuItem
															key={idx}
															className='flex items-center justify-between souq_storge_category_filter_items'
															sx={{
																backgroundColor: "#EFF9FF",
																height: "3rem",
																"&:hover": {},
															}}
															value={`${item?.id}`}>
															<h2 className='md:text-[18px] text-[16px]'>
																{item?.code}
															</h2>
															<h2 className='md:text-[18px] text-[16px]'>
																{item?.name}
															</h2>
														</MenuItem>
													);
												})}
											</Select>
										</div>
									</div>
									<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
										<h2 className={formTitleClasses}>
											<AiFillStar
												style={{
													display: "inline-block",
													marginLeft: "0.5rem",
													color: "red",
												}}></AiFillStar>
											رقم المدينة<span className='text-red-500'>*</span>
										</h2>
										<div className='flex flex-col w-full md:w-auto'>
											<Controller
												name={"code"}
												control={control}
												rules={{
													required: "حقل الكود مطلوب",
													pattern: {
														value: /^[0-9]+$/i,
														message: "يجب ان يكون الكود أرقام",
													},
												}}
												render={({ field: { onChange, value } }) => (
													<input
														className={formInputClasses}
														style={formInputStyle}
														placeholder='أدخل أرقام فقط'
														type='text'
														name='code'
														value={value}
														onChange={(e) => {
															onChange(e.target.value.replace(/[^0-9]/g, ""));
														}}
														disabled={true}
													/>
												)}
											/>
										</div>
									</div>
									<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
										<h2 className={formTitleClasses}>
											<AiFillStar
												style={{
													display: "inline-block",
													marginLeft: "0.5rem",
													color: "red",
												}}></AiFillStar>
											اسم المدينة (AR)<span className='text-red-500'>*</span>
										</h2>
										<div className='flex flex-col w-full md:w-auto'>
											<Controller
												name={"name"}
												control={control}
												rules={{
													required: "حقل الاسم مطلوب",
													pattern: {
														value: /^[^-\s][\u0600-\u06FF ]+$/i,
														message: "يجب ان تكون الاحرف بالعربي",
													},
												}}
												render={({ field: { onChange, value } }) => (
													<input
														className={formInputClasses}
														style={formInputStyle}
														placeholder='ادخل حروف عربي فقط'
														type='text'
														name='name'
														value={value}
														onChange={(e) => {
															onChange(e.target.value.replace(/[^0-9]/g, ""));
														}}
														disabled={true}
													/>
												)}
											/>
										</div>
									</div>
									<div className='flex flex-col items-start mb-4 md:flex-row md:mb-8 md:items-center'>
										<h2 className={formTitleClasses}>
											<AiFillStar
												style={{
													display: "inline-block",
													marginLeft: "0.5rem",
													color: "red",
												}}></AiFillStar>
											اسم المدينة (EN)<span className='text-red-500'>*</span>
										</h2>
										<div className='flex flex-col w-full md:w-auto'>
											<Controller
												name={"name_en"}
												control={control}
												rules={{
													required: "حقل الاسم بالانجليزي مطلوب",
													pattern: {
														value: /^[^-\s][A-Za-z ]+$/i,
														message: "يجب ان تكون الاحرف بالانجليزي",
													},
												}}
												render={({ field: { onChange, value } }) => (
													<input
														className={formInputClasses}
														style={formInputStyle}
														placeholder='ادخل حروف انجليزية فقط'
														type='text'
														name='name_en'
														value={value}
														onChange={(e) => {
															onChange(e.target.value.replace(/[^0-9]/g, ""));
														}}
														disabled={true}
													/>
												)}
											/>
										</div>
									</div>
								</div>
								<div
									className='flex items-center justify-center gap-4 p-4 md:p-8'
									style={{
										backgroundColor: "rgba(235, 235, 235, 1)",
									}}>
									<Button
										btnType={"button"}
										className={
											"md:h-14 h-[45px]  md:w-1/3 w-11/12 md:text-xl md:text-[18px]"
										}
										style={{ borderColor: "#f75e22" }}
										textStyle={{ color: "#f75e22" }}
										type={"normal"}
										onClick={() => {
											navigate("/cities");
										}}>
										الغاء
									</Button>
								</div>
							</>
						)}
					</div>
				</form>
			</div>
		</>
	);
};

export default ShowCity;
