import React, { useEffect, useState } from "react";

import Context from "./context";

const ContextProvider = (props) => {
	const [selectedId, setSelectedId] = useState(3); // to handle tabs of atlbha academy
	const [title, setEndActionTitle] = useState(null);
	const [actionWarning, setActionWarning] = useState(false);
	const [subCategories, setSubCategories] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	//  to handle show / hidden Add SubVariety page
	const [showAddSubVariety, setShowAddSubVariety] = useState(false);

	/** product options  */
	const [productHasOptions, setProductHasOptions] = useState(false);
	const [attributes, setAttributes] = useState([]);
	const [optionsSection, setOptionsSection] = useState([
		{
			name: "",
			select_value: "نص",
			values: [
				{
					id: 1,
					title: "",
					color: "#000000",
					image: "",
					previewImage: "",
					defaultOption: false,
				},
			],
		},
	]);

	const clearOptions = () => {
		setProductHasOptions(false);
		setAttributes([]);
		setOptionsSection([
			{
				name: "",
				select_value: "نص",
				values: [
					{
						id: 1,
						title: "",
						color: "#000000",
						image: "",
						previewImage: "",
						defaultOption: false,
					},
				],
			},
		]);
	};

	useEffect(() => {
		if (title) {
			setTimeout(() => {
				setEndActionTitle(null);
				setActionWarning(false);
			}, 1000);
		}
	}, [title]);

	const context = {
		selectedId,
		setSelectedId,

		title,
		setEndActionTitle,
		actionWarning,
		setActionWarning,
		subCategories,
		setSubCategories,
		productOptions,
		setProductOptions,
		showAddSubVariety,
		setShowAddSubVariety,

		//product Options
		productHasOptions,
		setProductHasOptions,
		attributes,
		setAttributes,
		optionsSection,
		setOptionsSection,
		clearOptions,
	};

	return <Context.Provider value={context}>{props.children}</Context.Provider>;
};

export default ContextProvider;
