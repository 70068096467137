import React, { useState, Fragment, useContext } from "react";
import styles from "./AddNewLesson.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Context */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Components */
import AddNewVideo from "./AddNewVideo";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { IoMdCloudUpload } from "react-icons/io";
import { useAddNewExplainVideosMutation } from "../../../../RTK/apiSlices/coursesApi";
import Context from "../../../../store/context";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900 pb-36  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const AddNewLesson = () => {
	const navigate = useNavigate();
	const contextStore = useContext(Context);
	const { setSelectedId } = contextStore;

	const handleClosePage = () => {
		navigate("/atlbha_academy");
		setSelectedId(3);
	};

	const [data, setData] = useState({
		video: "",
		thumbnail: "",
		link: "",
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			title: "",
		},
	});

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [images, setImages] = useState([]);
	const [video, setVideo] = useState("");

	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChange = (imageList) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				thumbnail: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, thumbnail: null });
		}
	};

	const [DataError, setDataError] = useState({
		title: "",
		video: "",
		thumbnail: "",
	});

	const resetData = () => {
		setDataError({
			title: "",
			video: "",
			thumbnail: "",
		});
	};

	const [addNewExplainVideos] = useAddNewExplainVideosMutation();
	const addNewExplainVideosHandel = async (formData) => {
		try {
			const res = await addNewExplainVideos({
				body: formData,
			});

			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				handleClosePage();
			} else {
				setLoadingTitle(null);

				setDataError({
					title: res?.data?.message?.en?.title?.[0],
					video: res?.data?.message?.en?.video?.[0],
					thumbnail: res?.data?.message?.en?.thumbnail?.[0],
				});
				toast.error(res?.data?.message?.en?.title?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.video?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.thumbnail?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddLesson = (dataAddLesson) => {
		resetData();
		const formData = new FormData();
		formData.append("title", dataAddLesson?.title);
		if (images.length !== 0) {
			formData.append("thumbnail", images[0]?.file || null);
		}
		if (video !== "") {
			formData.append("video", video || null);
		}
		setLoadingTitle("جاري اضافة الدرس");
		addNewExplainVideosHandel(formData);
	};

	return (
		<Fragment>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | اضافة درس</title>
			</Helmet>
			<BackDrop onClick={handleClosePage} />
			<div
				className={`fixed top-[70px] left-0 bg-slate-50 z-30 otlobha_new_product ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<form onSubmit={handleSubmit(handleAddLesson)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='flex flex-col md:py-[30px] md:pr-[27px] p-4 gap-[10px]'
							style={{
								backgroundColor: "#EDF3FC",
							}}>
							<h2
								style={{ color: "#011723" }}
								className='md:text-[24px] text-[20px] font-bold'>
								اضافة درس
							</h2>
							<p
								style={{ color: "#011723" }}
								className='md:text-[20px] text-[16px] font-normal'>
								اضف درس لقسم الشروحات في اكاديمية سوق
							</p>
						</div>
						<div
							style={{ backgroundColor: "#F6F6F6" }}
							className={`flex-1 flex flex-col gap-8 overflow-y-scroll md:py-8 md:pr-[27px] md:pl-[157px] p-4 ${styles.content}`}>
							<div className='flex flex-col items-start md:flex-row md:items-center gap-y-4'>
								<label
									style={{ color: "#011723" }}
									className='md:w-80 w-full md:text-[20px] text-[18px] font-medium whitespace-nowrap'>
									عنوان الدرس<span className='text-red-500'>*</span>
								</label>
								<div className='flex flex-col w-full gap-2'>
									<input
										className='w-full md:h-14 h-[45px] rounded-md px-5 py-4 outline-none'
										style={{
											fontSize: "20px",
											color: "#1948a0",
											backgroundColor: "#EEF3FC",
											border: "1px solid #86B1EA",
										}}
										placeholder='عنوان الفيديو'
										type='text'
										name='title'
										{...register("title", {
											required: "حقل العنوان مطلوب",
											pattern: {
												value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
												message: "يجب ان يتكون الاسم من أحرف",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.title}
										{errors?.title && errors.title.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col items-start md:flex-row md:items-center gap-y-4'>
								<h2
									style={{ color: "#011723", fontSize: "20px" }}
									className='min-w-[14.5rem] font-medium whitespace-nowrap'>
									ارفاق فيديو الدرس<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col w-full gap-2'>
									<div className={styles.add_video}>
										<AddNewVideo
											value={video}
											setValue={setVideo}
											placeholder='قم بنسخ رابط الامبدد الخاص بالفيديو من اليوتيوب'
										/>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.video}
									</span>
								</div>
							</div>
							<div className='flex flex-col items-start md:flex-row md:items-center gap-y-4'>
								<label
									className='font-medium w-80 whitespace-nowrap'
									style={{ color: "#011723", fontSize: "20px" }}>
									صورة تعريفية <span className='text-red-500'>*</span>
								</label>
								<div className='flex flex-col w-full gap-2'>
									<div className='text-gray-400 font-light text-[16px]'>
										المقاس المقترح : 440 * 440
									</div>
									<ImageUploading
										value={images}
										onChange={onChange}
										maxNumber={2}
										dataURLKey='data_url'
										acceptType={["jpg", "png", "jpeg", "webp"]}>
										{({ onImageUpload, dragProps }) => (
											// write your building UI
											<div
												className='relative mx-auto overflow-hidden upload__image-wrapper'
												style={{
													width: "100%",
													padding: "8px",
													border: "1px dashed #1948a0",
													borderRadius: "10px",
													strokeDasharray: "'6%2c5'",
												}}
												onClick={() => {
													onImageUpload();
												}}
												{...dragProps}>
												<div className='w-full h-full cursor-pointer image-item'>
													<div className='flex flex-col items-center justify-center w-full h-full'>
														<IoMdCloudUpload size={"2em"}></IoMdCloudUpload>
														<h2 style={{ color: "#011723", fontSize: "16px" }}>
															اسحب الصورة هنا
														</h2>
														<div className='flex flex-col items-center gap-1'>
															<h2 className='mb-0'>
																(سيتم قبول الصور png & jpg & jpeg & webp)
															</h2>
															<span className='text-gray-400 font-light text-[14px]'>
																(الحد الأقصى للصورة 1MB)
															</span>
														</div>
													</div>
												</div>
											</div>
										)}
									</ImageUploading>

									<span className='text-red-500 text-[16px]'>
										{DataError?.thumbnail}
									</span>
								</div>
							</div>
							<div className='flex flex-col items-start md:flex-row '>
								<label className='font-medium w-80 whitespace-nowrap'></label>
								{images[0] && (
									<div className='md:w-[400px] w-[100%] md:h-[400px] h-[100%] '>
										<img
											src={images[0]?.data_url || data?.thumbnail}
											alt='preview-img'
											loading='lazy'
										/>
									</div>
								)}
							</div>
						</div>
						<div
							className='md:h-[135px] h-[100px] md:p-8 p-4 flex items-center justify-center gap-4 bg-[rgb(220, 232, 249)]'
							style={{
								backgroundColor: "#EDF3FC",
							}}>
							<Button
								btnType={"submit"}
								className='font-medium save_button md:h-14 h-[45px] w-full md:w-1/3'
								textStyle={{ color: "#FFF", fontSize: "22px" }}
								type={"normal"}>
								حفظ
							</Button>
							<Button
								className='font-medium md:h-14 h-[45px] w-full md:w-1/3'
								style={{
									borderColor: `#f75e22`,
								}}
								textStyle={{ color: "#f75e22", fontSize: "22px" }}
								type={"outline"}
								onClick={handleClosePage}
								btnType={"button"}>
								إلغاء
							</Button>
						</div>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default AddNewLesson;
