import React from "react";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* MUI */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const MostProducts = ({ more_product_visit, loading }) => {
	return (
		<TableContainer
			component={Paper}
			sx={{ height: "100%" }}
			className='shadow-[0px_3px_6px_#00000029] rounded-lg'>
			<h2 className='p-4 text-[22px] font-medium text-[#1948a0] bg-[#EEF3FC]'>
				المنتجات الأكثر زيارة
			</h2>
			<Table sx={{}} aria-label='simple table'>
				<TableHead>
					<TableRow
						className='bg-[rgb(220, 232, 249)]'
						sx={{
							"& .MuiTableCell-root.MuiTableCell-head": {
								fontFamily: "'Tajawal', sans-serif",
								border: "none",
							},
						}}>
						<TableCell
							align='center'
							className='text-[#52575D] text-base font-medium '>
							رقم المنتج
						</TableCell>
						<TableCell
							align='right'
							className='text-[#52575D] text-base font-medium'>
							الاسم
						</TableCell>
						<TableCell
							align='right'
							className='text-[#52575D] text-base font-medium'>
							الأنشطة
						</TableCell>
						<TableCell
							align='right'
							className='text-[#52575D] text-base font-medium'>
							السعر
						</TableCell>
						<TableCell
							align='center'
							className='text-[#52575D] text-base font-medium '>
							اجمالى المبيعات
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody
					sx={{
						"& .MuiTableRow-root .MuiTableCell-root": {
							fontFamily: "'Tajawal', sans-serif",
							border: "none",
						},
					}}>
					{loading ? (
						<TableRow>
							<TableCell colSpan={5}>
								<CircularLoading />
							</TableCell>
						</TableRow>
					) : (
						<>
							{more_product_visit?.length === 0 ? (
								<TableRow>
									<TableCell colSpan={5}>
										<p> لا يوجد منتجات حتي الآن</p>
									</TableCell>
								</TableRow>
							) : (
								more_product_visit?.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
											"& .MuiTableCell-root": {
												height: "4rem",
												border: "none",
											},
										}}>
										<TableCell
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
											component='th'
											scope='row'>
											{row?.id}
										</TableCell>
										<TableCell
											sx={{ textAlign: "start" }}
											component='th'
											scope='row'>
											<div className='flex flex-row items-center gap-2'>
												<div
													className='img_icons'
													style={{
														minWidth: "35px",
														minHeight: "35px",
														maxWidth: "35px",
														maxHeight: "35px",
													}}>
													<img
														src={row?.cover}
														alt={row?.name}
														loading='lazy'
													/>
												</div>
												<span className='text-ellipsis w-24'>{row?.name}</span>
											</div>
										</TableCell>
										<TableCell align='right' className='flex flex-row'>
											<span className='text-ellipsis w-20'>
												{row?.category?.name}
											</span>
										</TableCell>
										<TableCell align='center' className='whitespace-nowrap'>
											{Number(row?.selling_price)?.toFixed(2)} ريال
										</TableCell>
										<TableCell align='center' className='text-base font-normal'>
											{row?.getOrderTotal}
										</TableCell>
									</TableRow>
								))
							)}
						</>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default MostProducts;
