import React, { useState } from "react";

/* MUI */
import { styled } from "@mui/material";
import Badge from "@mui/material/Badge";

import LogoutBtn from "./LogoutBtn";
import ProfileBtn from "./ProfileBtn";
import UserBadge from "../UserBadge/UserBadge";

const BackDrop = ({ closeMenu }) => {
	return (
		<div
			onClick={closeMenu}
			className='fixed bottom-0 left-0 z-10 w-full h-full back_drop'></div>
	);
};

const ProfileMenu = () => {
	const [open, setOpen] = useState(false);

	const handleCloseMenu = () => {
		setOpen(!open);
	};

	return (
		<div className='md:min-w-[220px] relative h-full order-last md:order-first'>
			<UserBadge closeMenu={handleCloseMenu} />
			<div className={`${open ? "flex" : "hidden"}`}>
				<BackDrop closeMenu={handleCloseMenu} />
				<div className='min-w-[220px] bg-[#4875c2]  flex flex-col gap-4  text-[#F7FCFF] w-full py-3 md:absolute fixed md:top-14 top-[4.7rem] left-0 z-20'>
					<ProfileBtn closeMenu={handleCloseMenu} />
					<LogoutBtn closeMenu={handleCloseMenu} />
				</div>
			</div>
		</div>
	);
};
export default ProfileMenu;
