import React, { useEffect, useState } from "react";
/* Components */
import TableComp from "./TableComp/TableComp";
/* MUI */
import FormControl from "@mui/material/FormControl";
/* Icons */
import { AiOutlineSearch } from "react-icons/ai";
import { useSearchInCountriesMutation } from "../../../../RTK/apiSlices/countriesApi";

const UsersTable = ({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) => {
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	let resultData = fetchedData || [];
	if (search !== "") {
		resultData = searchResults?.countries;
	} else {
		resultData = fetchedData;
	}

	const [searchInCountries, { isLoading: searchIsLoading }] =
		useSearchInCountriesMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInCountries({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(resultData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);
	return (
		<div className='mt-3'>
			<div className='mb-4'>
				<FormControl
					className='flex flex-row gap-4'
					sx={{ minWidth: 120, maxWidth: "100%", flex: "1" }}>
					<label className={`flex-1 md:h-14 h-[45px] relative `}>
						<input
							className='md:w-[376px] w-full h-full outline-0 pr-12 rounded-lg text-lg font-normal'
							placeholder=' ابحث عن دولة'
							type='text'
							name='name'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							style={{
								border: "1px solid #86B1EA",
								backgroundColor: "#EEF4FC",
							}}
						/>
						<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
							<AiOutlineSearch color='#1948a0' size={"20px"}></AiOutlineSearch>
						</div>
					</label>
				</FormControl>
			</div>

			<div dir={"ltr"} className={"md:mt-12 mt-6"}>
				<TableComp
					data={resultData}
					loading={loading || searchIsLoading}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
					search={search}></TableComp>
			</div>
		</div>
	);
};

export default UsersTable;
