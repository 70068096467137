import React from "react";
/* Icons */
import { LinkArrow } from "../../../../assets/Icons/index";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

const LatestMarkets = ({ latestStores }) => {
	return (
		<div className='flex-1 rounded-lg shadow-lg'>
			<div
				className='flex items-center h-12 pr-4'
				style={{ backgroundColor: "#CBDEF6" }}>
				<h2 className='font-medium text-[#1948a0] md:text-[18px] text-[16px]'>
					أحدث المتاجر
				</h2>
			</div>
			<div className='bg-white'>
				{latestStores?.length === 0 ? (
					<div className='flex justify-center w-100 h-100 align-center'>
						<p> لا يوجد متاجر حتي الآن</p>
					</div>
				) : (
					latestStores?.map((store, index) => {
						return (
							<div
								key={index}
								className={`${
									index === latestStores.length - 1
										? "w-full flex flex-row items-end space-between md:p-4 p-2 md:h-24 h-16  bg-[rgb(220, 232, 249)] "
										: "w-full flex flex-row items-end space-between md:p-4 p-2 md:h-24 h-16 shadow-[0px_3px_6px_#1948a00F] bg-[rgb(220, 232, 249)] mb-2 "
								}`}>
								<div className='flex flex-row items-center flex-1 gap-3'>
									<div className='flex flex-col md:h-[45px] h-[30px] md:w-[45px] w-[30px]'>
										<div
											className='img_icons'
											style={{
												minWidth: "100%",
												minHeight: "100%",
												maxWidth: "100%",
												maxHeight: "100%",
											}}>
											<img
												src={store?.icon ? store?.icon : PlaceholderImage}
												alt={store?.store_name}
												loading='lazy'
												style={{
													width: "100%",
													height: "100%",
													objectFit: "cover",
													borderRadius: "50%",
												}}
											/>
										</div>
									</div>
									<div className='flex flex-col flex-1 gap-1 md:gap-4'>
										<h2 className='md:w-96 w-52 font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0] overflow-hidden whitespace-nowrap text-ellipsis'>
											{store?.store_name}
										</h2>
										<h2 className='md:w-96 w-52 font-normal md:text-[18px] text-[16px]  text-[#1948a0]overflow-hidden whitespace-nowrap text-ellipsis'>
											{store?.domain}
										</h2>
									</div>
								</div>
								<div className='md:w-[24px] w-[18px]'>
									<a href={store?.domain} target='_blank' rel='noreferrer'>
										<LinkArrow className='w-full' title='معاينة المتجر' />
									</a>
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
};

export default LatestMarkets;
