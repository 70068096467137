import React, { useState, useContext } from "react";
import styles from "./AddNewMarket.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
/* Custom Hook */

/* Context */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
/* Icons */
import { AiFillStar } from "react-icons/ai";
import { GoArrowRight } from "react-icons/go";
import {
	PhoneIcon,
	PasswordClose,
	PasswordOpen,
	User,
	Email,
	Arrow,
} from "../../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import {
	useActivitiesDataQuery,
	useCitiesDataQuery,
	useCountriesDataQuery,
	useSelectPackagesQuery,
	useSubCategoriesDataQuery,
} from "../../../../RTK/apiSlices/selectorApi";
import { useAddNewMarketMutation } from "../../../../RTK/apiSlices/marketsApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};
const planeTime = [
	{ id: 1, name: "12 شهر", name_en: "year" },
	{ id: 2, name: "6 أشهر", name_en: "6months" },
];
const conditions = [
	{ id: 1, name: "مفعل", name_en: "active" },
	{ id: 2, name: "غير مفعل", name_en: "not_active" },
];

const AddNewMarket = () => {
	const navigate = useNavigate();

	const { data: packages } = useSelectPackagesQuery();

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [images, setImages] = useState([]);

	// Define storeInfo object
	const [storeInfo, setStoreInfo] = useState({
		package_id: "",
		activity_ids: [],
		sub_activity_ids: [],
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			store_name: "",
			domain: "",
			store_email: "",
			phonenumber: "",
			package_id: "",
			activity_ids: "",
			sub_activity_ids: "",
			country_id: "",
			city_id: "",

			name: "",
			user_name: "",
			email: "",
			password: "",
			userphonenumber: "",
			user_country_id: "",
			user_city_id: "",
			status: "",
		},
	});
	const [DataError, setDataError] = useState({
		store_name: "",
		domain: "",
		store_email: "",
		phonenumber: "",
		package_id: "",
		activity_ids: "",
		sub_activity_ids: "",
		country_id: "",
		city_id: "",

		name: "",
		user_name: "",
		email: "",
		password: "",
		userphonenumber: "",
		user_country_id: "",
		user_city_id: "",
		status: "",
		iamge: "",
	});
	const resetData = () => {
		setDataError({
			store_name: "",
			domain: "",
			store_email: "",
			phonenumber: "",
			package_id: "",
			activity_ids: "",
			sub_activity_ids: "",
			country_id: "",
			city_id: "",

			name: "",
			user_name: "",
			email: "",
			password: "",
			userphonenumber: "",
			user_country_id: "",
			user_city_id: "",
			status: "",
			iamge: "",
		});
	};

	/** --------------------------------------------- */

	// to get selectors from api
	const { data: countryList } = useCountriesDataQuery();
	const { data: citiesList } = useCitiesDataQuery();
	const { data: activitiesList } = useActivitiesDataQuery();
	const queryParams = storeInfo?.activity_ids
		?.map((id) => `category_id[]=${id}`)
		.join("&");
	const { data: subActivitiesList } = useSubCategoriesDataQuery({
		queryParams,
	});
	/** -------------------------------------------------------- */

	// set these state open and close activity and show password
	const [showPassword, setShowPassword] = useState(false);

	// to hold images
	const onChange = (imageList) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};

	const [addNewMarket] = useAddNewMarketMutation();
	const addNewMarketHandel = async (formData) => {
		try {
			const res = await addNewMarket({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/markets");
			} else {
				setLoadingTitle(null);

				setDataError({
					store_name: res?.data?.message?.en?.store_name?.[0],
					domain: res?.data?.message?.en?.domain?.[0],
					store_email: res?.data?.message?.en?.store_email?.[0],
					phonenumber: res?.data?.message?.en?.phonenumber?.[0],
					package_id: res?.data?.message?.en?.package_id?.[0],
					activity_ids: res?.data?.message?.en?.activity_id?.[0],
					sub_activity_ids: res?.data?.message?.en?.subcategory_id?.[0],
					country_id: res?.data?.message?.en?.country_id?.[0],
					city_id: res?.data?.message?.en?.city_id?.[0],

					name: res?.data?.message?.en?.name?.[0],
					user_name: res?.data?.message?.en?.user_name?.[0],
					email: res?.data?.message?.en?.email?.[0],
					password: res?.data?.message?.en?.password?.[0],
					userphonenumber: res?.data?.message?.en?.userphonenumber?.[0],
					user_country_id: res?.data?.message?.en?.user_country_id?.[0],
					user_city_id: res?.data?.message?.en?.user_city_id?.[0],
					status: res?.data?.message?.en?.status?.[0],
					image: res?.data?.message?.en?.image?.[0],
				});
				toast.error(res?.data?.message?.en?.store_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.domain?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.store_email?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.phonenumber?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.package_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.activity_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.subcategory_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.country_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.city_id?.[0], { theme: "light" });

				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.user_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.email?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.password?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.userphonenumber?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.user_country_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.user_city_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.status?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.image?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	// define this functions to post all add market data to server
	const handleAddStore = (data) => {
		resetData();
		setLoadingTitle("جاري حفظ المتجر");
		let formData = new FormData();
		formData.append("store_name", data?.store_name);
		formData.append("domain", data?.domain);
		formData.append("store_email", data?.store_email);
		formData.append(
			"phonenumber",
			data?.phonenumber?.startsWith("+966") ||
				data?.phonenumber?.startsWith("00966")
				? data?.phonenumber
				: `+966${data?.phonenumber}`
		);
		formData.append("country_id", data?.country_id);
		formData.append("city_id", data?.city_id);

		// create looping to get all ids for activity_ids and assign it
		for (let i = 0; i < storeInfo?.activity_ids?.length; i++) {
			formData.append([`activity_id[${i}]`], storeInfo?.activity_ids[i]);
		}
		// create looping to get all ids for sub_activity_ids and assign it
		for (let i = 0; i < storeInfo?.sub_activity_ids?.length; i++) {
			formData.append([`subcategory_id[${i}]`], storeInfo?.sub_activity_ids[i]);
		}
		/** ------------------------------------------- */

		formData.append("name", data?.name);
		formData.append("user_name", data?.user_name);
		formData.append("email", data?.email);
		formData.append("password", data?.password);
		formData.append("userphonenumber", data?.userphonenumber);
		formData.append("user_country_id", data?.user_country_id);
		formData.append("user_city_id", data?.user_city_id);

		// here we tell formData if images[0].file its has been file got it if not just put empty stings
		formData.append("image", images[0]?.file || null);
		formData.append("status", data?.status);
		addNewMarketHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | اضافة متجر</title>
			</Helmet>
			<BackDrop onClick={() => navigate("/markets")}></BackDrop>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<div className='flex flex-col justify-between h-full'>
					<div
						className=' px-5 py-[20px] pt-24 md:pt-10'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<h2 className='font-bold md:text-[22px] text-[20px] text-right mb-3'>
							انشاء متجر جديد
						</h2>
						<div className='flex flex-row items-center'>
							<div
								onClick={() => navigate("/markets")}
								className={"flex items-center gap-2 cursor-pointer"}>
								<div
									className='flex flex-col items-center justify-center'
									style={{
										width: "30px",
										height: "30px",
										borderRadius: "50%",
										backgroundColor: "#1948a01A",
									}}>
									<GoArrowRight
										style={{ color: "#1948a0", fontSize: "1.2rem" }}
									/>
								</div>
								<h2 className='ml-4 md:text-[18px] text-[16px]'>
									{" "}
									عرض المتاجر{" "}
								</h2>
							</div>
							<h3
								className='md:text-[18px] text-[16px]'
								style={{ color: "#4875c2" }}>
								/ انشاء متجر جديد
							</h3>
						</div>
					</div>
					<form
						onSubmit={handleSubmit(handleAddStore)}
						className='flex-1 overflow-y-scroll'>
						<div
							style={{ backgroundColor: "#F6F6F6" }}
							className={`flex-1 overflow-y-scroll md:pl-12 text-right md:py-5 md:pr-16 p-4 ${styles.content}`}>
							<div className='flex flex-col gap-4'>
								<h3
									style={{ color: "#011723" }}
									className='md:text-[22px] text-[20px] font-bold'>
									بيانات المتجر
								</h3>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}></AiFillStar>
										اسم المتجر<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<input
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md outline-none'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #F0F0F0",
											}}
											placeholder='حروف عربية أو انجليزية'
											type='text'
											name='store_name'
											{...register("store_name", {
												required: "حقل اسم المتجر مطلوب",
												pattern: {
													value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
													message: "يجب ان يتكون الاسم من أحرف",
												},
											})}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.store_name}
											{errors?.store_name && errors.store_name.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}></AiFillStar>
										الدومين<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<div
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #F0F0F0",
											}}>
											<input
												className='w-full text-left outline-none'
												style={{
													backgroundColor: "#EEF4FC",
													border: "1px solid #F0F0F0",
													direction: "ltr",
												}}
												placeholder='ادخل حروف انجليزية فقط'
												type='text'
												name='domain'
												{...register("domain", {
													required: "حقل الدومين مطلوب",
													pattern: {
														value: /^[^-\s][a-zA-Z0-9-_]+$/,
														message: "حقل الدومين يجب ان يكون أحرف أنجليزية",
													},
												})}
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.domain}
											{errors?.domain && errors.domain.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}></AiFillStar>
										البريد الإلكتروني<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<input
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md outline-none'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #F0F0F0",
											}}
											placeholder='name@name.com'
											type='email'
											name='store_email'
											{...register("store_email", {
												required: "حقل البريد الالكتروني مطلوب",
												pattern: {
													value:
														/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: "صيغة البريد الالكتروني غير صحيحة",
												},
											})}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.store_email}
											{errors?.store_email && errors.store_email.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										الدولة<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Controller
												name={"country_id"}
												control={control}
												rules={{ required: "حقل الدولة مطلوب" }}
												render={({ field: { onChange, value } }) => (
													<Select
														readOnly
														className={styles.select}
														value={value}
														onChange={onChange}
														displayEmpty
														IconComponent={(props) => (
															<Arrow fill='#242424' {...props} />
														)}
														renderValue={(selected) => {
															if (!selected) {
																return <h2>اختر الدولة</h2>;
															}
															const result = countryList?.countries?.filter(
																(item) => item?.id === parseInt(selected)
															);
															return result[0]?.name;
														}}
														sx={{
															height: "3.5rem",
															backgroundColor: "#EEF4FC",
															border: "1px solid #F0F0F0",
															borderRadius: "8px",
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
														}}>
														{countryList?.countries?.map((item, idx) => {
															return (
																<MenuItem
																	key={idx}
																	className='souq_storge_category_filter_items'
																	sx={{
																		backgroundColor: "#EFF9FF",
																		height: "3rem",
																		"&:hover": {},
																	}}
																	value={`${item?.id}`}>
																	{item?.name}
																</MenuItem>
															);
														})}
													</Select>
												)}
											/>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.country_id}
											{errors?.country_id && errors.country_id.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										المدينة<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Controller
												name={"city_id"}
												control={control}
												rules={{ required: "حقل المدينة مطلوب" }}
												render={({ field: { onChange, value } }) => (
													<Select
														className={styles.select}
														value={value}
														onChange={onChange}
														displayEmpty
														IconComponent={(props) => (
															<Arrow fill='#242424' {...props} />
														)}
														inputProps={{ "aria-label": "Without label" }}
														renderValue={(selected) => {
															if (!selected) {
																return <h2>اختر المدينة</h2>;
															}
															const result = citiesList?.cities?.filter(
																(item) => item?.id === parseInt(selected)
															);
															return result[0]?.name;
														}}
														sx={{
															height: "3.5rem",
															backgroundColor: "#EEF4FC",
															border: "1px solid #F0F0F0",
															borderRadius: "8px",
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
														}}>
														{citiesList?.cities?.map((item, idx) => {
															return (
																<MenuItem
																	key={idx}
																	className='souq_storge_category_filter_items'
																	sx={{
																		backgroundColor: "#EFF9FF",
																		height: "3rem",
																		"&:hover": {},
																	}}
																	value={`${item?.id}`}>
																	{item?.name}
																</MenuItem>
															);
														})}
													</Select>
												)}
											/>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.city_id}
											{errors?.city_id && errors.city_id.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										رقم الجوال<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<div
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #F0F0F0",
											}}>
											<div className='flex flex-row items-center w-full'>
												<PhoneIcon className={styles.icon} />
												<div
													className='w-full flex flex-row items-center gap-[1px]'
													style={{
														backgroundColor: "transparent",
														direction: "ltr",
													}}>
													<span>+966</span>
													<input
														className='w-full outline-none'
														style={{
															backgroundColor: "transparent",
															direction: "ltr",
														}}
														placeholder={500000000}
														type='tel'
														name='phonenumber'
														maxLength={9}
														{...register("phonenumber", {
															required: "حقل  رقم الجوال مطلوب",
															pattern: {
																value: /^(5\d{8})$/,
																message:
																	"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
															},
														})}
													/>
												</div>
											</div>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.phonenumber}
											{errors?.phonenumber && errors.phonenumber.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}
										/>
										اختر الباقة<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Controller
												name={"package_id"}
												control={control}
												rules={{ required: "حقل نوع الخطة مطلوب" }}
												render={({ field: { onChange, value } }) => (
													<Select
														className={styles.select}
														value={value}
														onChange={(e) => {
															setStoreInfo({
																...storeInfo,
																package_id: e.target.value,
															});
															onChange(e);
														}}
														displayEmpty
														IconComponent={(props) => (
															<Arrow fill='#242424' {...props} />
														)}
														inputProps={{ "aria-label": "Without label" }}
														renderValue={(selected) => {
															if (storeInfo?.package_id === "" || !selected) {
																return <h2>اختر الباقة المناسبة</h2>;
															}
															const result = packages?.filter(
																(item) => item?.id === parseInt(selected)
															);
															return result[0]?.name;
														}}
														sx={{
															height: "3.5rem",
															backgroundColor: "#EEF4FC",
															border: "1px solid #F0F0F0",
															borderRadius: "8px",
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
														}}>
														{packages?.map((item, idx) => {
															return (
																<MenuItem
																	key={idx}
																	className='souq_storge_category_filter_items'
																	sx={{
																		backgroundColor: "#EFF9FF",
																		height: "3rem",
																		"&:hover": {},
																	}}
																	value={`${item?.id}`}>
																	{item?.name}
																</MenuItem>
															);
														})}
													</Select>
												)}
											/>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.package_id}
											{errors?.package_id && errors.package_id.message}
										</span>
									</div>
								</div>

								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}></AiFillStar>
										النشاط الرئيسي
										<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Select
												className={styles.select}
												IconComponent={(props) => (
													<Arrow fill='#242424' {...props} />
												)}
												multiple
												displayEmpty
												value={storeInfo?.activity_ids}
												onChange={(e) => {
													setStoreInfo({
														...storeInfo,
														activity_ids: e.target.value,
														sub_activity_ids: [],
													});
												}}
												renderValue={(selected) => {
													if (storeInfo?.activity_ids?.length === 0) {
														return "نشاط أو تصنيف المتجر";
													}
													return selected?.map((item) => {
														const result = activitiesList?.activities?.filter(
															(a) => a?.id === parseInt(item)
														);
														return `${result?.[0]?.name} , `;
													});
												}}
												sx={{
													height: "3.5rem",
													backgroundColor: "#EEF4FC",
													border: "1px solid #A8C8F0",
													borderRadius: "8px",
													"& .MuiOutlinedInput-notchedOutline": {
														border: "none",
													},
												}}>
												{activitiesList?.activities?.map((item, index) => (
													<MenuItem
														className='souq_storge_category_filter_items multiple_select'
														key={index}
														value={item?.id}>
														<Checkbox
															checked={
																storeInfo?.activity_ids?.indexOf(item?.id) > -1
															}
														/>
														<ListItemText primary={item?.name} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.activity_ids}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}></AiFillStar>
										الأنشطة الفرعيه
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Select
												className={styles.select}
												IconComponent={(props) => (
													<Arrow fill='#242424' {...props} />
												)}
												multiple
												displayEmpty
												value={storeInfo?.sub_activity_ids}
												onChange={(e) => {
													setStoreInfo({
														...storeInfo,
														sub_activity_ids: e.target.value,
													});
												}}
												renderValue={(selected) => {
													if (storeInfo?.sub_activity_ids?.length === 0) {
														return "نشاط أو تصنيف المتجر";
													}
													return selected?.map((item) => {
														const result =
															subActivitiesList?.categories?.filter(
																(a) => a?.id === parseInt(item)
															);
														return `${result?.[0]?.name} , `;
													});
												}}
												sx={{
													height: "3.5rem",
													backgroundColor: "#EEF4FC",
													border: "1px solid #A8C8F0",
													borderRadius: "8px",
													"& .MuiOutlinedInput-notchedOutline": {
														border: "none",
													},
												}}>
												{subActivitiesList?.categories?.map((item, index) => (
													<MenuItem
														className='souq_storge_category_filter_items multiple_select'
														key={index}
														value={item?.id}>
														<Checkbox
															checked={
																storeInfo?.sub_activity_ids?.indexOf(item?.id) >
																-1
															}
														/>
														<ListItemText primary={item?.name} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.sub_activity_ids}
										</span>
									</div>
								</div>
							</div>
							<div className='flex flex-col gap-4 mt-8 md:mt-4'>
								<h3
									style={{ color: "#011723" }}
									className='md:text-[22px] text-[20px] font-bold'>
									بيانات المالك
								</h3>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										الإسم الكامل<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<div
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #A8C8F0",
											}}>
											<User className={styles.icon} />
											<input
												className='w-full outline-none'
												style={{ backgroundColor: "transparent" }}
												placeholder='خالد محمد'
												type='text'
												name='name'
												{...register("name", {
													required: "حقل الاسم مطلوب",
													pattern: {
														value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
														message: "يجب ان يتكون الاسم من أحرف",
													},
												})}
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.name}
											{errors?.name && errors.name.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										اسم المستخدم<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<div
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #A8C8F0",
											}}>
											<User className={styles.icon} />
											<input
												className='w-full outline-none'
												style={{ backgroundColor: "transparent" }}
												placeholder='K22'
												type='text'
												name='user_name'
												{...register("user_name", {
													required: "حقل اسم المستخدم مطلوب",
													pattern: {
														value: /^[^-\s][a-zA-Z0-9_]+$/,
														message:
															"حقل اسم المستخدم يجب ان يتكون من أحرف انجليزية",
													},
												})}
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.user_name}
											{errors?.user_name && errors.user_name.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										البريد الإلكتروني<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<div
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #A8C8F0",
											}}>
											<Email className={styles.icon} />
											<input
												className='w-full outline-none'
												style={{ backgroundColor: "transparent" }}
												placeholder='khaled@gmail.com'
												type='email'
												name='email'
												{...register("email", {
													required: "حقل البريد الالكتروني مطلوب",
													pattern: {
														value: /\S+@\S+\.\S+/,
														message: "صيغة البريد الالكتروني  غير صحيحة",
													},
												})}
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.email}
											{errors?.email && errors.email.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										كلمة المرور<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<div
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #A8C8F0",
											}}>
											<input
												className='w-full outline-none'
												style={{ backgroundColor: "transparent" }}
												type={showPassword ? "text" : "password"}
												name='password'
												{...register("password", {
													required: "حقل كلمة المرور مطلوب",
													minLength: {
														value: 6,
														message: "حقل كلمة المرور يجب الا يقل عن 6 أحرف",
													},
												})}
											/>
											{showPassword ? (
												<PasswordOpen
													className={styles.password}
													onClick={() => setShowPassword(!showPassword)}
												/>
											) : (
												<PasswordClose
													className={styles.password}
													onClick={() => setShowPassword(!showPassword)}
												/>
											)}
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.password}
											{errors?.password && errors.password.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										الدولة<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Controller
												name={"user_country_id"}
												control={control}
												rules={{ required: "حقل الدولة مطلوب" }}
												render={({ field: { onChange, value } }) => (
													<Select
														displayEmpty
														value={value}
														onChange={onChange}
														className={styles.select}
														IconComponent={(props) => (
															<Arrow fill='#242424' {...props} />
														)}
														renderValue={(selected) => {
															if (!selected) {
																return <h2>اختر الدولة</h2>;
															}
															const result = countryList?.countries?.filter(
																(item) => item?.id === parseInt(selected)
															);

															return result[0]?.name;
														}}
														sx={{
															height: "3.5rem",
															backgroundColor: "#EEF4FC",
															border: "1px solid #A8C8F0",
															borderRadius: "8px",
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
														}}>
														{countryList?.countries?.map((item, idx) => {
															return (
																<MenuItem
																	key={idx}
																	className='souq_storge_category_filter_items'
																	sx={{
																		backgroundColor: "#EFF9FF",
																		height: "3rem",
																		"&:hover": {},
																	}}
																	value={`${item?.id}`}>
																	{item?.name}
																</MenuItem>
															);
														})}
													</Select>
												)}
											/>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.user_country_id}
											{errors?.user_country_id &&
												errors.user_country_id.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										المدينة<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Controller
												name={"user_city_id"}
												control={control}
												rules={{ required: "حقل المدينة مطلوب" }}
												render={({ field: { onChange, value } }) => (
													<Select
														className={styles.select}
														value={value}
														onChange={onChange}
														displayEmpty
														IconComponent={(props) => (
															<Arrow fill='#242424' {...props} />
														)}
														inputProps={{ "aria-label": "Without label" }}
														renderValue={(selected) => {
															if (!selected) {
																return <h2>اختر المدينة</h2>;
															}
															const result = citiesList?.cities?.filter(
																(item) => item?.id === parseInt(selected)
															);
															return result[0]?.name;
														}}
														sx={{
															height: "3.5rem",
															backgroundColor: "#EEF4FC",
															border: "1px solid #A8C8F0",
															borderRadius: "8px",
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
														}}>
														{citiesList?.cities?.map((item, idx) => {
															return (
																<MenuItem
																	key={idx}
																	className='souq_storge_category_filter_items'
																	sx={{
																		backgroundColor: "#EFF9FF",
																		height: "3rem",
																		"&:hover": {},
																	}}
																	value={`${item?.id}`}>
																	{item?.name}
																</MenuItem>
															);
														})}
													</Select>
												)}
											/>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.user_city_id}
											{errors?.user_city_id && errors.user_city_id.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										رقم الجوال<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<div
											className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
											style={{
												backgroundColor: "#EEF4FC",
												border: "1px solid #A8C8F0",
											}}>
											<div className='flex flex-row items-center w-full'>
												<PhoneIcon className={styles.icon} />
												<div
													className='w-full flex flex-row items-center gap-[1px]'
													style={{
														backgroundColor: "transparent",
														direction: "ltr",
													}}>
													<span>+966</span>
													<input
														className='w-full outline-none'
														style={{
															backgroundColor: "transparent",
															direction: "ltr",
														}}
														placeholder='5419515123'
														type='tel'
														name='userphonenumber'
														maxLength={9}
														{...register("userphonenumber", {
															required: "حقل  رقم الجوال مطلوب",
															pattern: {
																value: /^(5\d{8})$/,
																message:
																	"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
															},
														})}
													/>
												</div>
											</div>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.userphonenumber}
											{errors?.userphonenumber &&
												errors.userphonenumber.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<div className='flex flex-col'>
										<h2 className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											الصورة الشخصية<span className='text-red-500'>*</span>
										</h2>
									</div>
									<div className='flex flex-col'>
										<span className='text-gray-600 text-sm font-normal'>
											الحد الأقصى للصورة 1MB
										</span>
										<ImageUploading
											value={images}
											onChange={onChange}
											maxNumber={1}
											dataURLKey='data_url'
											acceptType={["jpg", "png", "jpeg", "svg", "webp"]}>
											{({ imageList, onImageUpload, dragProps }) => (
												// write your building UI
												<div className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
													<div
														className='relative overflow-hidden upload__image-wrapper'
														style={{
															border: images[0] ? "none" : "3px solid #A8C8F0",
															borderRadius: "10px",
														}}
														onClick={() => {
															onImageUpload();
														}}
														{...dragProps}>
														<div
															className='image-item w-full flex cursor-pointer md:h-[56px] h-[44px]'
															style={{ backgroundColor: "#EFF0F0" }}>
															{!images[0] && (
																<div className='flex flex-row items-center justify-between w-full h-full py-4 pr-5'>
																	<h2
																		className='overflow-hidden whitespace-nowrap text-ellipsis'
																		style={{ color: "#1948a0" }}>
																		( اختر صورة فقط png & jpg & jpeg)
																	</h2>
																	<div
																		className='flex flex-col items-center justify-center px-5 rounded-lg md:px-10'
																		style={{
																			height: "56px",
																			backgroundColor: "#A7A7A7",
																			color: "#ffffff",
																		}}>
																		استعراض
																	</div>
																</div>
															)}
															{images[0] && (
																<div className='flex flex-row items-center justify-between w-full h-full py-4 pr-5'>
																	<h2 style={{ color: "#7C7C7C" }}>
																		{images[0].file.name}
																	</h2>
																	<div
																		className='flex flex-col items-center justify-center px-5 rounded-lg md:px-10'
																		style={{
																			height: "56px",
																			backgroundColor: "#A7A7A7",
																			color: "#ffffff",
																		}}>
																		استعراض
																	</div>
																</div>
															)}
														</div>
													</div>
												</div>
											)}
										</ImageUploading>
										<span className='text-red-500 text-[16px]'>
											{DataError?.image}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2 md:flex-row md:items-center'>
									<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
										الحالة<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col'>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Controller
												name={"status"}
												control={control}
												rules={{ required: "حقل الحالة مطلوب" }}
												render={({ field: { onChange, value } }) => (
													<Select
														className={styles.select}
														value={value}
														onChange={onChange}
														displayEmpty
														IconComponent={(props) => (
															<Arrow fill='#242424' {...props} />
														)}
														inputProps={{ "aria-label": "Without label" }}
														renderValue={(selected) => {
															if (!selected) {
																return <h2>اختر الحالة</h2>;
															}
															const result = conditions?.filter(
																(item) => item?.name_en === selected
															);
															return result[0]?.name;
														}}
														sx={{
															height: "3.5rem",
															backgroundColor: "#EEF4FC",
															border: "1px solid #A8C8F0",
															borderRadius: "8px",
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
															},
														}}>
														{conditions?.map((item, idx) => {
															return (
																<MenuItem
																	key={idx}
																	className='souq_storge_category_filter_items'
																	sx={{
																		backgroundColor: "#EFF9FF",
																		height: "3rem",
																		"&:hover": {},
																	}}
																	value={`${item?.name_en}`}>
																	{item?.name}
																</MenuItem>
															);
														})}
													</Select>
												)}
											/>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.status}
											{errors?.status && errors.status.message}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div
							className='flex justify-center gap-4 p-8'
							style={{
								backgroundColor: "#EDF3FC",
							}}>
							<Button
								btnType={"submit"}
								className={"md:h-14 save_button h-[45px] w-full md:w-1/2"}
								textStyle={{ color: "#FFFF", fontSize: "22px" }}
								type={"normal"}>
								حفظ واعتماد
							</Button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddNewMarket;
