import React, { useEffect, useState, useContext } from "react";
import styles from "./EditMarket.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

/* Context */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import {
	PasswordClose,
	PasswordOpen,
	User,
	Email,
} from "../../../../assets/Icons";
import {
	useGetMarketDataQuery,
	useUpdateMarketMutation,
} from "../../../../RTK/apiSlices/marketsApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const EditMarket = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data: fetchedData, isLoading: loading } = useGetMarketDataQuery({
		marketId: id,
	});
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	// Define storeInfo object
	const [storeInfo, setStoreInfo] = useState({
		name: "",
		user_name: "",
		email: "",
		password: "",
	});

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			user_name: "",
			email: "",
			password: "",
		},
	});

	useEffect(() => {
		setStoreInfo({
			name: fetchedData?.stores?.user?.name || "",
			user_name: fetchedData?.stores?.user?.user_name || "",
			email: fetchedData?.stores?.user?.email || "",
		});
	}, [fetchedData?.stores]);

	useEffect(() => {
		reset(storeInfo);
	}, [storeInfo, reset]);

	const [DataError, setDataError] = useState({
		name: "",
		user_name: "",
		email: "",
		password: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			user_name: "",
			email: "",
			password: "",
		});
	};

	// set these state open and close activity and show password
	const [showPassword, setShowPassword] = useState(false);

	const [updateMarket] = useUpdateMarketMutation();

	const updateMarketHandeler = async (formData) => {
		try {
			const res = await updateMarket({
				marketId: id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/markets");
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					user_name: res?.data?.message?.en?.user_name?.[0],
					email: res?.data?.message?.en?.email?.[0],
					password: res?.data?.message?.en?.password?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.user_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.email?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.password?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	// define this functions to post all add market data to server
	const handleEditStore = (data) => {
		resetData();
		setLoadingTitle("جاري تعديل معلومات المتجر");
		let formData = new FormData();
		formData.append("name", data?.name);
		formData.append("user_name", data?.user_name);
		formData.append("email", data?.email);
		formData.append("password", data?.password);
		updateMarketHandeler(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | تعديل متجر</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/markets");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<div className='flex flex-col justify-between h-full'>
					<div
						className='md:pt-12 pt-24 md:pr-16 md:p-8 px-5 py-[20px]'
						style={{
							backgroundColor: "rgba(235, 235, 235, 1)",
						}}>
						<h2 className='font-bold md:text-[22px] text-[20px] text-right mb-3'>
							تعديل الدخول لمتجر{" "}
							{fetchedData?.stores?.store_name &&
								`(${fetchedData?.stores?.store_name})`}
						</h2>
						<div className='flex flex-row items-center'>
							<div
								onClick={() => {
									navigate("/markets");
								}}
								className={"flex items-center gap-2 cursor-pointer"}>
								<div
									className='flex flex-col items-center justify-center'
									style={{
										width: "30px",
										height: "30px",
										borderRadius: "50%",
										backgroundColor: "#1948a01A",
									}}>
									<GoArrowRight
										style={{ color: "#1948a0", fontSize: "1.2rem" }}
									/>
								</div>
								<h2 className='ml-4 md:text-[18px] text-[16px]'>
									{" "}
									عرض المتاجر{" "}
								</h2>
							</div>
							<h3
								className='md:text-[18px] text-[16px]'
								style={{ color: "#4875c2" }}>
								/ تعديل الدخول لمتجر{" "}
								{fetchedData?.stores?.store_name &&
									`(${fetchedData?.stores?.store_name})`}
							</h3>
						</div>
					</div>
					{loading ? (
						<div className='flex items-center justify-center w-full h-full '>
							<CircularLoading />
						</div>
					) : (
						<form
							onSubmit={handleSubmit(handleEditStore)}
							className='flex flex-col flex-1 overflow-y-scroll'>
							<div
								style={{ backgroundColor: "#F6F6F6" }}
								className={`flex-1 overflow-y-scroll md:pl-12 text-right md:py-8 md:pr-16 p-4 ${styles.content}`}>
								<div className='flex flex-col gap-4'>
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											الاسم الكامل<span className='text-red-500'>*</span>
										</label>
										<div className='flex flex-col'>
											<div
												className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
												style={{
													backgroundColor: "#EEF4FC",
													border: "1px solid #F0F0F0",
												}}>
												<User className={styles.icon} />
												<Controller
													name={"name"}
													control={control}
													render={({ field: { onChange, value } }) => (
														<input
															className='w-full outline-none'
															style={{ backgroundColor: "transparent" }}
															placeholder='K22'
															type='text'
															name='name'
															disabled={true}
															value={value}
															onChange={onChange}
														/>
													)}
												/>
											</div>
											<span className='text-red-500 text-[16px]'>
												{DataError?.name}
												{errors?.name && errors.name.message}
											</span>
										</div>
									</div>
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											اسم المستخدم<span className='text-red-500'>*</span>
										</label>
										<div className='flex flex-col'>
											<div
												className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
												style={{
													backgroundColor: "#EEF4FC",
													border: "1px solid #F0F0F0",
												}}>
												<User className={styles.icon} />
												<Controller
													name={"user_name"}
													control={control}
													render={({ field: { onChange, value } }) => (
														<input
															className='w-full outline-none'
															style={{ backgroundColor: "transparent" }}
															placeholder='K22'
															type='text'
															name='user_name'
															disabled={true}
															value={value}
															onChange={onChange}
														/>
													)}
												/>
											</div>
											<span className='text-red-500 text-[16px]'>
												{DataError?.user_name}
												{errors?.user_name && errors.user_name.message}
											</span>
										</div>
									</div>
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											البريد الإلكتروني<span className='text-red-500'>*</span>
										</label>
										<div className='flex flex-col'>
											<div
												className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
												style={{
													backgroundColor: "#EEF4FC",
													border: "1px solid #F0F0F0",
												}}>
												<Email className={styles.icon} />
												<Controller
													name={"email"}
													control={control}
													render={({ field: { onChange, value } }) => (
														<input
															className='w-full outline-none'
															style={{ backgroundColor: "transparent" }}
															placeholder='khaled@gmail.com'
															type='email'
															name='email'
															disabled={true}
															value={value}
															onChange={onChange}
														/>
													)}
												/>
											</div>
											<span className='text-red-500 text-[16px]'>
												{DataError?.email}
												{errors?.email && errors.email.message}
											</span>
										</div>
									</div>
									<div className='flex flex-col gap-2 md:flex-row md:items-center'>
										<label className='md:text-[18px] text-[16px]  text-[#1948a0]w-[315px]'>
											تعديل كلمة المرور<span className='text-red-500'>*</span>
										</label>
										<div className='flex flex-col'>
											<div
												className='w-[555px] md:h-[56px] h-[44px] max-w-full py-4 px-5 rounded-md flex flex-row items-center justify-between'
												style={{
													backgroundColor: "#EEF4FC",
													border: "1px solid #F0F0F0",
												}}>
												<Controller
													name={"password"}
													control={control}
													rules={{
														required: "حقل كلمة المرور مطلوب",
														minLength: {
															value: 6,
															message: "حقل كلمة المرور يجب الا يقل عن 6 أحرف",
														},
													}}
													render={({ field: { onChange, value } }) => (
														<input
															className='w-full outline-none'
															style={{ backgroundColor: "transparent" }}
															type={showPassword ? "text" : "password"}
															name='password'
															value={value}
															onChange={onChange}
														/>
													)}
												/>
												{showPassword ? (
													<PasswordOpen
														className={styles.password}
														onClick={() => setShowPassword(!showPassword)}
													/>
												) : (
													<PasswordClose
														className={styles.password}
														onClick={() => setShowPassword(!showPassword)}
													/>
												)}
											</div>
											<span className='text-red-500 text-[16px]'>
												{DataError?.password}
												{errors?.password && errors.password.message}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								className='flex justify-center gap-4 p-8'
								style={{
									backgroundColor: "rgba(235, 235, 235, 1)",
								}}>
								<Button
									btnType={"submit"}
									className={"md:h-14 save_button h-[45px] w-[40%]"}
									textStyle={{ color: "#ffff", fontSize: "22px" }}
									type={"normal"}>
									حفظ
								</Button>
								<Button
									style={{
										height: "56px",
										borderColor: `#f75e22`,
									}}
									textStyle={{ color: "#f75e22" }}
									className=' w-[40%] md:h-[56px] h-[45px] md:text-[24px] text-[19px] font-medium'
									type={"outline"}
									onClick={() => {
										navigate("/markets");
									}}>
									اغلاق
								</Button>
							</div>
						</form>
					)}
				</div>
			</div>
		</>
	);
};

export default EditMarket;
