import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const websiteSocialMediaApi = createApi({
	reducerPath: "websiteSocialMediaApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.modelss.co/api/Admin/",
	}),
	tagTypes: ["WebsiteSocialMedia"],

	endpoints: (builder) => ({
		getWebsiteSocialMedia: builder.query({
			query: () => ({ url: `website_socialmedia` }),

			transformResponse: (response) => response.data,
			providesTags: ["WebsiteSocialMedia"],
		}),

		getWebsiteSocialMediaData: builder.query({
			query: ({ socialMediaId }) => ({
				url: `website_socialmedia/${socialMediaId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "WebsiteSocialMedia", id }],
		}),

		changeWebsiteSocialMediaStatus: builder.mutation({
			query: ({ id }) => ({
				url: `changewebsite_socialmediaStatus/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["WebsiteSocialMedia"],
		}),

		updateWebsiteSocialMedia: builder.mutation({
			query: ({ socialMediaId, body }) => {
				return {
					url: `website_socialmedia/${socialMediaId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["WebsiteSocialMedia"],
		}),

		addNewWebsiteSocialMedia: builder.mutation({
			query: ({ body }) => {
				return {
					url: `website_socialmedia`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["WebsiteSocialMedia"],
		}),

		websiteSocialMediaDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `website_socialmedia/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["WebsiteSocialMedia"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetWebsiteSocialMediaQuery,
	useGetWebsiteSocialMediaDataQuery,
	useChangeWebsiteSocialMediaStatusMutation,
	useUpdateWebsiteSocialMediaMutation,
	useAddNewWebsiteSocialMediaMutation,
	useWebsiteSocialMediaDeleteItemMutation,
} = websiteSocialMediaApi;
