import React, { useState, useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* Components */
import BusinessTripsTable from "../../../components/PackagesPageComp/BusinessTripsComp/BusinessTripsTable";

/* Custom Button */
import Button from "../../../UI/Button/Button";

/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { useGetBusinessTripsQuery } from "../../../RTK/apiSlices/subscriptionsApi";
import { TextEditorContext } from "../../../store/TextEditorProvider";

const BusinessTrips = () => {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const editorContent = useContext(TextEditorContext);
	const { setEditorValue } = editorContent;

	const { data: BusinessTrip, isLoading: loading } = useGetBusinessTripsQuery({
		page,
		number: rowsPerPage,
	});

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | الرحلات التجارية</title>
			</Helmet>
			<div className='p-4 relative md:bg-[#fafafa] bg-[#ffffff]'>
				{permissions?.includes("admin.plan.store") && (
					<div className='flex justify-end mb-10'>
						<Button
							className='md:w-[265px] save_button w-full md:h-[56px] h-[45px] text-xl'
							svg={<AiOutlinePlus color='#fff' className='w-5 h-5' />}
							type={"normal"}
							onClick={() => {
								setEditorValue("");
								navigate("/business-trips/add-new-business-trip");
							}}>
							اضافة رحلة جديدة
						</Button>
					</div>
				)}

				<BusinessTripsTable
					fetchedData={BusinessTrip?.trip_details}
					loading={loading}
					pageNumber={page}
					setPageNumber={setPage}
					currentPage={rowsPerPage}
					setCurrentPage={setRowsPerPage}
					page_count={BusinessTrip?.page_count}
				/>
			</div>
		</>
	);
};

export default BusinessTrips;
