import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

/* Context */
import { DeleteContext } from "../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* Components */
import NewPagination from "../../PaginationComp/NewPagination";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import VideoOfCourseDuration from "../VideoOfCourseDuration/VideoOfCourseDuration";

// icons
import { Delete, Copy, EditButton, TrueIcon } from "../../../assets/Icons";

// RTK query
import {
	useDeleteCourseMutation,
	useGetCoursesQuery,
} from "../../../RTK/apiSlices/coursesApi";

import PlaceholderImage from "../../../assets/images/placeholderImage.png";

const TrainingCourses = () => {
	const [id, setId] = useState("");
	const navigate = useNavigate();

	// courses data
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: courses, isLoading: coursesLoading } = useGetCoursesQuery({
		page,
		number: rowsPerPage,
	});

	// permissions
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	// --------------------------------------------------------

	// delete course
	const [deleteCourse] = useDeleteCourseMutation();
	const handleDeleteSingleItem = async (id) => {
		try {
			await deleteCourse({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	// handle copy url
	const [copy, setCopy] = useState(false);
	const handelCopy = (courseId) => {
		const course = courses?.courses?.filter(
			(course) => course?.id === courseId
		);
		navigator.clipboard.writeText(course[0]?.url);
		setCopy(true);
		toast.success("تم نسخ الرابط بنجاح");
		setTimeout(() => {
			setCopy(false);
		}, 1000);
		setId("");
	};

	return (
		<>
			<div className='mt-6 md:mt-12'>
				{coursesLoading ? (
					<div className='flex flex-col items-center justify-center'>
						<CircularLoading />
					</div>
				) : (
					<>
						{courses?.courses?.length > 0 ? (
							<>
								{courses?.courses?.map((course, index) => {
									return (
										<div
											key={index}
											className='h-auto md:h-[11rem] rounded-md md:rounded-lg flex md:flex-row flex-col md:items-center items-start justify-between shadow-sm gap-y-4 md:p-0 p-3 mb-4 bg-[#ffffff] overflow-hidden'
											style={{ border: "1px solid #eae8ee" }}>
											<div className='flex flex-row gap-3 md:gap-4'>
												<div
													className='relative w-32 h-full md:w-60'
													style={{ maxWidth: "100%" }}>
													<img
														src={
															course?.image ? course?.image : PlaceholderImage
														}
														alt={course?.name}
														loading='lazy'
														style={{
															width: "100%",
															height: "100%",
															objectFit: "cover",
														}}
													/>
												</div>
												<div className='flex flex-col items-center justify-center md:pt-4'>
													<h2
														className='font-medium md:text-xl text-[18px] md:mb-4 mb-2'
														style={{ color: "#1948a0" }}>
														{course?.name}
													</h2>

													<h2 className='mb-1 md:text-lg text-[16px]'>
														<VideoOfCourseDuration
															duration={course?.durationCourse}
														/>
													</h2>
													<h2 className='md:text-lg text-[16px]'>
														{course?.count} فيديو
													</h2>
												</div>
											</div>
											<div className='flex flex-row items-center justify-center gap-5 pr-2 md:pl-6'>
												{id === course?.id && copy ? (
													<TrueIcon />
												) : (
													<Copy
														title='نسخ الرابط'
														className='cursor-pointer'
														onClick={() => {
															handelCopy(course?.id);
															setId(course?.id);
														}}
													/>
												)}
												{permissions?.includes("admin.course.update") && (
													<EditButton
														title='تعديل'
														className='cursor-pointer'
														onClick={() => {
															navigate(`/edit-course/${course?.id}`);
														}}
													/>
												)}

												{permissions?.includes("admin.course.destroy") && (
													<Delete
														title='حذف'
														className='cursor-pointer'
														onClick={() => {
															setActionDelete(
																"سيتم حذف الدورة وهذة الخطوة غير قابلة للرجوع"
															);
															setItemId(course?.id);
														}}
													/>
												)}
											</div>
										</div>
									);
								})}
								<div dir='ltr'>
									<NewPagination
										pageNumber={page}
										setPageNumber={setPage}
										currentPage={rowsPerPage}
										setCurrentPage={setRowsPerPage}
										page_count={courses?.page_count}
									/>
								</div>
							</>
						) : (
							<div className='flex flex-col items-center justify-center mt-20 text-lg'>
								لاتوجد دورات
							</div>
						)}
					</>
				)}
				{actionDelete && (
					<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
				)}
			</div>
		</>
	);
};

export default TrainingCourses;
